
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignMessageService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }
  url = environment.apiUrl;

  getAllCampaignsWithContacts() {
    return this.http.get(`${this.url}/CRM/Campaigns/Messages/Contacts`);
  }
  getAllCampaignMessages(isactive: boolean = false) {
    let params = new HttpParams().set("active", isactive.toString().toLowerCase());
    return this.http.get(`${this.url}/CRM/Campaigns/Messages`,{ params: params });
  }
  getAllCampaigns() {
    return this.http.get(`${this.url}/CRM/Campaigns`);
  }
  getCampaign(campaign_id: string) {
    return this.http.get(`${this.url}/CRM/Campaign/${campaign_id}`)
  }
  getCampaignMessage(message_id: string) {
    return this.http.get(`${this.url}/CRM/Campaign/Message/${message_id}`)
  }
  getCampaignMessages(campaign_id: string) {
    return this.http.get(`${this.url}/CRM/Campaign/${campaign_id}/Messages`)
  }
  getCampaignMessageContacts(message_id: string) {
    return this.http.get(`${this.url}/CRM/Campaign/Message/${message_id}/Contacts`)
  }
  setCampaignMessageContactStatus(message_id: string, status_id: number, ids: number[]) {
    return this.http.post(`${this.url}/CRM/Campaign/Message/${message_id}/Contacts/Status/${status_id}`, ids);
  }
  deleteCampaignMessageContacts(message_id: string, ids: number[]) {

    return this.http.post(`${this.url}/CRM/Campaign/Message/${message_id}/DeleteContacts`, ids);
  }
  // ---------------------
  createCampaignMessage(campaign, campaign_id: string) {
    return this.http.post(`${this.url}/CRM/Campaign/${campaign_id}/Message`, campaign)
  }
  createCampaignMessages(campaign, includeProspects: boolean = false, excludeSocial: boolean = false) {
    let params = new HttpParams()
      .set("includeProspects", includeProspects.toString().toLowerCase())
      .set("excludeSocialConnections", excludeSocial.toString().toLowerCase())
    return this.http.post(`${this.url}/CRM/Campaign/Messages/CreateInBackground`, campaign, { params: params })
  }
  createCampaignFollowupMessage(campaign, campaign_id: string, message_id: string) {
    return this.http.post(`${this.url}/CRM/Campaign/${campaign_id}/Message/${message_id}/Followup`, campaign)
  }
  updateCampaignMessage(campaign, campaign_id: string, message_id: string) {
    return this.http.put(`${this.url}/CRM/Campaign/${campaign_id}/Message/${message_id}`, campaign)
  }
  pauseCampaignMessage(campaign_id: string, message_id: string, pause: boolean) {
    let params = new HttpParams().set("pause", pause.toString().toLowerCase());
    return this.http.put(`${this.url}/CRM/Campaign/${campaign_id}/Message/${message_id}/Pause`, {} , {params: params})
  }
  resendCampaignMessage(campaign_id: string, message_id: string) {
    return this.http.put(`${this.url}/CRM/Campaign/${campaign_id}/Message/${message_id}/Resend`, {})
  }
  deleteCampaign(campaign_id: string) {
    return this.http.delete(`${this.url}/CRM/Campaign/${campaign_id}`);
  }
  deleteCampaignMessage(message_id: string) {
    return this.http.delete(`${this.url}/CRM/Campaign/Message/${message_id}`);
  }
  deleteCampaignMessageAttachment(message_id: string, attach_id: string) {
    return this.http.delete(`${this.url}/CRM/Campaign/Message/${message_id}/Attachment/${attach_id}`);
  }
}
