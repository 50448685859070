import { Component, Inject, ViewContainerRef, ChangeDetectorRef, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../../services/contact.service';

@Component({
  selector: 'app-contact-edit-dialog',
  templateUrl: './contact-edit-dialog.component.html',
  styleUrls: ['./contact-edit-dialog.component.scss']
})
export class ContactEditDialogComponent implements OnInit {

  currentUser;
  contact;
  contact_id;
  company_name;
  company_id;

  constructor(
    public dialogRef: MatDialogRef<ContactEditDialogComponent>,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    public view: ViewContainerRef,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private contactService: ContactService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // --- don't need that ---
    //dialogRef.backdropClick().subscribe(() => {
    //  dialogRef.close()
    //});
  }

  contactTitles;
  formTitle = "Edit Contact";

  contactStatus = [
    { "id": 1, "name": "Active" },
    { "id": 6, "name": "On Hold" },
    { "id": 5, "name": "Responded" },
    { "id": 4, "name": "Unsubscribed" },
    { "id": 3, "name": "Bounced" },
    //{ "id": 2, "name": "Contacted" },
    { "id": 0, "name": "Inactive" },
  ]

  contactSource = [
    { "id": 1, "name": "Lead Database" },
    { "id": 2, "name": "LinkedIn" },
    { "id": 3, "name": "Website" },
    { "id": 4, "name": "Referral" },
  ]

  urlValidatorRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  contactEditForm: FormGroup;

  formData = new FormData();

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

    this.company_name = this.data.companyname;
    this.company_id = this.data.companyid;
    this.contact_id = this.data.id;

    console.log(this.data)

    this.authService.currentUser.subscribe(x => this.currentUser = x);

    this.contactService.getTitles().subscribe(titles => {
      this.contactTitles = titles;
      //console.log(this.contactTitles);
    });


    this.contactEditForm = this.fb.group({
      id: this.contact_id,
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      phone: null,
      mobile: null,
      department: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      titleId: null,
      status: null,
      source: null,
      isSocialConnection: false,
      linkedin: [null, Validators.pattern(this.urlValidatorRegex)]
    });
    if (this.contact_id > 0) {

      this.contactService.getContact(this.contact_id)
        .subscribe(c => {
          this.contact = c;

          console.log(this.contact);

          this.contactEditForm.patchValue(this.contact);

        });
    } else {
      this.formTitle = "New Contact";
    }
  }

  get f() { return this.contactEditForm.controls; }

  onSubmit() {

    if (this.contactEditForm.invalid) {
      return;
    }
    this.setFormData();

    //this.dialogRef.close();
    this.contactService.saveContact(this.formData).subscribe(result => {

      console.log(result);
      this.dialogRef.close(result);

    });

  }

  setFormData() {
    let entries = Object.entries(this.contactEditForm.value);

    console.log(entries);

    this.formData.set("id", this.contact_id.toString());
    if (this.company_id) {
      this.formData.set("companyId", this.company_id.toString());
    }

    for (let [key, value] of entries) {

      if (this.contact_id > 0) {
        if (this.contactEditForm.value[key] != this.contact[key]) {
          // allow to clear existing backend value by setting it to one space
          if (value === '') {
            value = ' ';
          }

          console.log(key, String(value));

          this.formData.set(key, String(value));
        }
      } else {
        if (value) {
          this.formData.set(key, String(value));
        }
      }

    }
  }
}
