<div class="container">
  <div class="row mb-2">
    <div class="col-12">
      <button class="btn btn-outline-primary btn-lg float-right" (click)="inviteUser()">Invite New User</button>
      <h2>All Users</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6 col-md-4" *ngFor="let user of users">
      <div class="card mb-3">
        <div class="card-body user-card">
          <h4>{{ user.firstName }} {{ user.lastName }} <small *ngIf="user.roleId==2"> ({{user.roleName}})</small></h4>
          <h6>{{ user.email }}</h6>
          <!--<p>{{ user.roleName }}</p>-->
        </div>
        <div class="card-footer">
          <button *ngIf="user.roleId > 2 && user.status==='Active'" class="btn btn-sm btn-link float-right" (click)="updateStatus(user,0)">Deactivate</button>
          <button *ngIf="user.roleId > 2 && user.status==='Deactivated'" class="btn btn-sm btn-link float-right" (click)="updateStatus(user,1)">Activate</button>

          <button *ngIf="user.roleId > 2 && user.status!=='Active'" class="btn btn-sm btn-link float-right" (click)="deleteUser(user)">Delete</button>
          <button *ngIf="user.status==='Invited'" class="btn btn-sm btn-link float-right" (click)="resendInvite(user)">Resend Invite</button>

          <!--<button *ngIf="user.roleId>2" class="btn btn-sm btn-link float-right" (click)="editUser(user.id)">Edit</button>-->

          <h6 class="pt-1" [ngClass]="{'text-success': user.status === 'Active',
                             'text-secondary': user.status === 'Invited',
                             'text-muted': user.status === 'Deactivated'}">
            {{ user.status}}
          </h6>
          <!--<small>{{ user.clientName }}</small>-->
        </div>
      </div>
    </div>
  </div>
</div>
