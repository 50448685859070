import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//export interface DialogData {
//  firstname: string;
//  lastname: string;
//  companyname: string;
//  email: string;
//}

@Component({
  selector: 'app-email-send-test-dialog',
  templateUrl: './email-send-test-dialog.component.html',
  styleUrls: ['./email-send-test-dialog.component.scss']
})
export class EmailSendTestDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<EmailSendTestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    console.log(data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
