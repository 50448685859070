import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { PreviousURLService } from 'src/app/services/previous-url.service';
import { WeekDay } from '@angular/common';
import { CheckboxSelectionComponent } from 'ag-grid';
import { EmailSendTestDialogComponent } from '../../email-send-test-dialog/email-send-test-dialog.component';
import { EmailMessageService } from '../../../services/email-message.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private userAuth: AuthenticationService,
    private flash: FlashMessagesService,
    public urlService: PreviousURLService,
    private emailMsgService: EmailMessageService,
    public dialog: MatDialog,
  ) { }

  theForm: FormGroup;
  loading = false;
  submitted = false;
  resetToken: string;
  error = '';
  userid: number;
  userEmailSettings: any;
  currentUser: User;
  formData = new FormData();
  //weekDays = WeekDay
  weekDays: Array<any> = [
    { val: 'Sun', key: WeekDay.Sunday, dflt: false },
    { val: 'Mon', key: WeekDay.Monday, dflt: true },
    { val: 'Tue', key: WeekDay.Tuesday, dflt: true },
    { val: 'Wed', key: WeekDay.Wednesday, dflt: true },
    { val: 'Thu', key: WeekDay.Thursday, dflt: true },
    { val: 'Fri', key: WeekDay.Friday, dflt: true },
    { val: 'Sat', key: WeekDay.Saturday, dflt: false }
  ];

  selectedDays: Array<Number> = [1, 2, 3, 4, 5];
  firstActive: Number = 1; //
  firstActiveTab = "weekday_1";

  ngOnInit() {

    //alert(this.weekDays.length);
    //this.weekDays.forEach((item, index) => {
    //  console.log(item);
    //});


    this.theForm = this.fb.group({
      mailServer: [null],
      mailPort: [null],
      senderName: [''],
      senderEmail: [''],
      senderPassword: [''],
      maxEmailsPerHour: [null, Validators.required],

      startTime0: [{ value: null, disabled: true }, Validators.required],
      endTime0: [{ value: null, disabled: true }, Validators.required],

      startTime1: [{ value: null, disabled: true }, Validators.required],
      endTime1: [{ value: null, disabled: true }, Validators.required],

      startTime2: [{ value: null, disabled: true }, Validators.required],
      endTime2: [{ value: null, disabled: true }, Validators.required],

      startTime3: [{ value: null, disabled: true }, Validators.required],
      endTime3: [{ value: null, disabled: true }, Validators.required],

      startTime4: [{ value: null, disabled: true }, Validators.required],
      endTime4: [{ value: null, disabled: true }, Validators.required],

      startTime5: [{ value: null, disabled: true }, Validators.required],
      endTime5: [{ value: null, disabled: true }, Validators.required],

      startTime6: [{ value: null, disabled: true }, Validators.required],
      endTime6: [{ value: null, disabled: true }, Validators.required],

      //enableTracking: [{ value: false, disabled: false },],
      //weekdays: ['', Validators.required]
    });

    //this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUser = this.userAuth.currentUserValue;

    //TODO: consider that userid can be passed via url parameter
    this.userid = this.currentUser.id;

    this.reloaduser();

  }
  // convenience getter for easy access to form fields
  get f() { return this.theForm.controls; }

  reloaduser(): void {
    //this.userService.getById(this.userid).subscribe(u => {
    //  this.user = u;
    //  this.theForm.patchValue(this.user);
    //  this.theForm.patchValue({ password: '', passwordConfirm: '' });
    //  //
    //  console.log(this.user);
      this.userService.getEmailSettings(this.userid).subscribe(s => {
        this.userEmailSettings = s;

        this.theForm.patchValue(this.userEmailSettings);
        //--- assume that it's comming from database
        //this.userEmailSettings.weekDays = "1,2,3,4";
        this.selectedDays = this.userEmailSettings.weekdays.split(",");
        this.selectedDays.sort((a, b) => <number>a - <number>b);

        this.selectedDays.forEach((item, index) => {
          if (index == 0) {
            this.firstActive = item;
            this.firstActiveTab = `weekday_${item}`;
          }
          this.theForm.controls[`startTime${item}`].enable();
          this.theForm.controls[`endTime${item}`].enable();
        })
        console.log(this.userEmailSettings)
      });
    //});
  }
  isDaySelected(day: number) {

    if (this.selectedDays.find(x => x == day)) {
      return true;
    } else {
      return false;
    }
  }
  onCheckBoxClick(e) {
    //console.log(e);
    // this allows checkbox inside href
    e.stopPropagation();

  }
  onCheckboxChange(e) {
    //const checkArray: FormArray = this.theForm.get('checkArray') as FormArray;
    console.log(e);
    var day = e.target.id.split("_")[1];

    if (e.target.checked) {
      this.selectedDays.push(e.target.value);

      this.theForm.controls[`startTime${day}`].enable();
      this.theForm.controls[`endTime${day}`].enable();

    } else {

      this.theForm.controls[`startTime${day}`].disable();
      this.theForm.controls[`endTime${day}`].disable();

      this.selectedDays.forEach((item, index) => {
        if (item.toString() == e.target.value) {
          this.selectedDays.splice(index, 1);
          return;
        }
      });
    }

    this.theForm.markAsDirty();

    console.log(this.selectedDays);
    let days = "";
    if (this.selectedDays.length > 0) {
      days = this.selectedDays.join(",");
      this.formData.set("weekdays", days);
    } else {
      //this.theForm.setErrors({ 'invalid': true });
    }

  }

  setFormData() {
    let entries = Object.entries(this.theForm.value);
    for (let [key, value] of entries) {
      if (value === null) {
        value = '';
      }
      this.formData.set(key, String(value));
      //console.log(key, String(value));
    }
    //var selectedDays = '';

    //var chks = document.getElementsByClassName("mat-checkbox-input");
    //Array.from(chks).forEach((element, index) => {
    //  // conditional logic here.. access element
    //  console.log(element);
    //  console.log(element.attributes['value'].value);
    //  console.log(element.attributes["aria-checked"]['aria-checked']);
    //});
    ////chks.forEach((item, index) => {
    ////  console.log(item);
    ////  console.log(item.textContent);
    ////  if (item.nodeValue) {
    ////    selectedDays += item.nodeValue;
    ////  }
    ////});

    //alert(selectedDays);
  }
  goBack() {
    this.urlService.goToPreviousUrl();
  }
  onSubmit() {
    // stop here if form is invalid
    if (this.theForm.invalid) {
      return;
    }

    this.setFormData();

    this.userService.updateEmailSettings(this.userid, this.formData)
      .subscribe(ret => {

        this.flash.show('Successfully updated email settings', { cssClass: 'alert-success', timeout: 3000 })

        this.reloaduser();
      });
  }

  sendTestEmail(): void {
    const dialogRef = this.dialog.open(EmailSendTestDialogComponent, {
      width: '400px',
      disableClose: false,
      data: {
        email: '',
        message: '',
        subject: ''
      }
    });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      console.log(result);
      if (result && result.email) {
        //--- save the data 1st ---
        this.setFormData();
        this.userService.updateEmailSettings(this.userid, this.formData)
          .subscribe(() => {

            this.emailMsgService.send(-1, result).subscribe(response => {
              console.log(response);
              let r: any = response;
              if (r !== null && r.message) {
                this.flash.show(`Error sending email: ${r.message}`, { cssClass: 'alert-danger', timeout: 6000 });
              }
              else {
                this.flash.show(`Successfully send email to ${result.email}`, { cssClass: 'alert-success', timeout: 3000 });
              }
            });

          });
      }
    });

  }
}
