<div class="container-fluid">
  <div class="row">
    <div class="col-sm-6 offset-sm-3 col-lg-4 offset-lg-4">
      <div class="jumbotron mt-5">
        <h2>Reset Password</h2>
        <form [formGroup]="theForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="password1">New Password</label>
            <input type="password" formControlName="password1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password1.errors }" />
            <div *ngIf="submitted && f.password1.errors" class="invalid-feedback">
              <div *ngIf="f.password1.errors.required">Password is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="password2">Confirm New Password</label>
            <input type="password" formControlName="password2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password2.errors }" />
            <div *ngIf="submitted && f.password2.errors" class="invalid-feedback">
              <div *ngIf="f.password2.errors.required">Password is required</div>
            </div>
          </div>
          <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary">Submit</button>
            <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </div>
          <div *ngIf="error" class="alert alert-danger">{{error}}</div>
          <div *ngIf="sucessMessage" class="alert alert-success">{{sucessMessage}}</div>
        </form>
      </div>
    </div>
  </div>
</div>

