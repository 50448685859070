<!-- main app container -->

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
      <div class="jumbotron mt-3">
        <h2 class="mb-3">Register</h2>
        <hr>
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" autocomplete="on">
          <fieldset>
            <legend class="mb-3">Company Details</legend>
            <div class="form-group">
              <label for="clientname" class="required-label">Company Name</label>
              <input type="text" maxlength="250" id="clientname" formControlName="ClientName" class="form-control" [ngClass]="{ 'is-invalid': f.ClientName.dirty && f.ClientName.errors }" />
              <div *ngIf="f.ClientName.errors" class="invalid-feedback">
                <div *ngIf="f.ClientName.errors.required">Company name is required</div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8 form-group">
                <label for="website">Website</label>
                <input type="text" maxlength="250" id="website" formControlName="Website" class="form-control" />
              </div>
              <div class="col-sm-4 form-group">
                <label for="phone">Phone</label>
                <input type="text" maxlength="250" id="phone" formControlName="Phone" class="form-control" />
              </div>
            </div>
            <div class="form-group">
              <label for="streetaddress">Street Address</label>
              <input type="text" maxlength="250" id="streetaddress" formControlName="Streetaddress" class="form-control" />
            </div>
            <div class="row">
              <div class="col-sm-4 form-group">
                <label for="city">City</label>
                <input type="text" maxlength="250" id="city" formControlName="City" class="form-control" />
              </div>
              <div class="col-sm-4 form-group">
                <label for="state">State</label>
                <input type="text" maxlength="2" id="state" formControlName="State" class="form-control text-uppercase" />
                <!--<select name="state" id="state" class="form-control" formControlName="Stateid">
          <option value="" selected disabled>Select a state</option>
          <option value="{{ state.id }}" *ngFor="let state of listOfStates">{{ state.name }}</option>
        </select>-->
              </div>
              <div class="col-sm-4 form-group">
                <label for="zip">Zip</label>
                <input type="text" maxlength="50" id="zip" formControlName="Zip" class="form-control" />
              </div>
            </div>
          </fieldset>
          <hr class="mb-3">
          <fieldset class="mb-1">
            <legend class="mb-3">User Details</legend>
            <div class="row">
              <div class="col-sm-6 form-group">
                <label for="userfirstname" class="required-label">First Name</label>
                <input type="text" maxlength="50" id="userfirstname" formControlName="UserFirstName" class="form-control" [ngClass]="{ 'is-invalid': f.UserFirstName.dirty && f.UserFirstName.errors }" />
                <div *ngIf="f.UserFirstName.errors" class="invalid-feedback">
                  <div *ngIf="f.UserFirstName.errors.required">First Name is required</div>
                </div>
              </div>
              <div class="form-group col-sm-6">
                <label for="userlastname" class="required-label">Last Name</label>
                <input type="text" maxlength="50" id="userlastname" formControlName="UserLastName" class="form-control" [ngClass]="{ 'is-invalid': f.UserLastName.dirty && f.UserLastName.errors }" />
                <div *ngIf="f.UserLastName.errors" class="invalid-feedback">
                  <div *ngIf="f.UserLastName.errors.required">Last Name is required</div>
                </div>
              </div>
              <div class="form-group col-sm-12">
                <label for="useremail" class="required-label">Email</label>
                <input id="useremail" maxlength="50" type="text" formControlName="UserEmail" class="form-control" [ngClass]="{ 'is-invalid': f.UserEmail.dirty && f.UserEmail.errors }" />
                <div *ngIf="f.UserEmail.errors" class="invalid-feedback">
                  <div *ngIf="f.UserEmail.errors.required">Email is required</div>
                </div>
              </div>
              <div class="form-group col-sm-12">
                <label for="userpassword" class="required-label">Password</label>
                <input type="password" id="userpassword" formControlName="UserPassword" class="form-control" [ngClass]="{ 'is-invalid': f.UserPassword.dirty && f.UserPassword.errors }" />
                <div *ngIf="f.UserPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.UserPassword.errors.required">Please enter a password</div>
                </div>
              </div>
              <div class="form-group col-sm-12">
                <label for="userpasswordconfirm" class="required-label">Confirm Password</label>
                <input type="password" id="userpasswordconfirm" formControlName="UserPasswordConfirm" class="form-control" [ngClass]="{ 'is-invalid': f.UserPasswordConfirm.dirty && f.UserPasswordConfirm.errors }" />
                <div *ngIf="f.UserPasswordConfirm.errors" class="invalid-feedback">
                  <div *ngIf="f.UserPasswordConfirm.errors.required">Please confirm your password</div>
                </div>
              </div>
            </div>
          </fieldset>
          <hr/>
          <fieldset class="mb-3" *ngIf="needSubscription">
            <legend class="mb-3">Payment Details</legend>
            <div class="form-group">
              <!--<label for="card-info">New Card</label>-->
              <div id="card-info" #cardInfo></div>
              <div *ngIf="cardErrors" role="alert" class="stripe-error">
                {{ cardErrors }}
              </div>
            </div>
          </fieldset>
       
          <div class="form-group">
            <button [disabled]="!registerForm.valid || registerForm.pristine || cardErrors !== null" class="btn btn-primary">Register</button>
          </div>
        </form>
        <hr>
        Already have an account? <a [routerLink]="[ '/login' ]">Login</a> instead
      </div>
    </div>
  </div>
</div>
