
<mat-dialog-content class="card-body">
  <p [innerHTML]="message"></p>
</mat-dialog-content>
<br/>
<mat-dialog-action class="float-right">
  <button class="btn btn-primary" (click)="onNoClick()" cdkFocusInitial>No</button>
   &nbsp;
  <button class="btn btn-secondary" [mat-dialog-close]="true">Yes</button>
</mat-dialog-action>

