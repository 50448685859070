<div class="container-fluid">
  <div class="row">
    <div class="col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
      <div class="jumbotron mt-1">
        <h3>Email Settings</h3>
        <form [formGroup]="theForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="mailServer">Outgoing Mail Server</label>
            <input type="text" maxlength="50" id="mailServer" formControlName="mailServer" class="form-control" />
          </div>
          <div class="form-group">
            <label for="mailPort">Mail Port</label>
            <input type="text" maxlength="50" id="mailPort" formControlName="mailPort" class="form-control" />
          </div>
          <div class="form-group">
            <label for="senderName">Name</label>
            <input type="text" maxlength="50" id="senderName" formControlName="senderName" class="form-control" />
          </div>
          <div class="form-group">
            <label for="senderEmail">Email</label>
            <input type="text" maxlength="50" id="senderEmail" formControlName="senderEmail" class="form-control" autocomplete="off" />
          </div>
          <div class="form-group">
            <label for="senderPassword">Password</label>
            <input type="password" maxlength="50" id="senderPassword" formControlName="senderPassword" class="form-control" autocomplete="new-password" />
          </div>
          <hr />
          <div class="form-group">
            <label for="dailyStartTime">Maximum # of Emails Per Hour</label>
            <input type="number" class="form-control" required id="maxEmailsPerHour" formControlName="maxEmailsPerHour"
                   [ngClass]="{ 'is-invalid':  (f.maxEmailsPerHour.dirty || f.maxEmailsPerHour.touched) && f.maxEmailsPerHour.errors }">
            <div *ngIf="f.maxEmailsPerHour.errors" class="invalid-feedback">
              <div *ngIf="f.maxEmailsPerHour.errors.required">Number is required</div>
            </div>
          </div>
          <!--<div class="row">-->
            <!--<div class="col-12">-->
              <!--<div class="form-group">-->
                <div><label>Email Days</label></div>
                <!--<span class="mr-4" *ngFor="let weekDays of weekDays; let i=index">
    <label>
      <input type="checkbox" [value]="weekDays.key" [checked]="isDaySelected(weekDays.key)" (change)="onCheckboxChange($event)" />
      {{weekDays.val}}
    </label>
  </span>-->
                <ul class="nav nav-tabs" id="weekdayTabs" role="tablist">
                  <li class="nav-item" *ngFor="let weekDays of weekDays; let i=index">
                    <div [attr.href]="'#weekday_'+ weekDays.key" class="nav-link cursor-pointer" [ngClass]="{ 'active': weekDays.key == firstActive }" id="weekday-tab" data-toggle="tab" role="tab">
                      <label class="cursor-pointer">
                        <input [attr.id]="'w_' + weekDays.key" type="checkbox" [value]="weekDays.key" [checked]="isDaySelected(weekDays.key)" (change)="onCheckboxChange($event)" (click)="onCheckBoxClick($event);" />
                        {{weekDays.val}}
                      </label>
                    </div>
                  </li>
                  <!--<li class="nav-item">
                    <a class="nav-link active" id="campaign-tab" data-toggle="tab" href="#campaign_edit" role="tab" aria-controls="azure"
                       aria-selected="true"><h3>{{pageTitle}}</h3></a>
                  </li>
                  <li class="nav-item" *ngIf="message_id">
                    <a class="nav-link" id="contacts-tab" data-toggle="tab" href="#contact_list" role="tab" aria-controls="amazon"
                       aria-selected="false"><h3>Contacts</h3></a>
                  </li>-->
                </ul>
                <div *ngIf="selectedDays.length==0" class="text-danger">
                  <div class="text-danger">At least one day of the week must be selected</div>
                </div>
              <!--</div>-->
            <!--</div>-->
          <!--</div>-->
          <div class="tab-content bg-white p-2 rounded">
            <div class="tab-pane fade" [ngClass]="{ 'active show': firstActiveTab == 'weekday_0' }" id="weekday_0" role="tabpanel">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="startTime0">Start Time</label>
                    <input type="time" class="form-control" required id="startTime0" formControlName="startTime0"
                           [ngClass]="{ 'is-invalid':  (f.startTime0.dirty || f.startTime0.touched) && f.startTime0.errors }">
                    <div *ngIf="f.startTime0.errors" class="invalid-feedback">
                      <div *ngIf="f.startTime0.errors.required">Start Time is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="endTime0">End Time</label>
                    <input type="time" class="form-control" required id="endTime0" formControlName="endTime0"
                           [ngClass]="{ 'is-invalid':  (f.endTime0.dirty || f.endTime0.touched) && f.endTime0.errors }">
                    <div *ngIf="f.endTime0.errors" class="invalid-feedback">
                      <div *ngIf="f.endTime0.errors.required">End Time is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" [ngClass]="{ 'active show': firstActiveTab == 'weekday_1' }" id="weekday_1" role="tabpanel">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="startTime1">Start Time</label>
                    <input type="time" class="form-control" required id="startTime1" formControlName="startTime1"
                           [ngClass]="{ 'is-invalid':  (f.startTime1.dirty || f.startTime1.touched) && f.startTime1.errors }">
                    <div *ngIf="f.startTime1.errors" class="invalid-feedback">
                      <div *ngIf="f.startTime1.errors.required">Start Time is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="endTime1">End Time</label>
                    <input type="time" class="form-control" required id="endTime1" formControlName="endTime1"
                           [ngClass]="{ 'is-invalid':  (f.endTime1.dirty || f.endTime1.touched) && f.endTime1.errors }">
                    <div *ngIf="f.endTime1.errors" class="invalid-feedback">
                      <div *ngIf="f.endTime1.errors.required">End Time is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" [ngClass]="{ 'active show': firstActiveTab == 'weekday_2' }"id="weekday_2" role="tabpanel">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="startTime2">Start Time</label>
                    <input type="time" class="form-control" required id="startTime2" formControlName="startTime2"
                           [ngClass]="{ 'is-invalid':  (f.startTime2.dirty || f.startTime2.touched) && f.startTime2.errors }">
                    <div *ngIf="f.startTime2.errors" class="invalid-feedback">
                      <div *ngIf="f.startTime2.errors.required">Start Time is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="endTime2">End Time</label>
                    <input type="time" class="form-control" required id="endTime2" formControlName="endTime2"
                           [ngClass]="{ 'is-invalid':  (f.endTime2.dirty || f.endTime2.touched) && f.endTime2.errors }">
                    <div *ngIf="f.endTime2.errors" class="invalid-feedback">
                      <div *ngIf="f.endTime2.errors.required">End Time is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" [ngClass]="{ 'active show': firstActiveTab == 'weekday_3' }"id="weekday_3" role="tabpanel">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="startTime3">Start Time</label>
                    <input type="time" class="form-control" required id="startTime3" formControlName="startTime3"
                           [ngClass]="{ 'is-invalid':  (f.startTime3.dirty || f.startTime3.touched) && f.startTime3.errors }">
                    <div *ngIf="f.startTime3.errors" class="invalid-feedback">
                      <div *ngIf="f.startTime3.errors.required">Start Time is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="endTime3">End Time</label>
                    <input type="time" class="form-control" required id="endTime3" formControlName="endTime3"
                           [ngClass]="{ 'is-invalid':  (f.endTime3.dirty || f.endTime3.touched) && f.endTime3.errors }">
                    <div *ngIf="f.endTime3.errors" class="invalid-feedback">
                      <div *ngIf="f.endTime3.errors.required">End Time is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" [ngClass]="{ 'active show': firstActiveTab == 'weekday_4' }"id="weekday_4" role="tabpanel">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="startTime4">Start Time</label>
                    <input type="time" class="form-control" required id="startTime4" formControlName="startTime4"
                           [ngClass]="{ 'is-invalid':  (f.startTime4.dirty || f.startTime4.touched) && f.startTime4.errors }">
                    <div *ngIf="f.startTime4.errors" class="invalid-feedback">
                      <div *ngIf="f.startTime4.errors.required">Start Time is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="endTime4">End Time</label>
                    <input type="time" class="form-control" required id="endTime4" formControlName="endTime4"
                           [ngClass]="{ 'is-invalid':  (f.endTime4.dirty || f.endTime4.touched) && f.endTime4.errors }">
                    <div *ngIf="f.endTime4.errors" class="invalid-feedback">
                      <div *ngIf="f.endTime4.errors.required">End Time is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" [ngClass]="{ 'active show': firstActiveTab == 'weekday_5' }"id="weekday_5" role="tabpanel">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="startTime5">Start Time</label>
                    <input type="time" class="form-control" required id="startTime5" formControlName="startTime5"
                           [ngClass]="{ 'is-invalid':  (f.startTime5.dirty || f.startTime5.touched) && f.startTime5.errors }">
                    <div *ngIf="f.startTime5.errors" class="invalid-feedback">
                      <div *ngIf="f.startTime5.errors.required">Start Time is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="endTime5">End Time</label>
                    <input type="time" class="form-control" required id="endTime5" formControlName="endTime5"
                           [ngClass]="{ 'is-invalid':  (f.endTime5.dirty || f.endTime5.touched) && f.endTime5.errors }">
                    <div *ngIf="f.endTime5.errors" class="invalid-feedback">
                      <div *ngIf="f.endTime5.errors.required">End Time is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" [ngClass]="{ 'active show': firstActiveTab == 'weekday_6' }"id="weekday_6" role="tabpanel">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="startTime6">Start Time</label>
                    <input type="time" class="form-control" required id="startTime6" formControlName="startTime6"
                           [ngClass]="{ 'is-invalid':  (f.startTime6.dirty || f.startTime6.touched) && f.startTime6.errors }">
                    <div *ngIf="f.startTime6.errors" class="invalid-feedback">
                      <div *ngIf="f.startTime6.errors.required">Start Time is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="endTime5">End Time</label>
                    <input type="time" class="form-control" required id="endTime6" formControlName="endTime6"
                           [ngClass]="{ 'is-invalid':  (f.endTime6.dirty || f.endTime6.touched) && f.endTime6.errors }">
                    <div *ngIf="f.endTime6.errors" class="invalid-feedback">
                      <div *ngIf="f.endTime6.errors.required">End Time is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group small">
            This schedule will be followed for automatically creating campaign batches for a big campaign. You still have the flexibility to manually create campaigns outside of this schedule.
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-secondary btn-lg mr-1" [disabled]="!theForm.valid || theForm.pristine">Save</button>
            <button type="button" class="btn btn-primary btn-lg" (click)="goBack()">Cancel</button>

            <button type="button" class="btn btn-info btn-lg float-right" (click)="sendTestEmail()">Send Test Email</button>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>

