<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-lg-10 offset-lg-1">
      <div class="jumbotron mt-1">
        <form [formGroup]="companyForm" (ngSubmit)="onSubmit()">
          <div class="row mb-3">
            <div class="col-sm-8">
              <a *ngIf="currentUser.paymentProfileId!=='free'" class="btn btn-link float-right mt-1" routerLink="/client/payment" >Payment Details</a>
              <h3 class="py-2">{{currentCompany.clientName}}</h3>
              <div class="form-group">
                <label class="required-label" for="name">Company Name</label>
                <input type="text" id="name" maxlength="250" formControlName="clientName" class="form-control"
                       [ngClass]="{ 'is-invalid':  f.clientName.dirty && f.clientName.errors }" />
                <div *ngIf="f.clientName.errors" class="invalid-feedback">
                  <div *ngIf="f.clientName.errors.required">Company Name is required</div>
                </div>
              </div>
              
              <!--<hr>-->
              <div class="row">
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="website">Website</label>
                    <input type="url" id="website" maxlength="250" formControlName="website" class="form-control"
                           [ngClass]="{ 'is-invalid':  f.website.dirty && f.website.errors }" />
                    <div *ngIf="f.website.errors" class="invalid-feedback">
                      <div *ngIf="f.website.errors.pattern">Please enter a valid URL</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="phone">Phone</label>
                    <input type="tel" formControlName="phone" id="phone" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="streetaddress">Street Address</label>
                <input type="text" id="streetaddress" maxlength="250" formControlName="streetaddress" class="form-control" />
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="city">City</label>
                    <input type="text" id="city" maxlength="250" formControlName="city" class="form-control" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="state">State</label>
                    <input type="text" id="state" maxlength="2" formControlName="state" class="form-control" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="zip">Zip</label>
                    <input type="text" id="zip" maxlength="50" formControlName="zip" class="form-control" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-4 card-image-container mt-5 pt-2">
              <label>Company Logo</label>
              <div class="image-container mb-2">
                <img class="img-fluid ml-1" *ngIf="!companyLogoFile" src="/assets/img/placeholder-image.png" width="210" alt="image upload placehold">
                <img class="img-fluid ml-1" *ngIf="companyLogoFile" [src]="companyLogoFile" width="210" alt="image upload placeholder'">
              </div>
              <div class="custom-file mb-2">
                <input type="file" class="custom-file-input" id="customFile" formControlName="logoFile" (change)="onFileChange($event.target.files, 'companyLogoFile')" accept="image/png, image/jpeg">
                <label class="custom-file-label" for="customFile">Choose file</label>
              </div>
              <!--<label for="color">Company Color</label>
              <input type="text" id="color" maxlength="7" formControlName="brandColor" class="form-control" placeholder="Hex Value" [(ngModel)]="companyForm.value.brandColor" />
              <input type="color" id="color" formControlName="brandColor" class="form-control" style="cursor:pointer;" placeholder="Color picker" [(ngModel)]="companyForm.value.brandColor" />
              <hr>-->
              <!--<h5>Social Media URLs</h5>
              <div class="form-group">
                <label for="facebook">Facebook</label>
                <input type="url" id="facebook" formControlName="facebookUrl" class="form-control"
                       [ngClass]="{ 'is-invalid':  f.facebookUrl.dirty && f.facebookUrl.errors }" />
                <div *ngIf="f.facebookUrl.errors" class="invalid-feedback">
                  <div *ngIf="f.facebookUrl.errors.pattern">Please enter a valid URL</div>
                </div>
              </div>
              <div class="form-group">
                <label for="twitter">Twitter</label>
                <input type="url" id="twitter" formControlName="twitterUrl" class="form-control"
                       [ngClass]="{ 'is-invalid':  f.twitterUrl.dirty && f.twitterUrl.errors }" />
                <div *ngIf="f.twitterUrl.errors" class="invalid-feedback">
                  <div *ngIf="f.twitterUrl.errors.pattern">Please enter a valid URL</div>
                </div>
              </div>
              <div class="form-group">
                <label for="instagram">Instagram</label>
                <input type="url" id="instagram" formControlName="instagramUrl" class="form-control"
                       [ngClass]="{ 'is-invalid':  f.instagramUrl.dirty && f.instagramUrl.errors }" />
                <div *ngIf="f.instagramUrl.errors" class="invalid-feedback">
                  <div *ngIf="f.instagramUrl.errors.pattern">Please enter a valid URL</div>
                </div>
              </div>-->
            </div>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-secondary btn-lg mr-1" [disabled]="!companyForm.valid || companyForm.pristine">Save</button>
            <button type="button" class="btn btn-primary btn-lg" (click)="goBack()">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

