import { Component, OnInit, ViewContainerRef, ViewChild, OnDestroy } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
import { formatDate, formatPercent } from '@angular/common';
import { AuthenticationService } from '../../services/authentication.service';
import { CampaignMessageService } from '../../services/campaign-message.service';
import { MatDialog } from '@angular/material';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { EventInput } from '@fullcalendar/core';
import { EventEmitter } from 'events';
import { Observable, interval, Subscription, of } from "rxjs";
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-campaign-calendar',
  templateUrl: './campaign-calendar.component.html',
  styleUrls: ['./campaign-calendar.component.scss']
})
export class CampaignCalendarComponent implements OnInit, OnDestroy {

  @ViewChild('calendar') calendarComponent: FullCalendarComponent; // the #calendar in the template

  calendarPlugins = [dayGridPlugin, timeGrigPlugin, listPlugin, interactionPlugin]; // important!
  campaigns: any = [];
  currentUser;
  public calendarEvents: EventInput = [];
  calendarWeekends = true;
  routeSubscription: any; // this will allow to reload component without window.location.realod()
  private timerSubscription: Subscription = new Subscription();
  isActive: boolean = true;

  constructor(
    private authService: AuthenticationService,
    private campaignMsgService: CampaignMessageService,
    public dialog: MatDialog,
    public view: ViewContainerRef,
    private flash: FlashMessagesService,
    private route: ActivatedRoute,
    private router: Router
  )
  {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnInit() {

    this.authService.currentUser.subscribe(x => this.currentUser = x);
    this.calendarComponent.allDayHtml = "No Time";
    this.calendarComponent.noEventsMessage = "No campaigns scheduled";
    this.calendarComponent.buttonText = {
      today: 'Today',
      month: 'Month',
      week: 'Week',
      day: 'Day',
      list: 'List'
    };
    this.calendarComponent.listDayFormat = {
      // will produce something like "Tuesday, September 18, 2018"
      month: 'long',
      year: 'numeric',
      day: 'numeric',
      weekday: 'long'
    }
    this.calendarComponent.listDayAltFormat = false;

    //this.calendarComponent.eventRender.emit = this.eventFormatter;
    //this.calendarComponent.eventClick.emit = this.eventClick;
    let filter = localStorage.getItem('crm_calendar_active_filter');
    console.log("filter", filter);
    if (filter == null) {
      filter = this.isActive.toString();
      localStorage.setItem("crm_calendar_active_filter", filter);
    } else {
      this.isActive = filter === 'true' ? true : false;
    }
    this.refreshData();

  }

  setFilter() {

    localStorage.setItem("crm_calendar_active_filter", this.isActive.toString());
    this.refreshData();
  }
  private refreshData() {

    this.campaignMsgService.getAllCampaignMessages(this.isActive).subscribe(cmp => {
      //
      this.campaigns = cmp;
      //
      //this.subscribeToData();
      //
      this.calendarEvents = [];
      this.campaigns.forEach((item, index) => {
        this.createCalendarItem(item);
      });

      console.log(this.calendarEvents);

      // --- defaultView doesn't work, so using changeView instead ---
      this.calendarComponent.getApi().changeView('listMonth');

    });

  }
  //private subscribeToData(): void {

  //  this.timerSubscription = interval(10000).subscribe(() => this.refreshData());
  //}

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  private createCalendarItem(item: any) {

    let start: Date = new Date(item.startDate);
    let allDay = true;
    if (item.startTime !== null) {
      const y = start.getFullYear();
      const m = start.getMonth() + 1;
      const d = start.getDate();
      const dd = y + '-' + m + '-' + d + ' ' + item.startTime;
      start = new Date(dd);
      allDay = false;
    }
    this.calendarEvents.push({
      'id': item.id,
      'title': item.campaignTitle,
      'start': start,
      'allDay': allDay,
      'extendedProps': item
    });
  }

  handleDateClick(arg) {

    console.log("dateClick", arg);

    this.createCampaign(arg);
  }
  handleDayClick(e) {

    this.calendarComponent.getApi().changeView('timeGridDay', e);
  }

  eventFormatter(info) {

    //console.log("eventformatter",info);

    //info.el.title = info.event.title + " (# of contacts: " + info.event.extendedProps.campaignContactsCount + ")";
    let canEdit = true;
    let restarted = false;
    info.el.style.cursor = 'pointer';
    if (info.event.extendedProps.isStarted) {
      canEdit = false;
    }
    else // if started
    {
      if (!info.event.extendedProps.isPaused) {
        if (info.event.allDay === false) {
          const now = new Date();///
          //console.log("event start", info.event.start, now)
          if (info.event.start < now) {
            canEdit = false;
            restarted = true;
          }
        }
      }
    }
    if (!canEdit && !restarted) {
      info.el.style.backgroundColor = "#ddd";
      //info.el.style.textDecoration = "line-through";
      info.el.style.color = "#999";
    }
    //else {
    //  info.el.style.cursor = 'pointer';
    //}

    //console.log(info.view.type);

    if (info.view.type === 'listMonth') {

      let contactsHtml = `<span> (${info.event.extendedProps.campaignContactsCount} contacts)</span>`;
      if (info.event.extendedProps.enableTracking) {
        contactsHtml += `<i class="ml-3 far fa-envelope-open" title="Email Opened Tracking is Enabled"></i>`;
        var sent: number = info.event.extendedProps.emailContactsSent;
        var opened: number = info.event.extendedProps.emailOpenedCount;

        let openrate = sent > 0 ? formatPercent(opened/sent, 'en-US', '0.1-2') : '';

        contactsHtml += ` <b>${openrate}</b>`;
       
      }
      let restartHtml = ``;
      if (restarted) {
        restartHtml = `<span class='alert-info float-right'>&nbsp;re-sending failed emails queued!&nbsp;</span>`
      }

      let contactsWarnHtml = ``;

      if (canEdit) {

        let pauseBtnHtml = ``;
        if (info.event.extendedProps.isPaused) {
          pauseBtnHtml = `<button id='btnStartEvent' class="btn btn-sm btn-outline-secondary float-right" style = "width:55px" > <i class="fas fa-xs fa-play" > Start </i></button>`;
        } else {
          pauseBtnHtml = `<button id='btnPauseEvent' class="btn btn-sm btn-outline-info float-right" style = "width:55px" > <i class="fas fa-xs fa-pause" > Pause </i></button>`;
        }
        //<button * ngIf="!params.data.isStarted && params.colDef.field=='isPaused' && params.value==false"(click) = "invokeParentMethod('pause')" class="btn btn-sm btn-outline-info" style = "width:60px" > <i class="fas fa-xs fa-pause" > Pause < /i></button >
        //  <button * ngIf="!params.data.isStarted && params.colDef.field=='isPaused' && params.value==true"(click) = "invokeParentMethod('restart')" class="btn btn-sm btn-outline-secondary" style = "width:60px" > <i class="fas fa-xs fa-play" > Start < /i></button >

        const deleteBtnHtml = `   
        <button id='btnDeleteEvent' class="btn btn-sm btn-outline-danger float-right ml-1" title="Delete Campaign" >
        <i class="fas fa-trash-alt" > </i>
        </button >
        `;

        info.el.cells[2].innerHTML += contactsHtml + deleteBtnHtml + pauseBtnHtml;

      } else {

        if (info.event.extendedProps.campaignContactsHaveNotSent
          || info.event.extendedProps.campaignContactsHaveFailed) {

          contactsWarnHtml = `<span class="ml-2 text-danger" title="Have Failed or Not Sent emails"><i class="fa fa-exclamation-circle" aria-hidden="true"></i><span>`
        }

        info.el.cells[2].innerHTML += contactsHtml + contactsWarnHtml + restartHtml;
      }
    }
  }

  eventClick(info) {

    console.log(info);

    if (!info.event.extendedProps.isStarted) {

      info.jsEvent.preventDefault(); // don't let the browser navigate

      if (info.jsEvent.srcElement.id === 'btnDeleteEvent' ||
        info.jsEvent.srcElement.parentElement.id === 'btnDeleteEvent') {

        event.cancelBubble = true;
        this.deleteCampaign(info.event.id, info.event.title);
      }
      else if (info.jsEvent.srcElement.id === 'btnStartEvent' ||
        info.jsEvent.srcElement.parentElement.id === 'btnStartEvent') {

        this.pauseCampaign(info.event.extendedProps.campaignId, info.event.id, 'start');
      }
      else if (info.jsEvent.srcElement.id === 'btnPauseEvent' ||
        info.jsEvent.srcElement.parentElement.id === 'btnPauseEvent') {

        this.pauseCampaign(info.event.extendedProps.campaignId, info.event.id, 'pause');
      }
      else {
        this.router.navigate(['/', 'campaign', 'message', info.event.id]);
      }

      //window.location.href = '/campaign/message/' + info.event.id;
    } else {
      // -- just to allow to view the template ---
      //this.router.navigate(['/', 'template', info.event.extendedProps.emailMessageId, 'edit']);
      this.router.navigate(['/', 'campaign', 'message', info.event.id]);
    }
  }

  pauseCampaign(campaignId, id, action) {

    let pause_param: boolean = (action === 'pause' ? true : false);
    this.campaignMsgService.pauseCampaignMessage(campaignId, id, pause_param).subscribe(response => {
      this.flash.show(`Successfully ${action}ed campaign`, { cssClass: 'alert-success', timeout: 5000 });
      this.calendarEvents.forEach((item, index) => {
        if (item.id == id) {
          //alert(data.id + ":" + pause_param);
          item.extendedProps.isPaused = pause_param;
        }
      });
    });
  }

  createCampaign(arg) {

    let d = formatDate(arg.date, 'MMMM dd yyyy', 'en-US');
    let t = formatDate(arg.date, 'hh:mm a', 'en-us');
    let root_elm = this.view;
    let msg = `Would you like to schedule a campign on <b>${d}</b>`;
    if (!arg.allDay) {
      msg += ` at <b>${t}</b>`
    }

    //console.log(root_elm);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {
        data: msg + "?",
        viewContainerRef: root_elm,
      });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        this.router.navigate(['/', 'campaign', 'message'], { queryParams: { dt: arg.dateStr } });
      }
    });

  }
  deleteCampaign(id, name) {


    let root_elm = this.view;
    //console.log(root_elm);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {

        data: `Are you sure you want to delete the campaign "${name}"?`,
        viewContainerRef: root_elm,
      });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.campaignMsgService.deleteCampaignMessage(id).subscribe(response => {
          this.flash.show('Successfully deleted campaign', { cssClass: 'alert-success', timeout: 5000 });

          this.calendarEvents.forEach((item, index) => {
            if (item.id == id) {
              this.calendarEvents.splice(index, 1);
            }
          });
          //this.removeSelected()
        })
      }
    });
  }
}
