<h1 mat-dialog-title>Upload Contacts</h1>

<mat-dialog-content class="card-body">
  <form [formGroup]="contactUploadForm" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <!--<div class="row no-gutters">-->
      <!--<div class="col-md-12">
  <div class="card-body">-->
      <!--<div class="custom-file mb-3">
    <label for="file">Upload excel file with contacts</label>
    <input type="file" class="form-control-file"
           accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
           id="file"
           formControlName="file"
           (change)="onFileChange($event.target.files)" />

  </div>-->
      <div class="custom-file mb-3">
        <label class="custom-file-label" #labelImport for="file">Choose an Excel file with contacts</label>

        <input type="file" class="custom-file-input"
               accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
               id="file"
               formControlName="file"
               (change)="onFileChange($event.target.files)" />
      </div>

      <a download="ContactUploadTemplate" target="_blank" href="/assets/files/ContactUploadTemplate.xlsx">
        Click here to download a sample file
      </a>
      <!--</div>
  </div>-->
      <!--<div class="col-md-12 p-4">

    <button type="submit" class="btn btn-secondary btn-lg float-right" [disabled]="!f.file.value">Submit</button>

  </div>-->
      <!--<mat-dialog-action class="float-right">
    <button class="btn btn-primary" (click)="onNoClick()" cdkFocusInitial>Cancel</button>
    &nbsp;
    <button type="submit" class="btn btn-secondary" [disabled]="!f.file.value">Submit</button>
  </mat-dialog-action>-->

    </div>
  </form>
</mat-dialog-content>
<mat-dialog-action class="float-right">
  <button class="btn btn-primary" (click)="onNoClick()" cdkFocusInitial>Cancel</button>
  &nbsp;
  <button type="submit" (click)="onSubmit()" class="btn btn-secondary" [disabled]="!f.file.value">Submit</button>
</mat-dialog-action>
