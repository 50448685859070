<div class="container-fluid">

  <h1>Analytics</h1>
  <!--<h5 class="mt-5">Date Range</h5>-->
  <fieldset class="border rounded p-3">
    <div class="row mt-2">
      <div [ngClass]="(currentUser.roleId < 3)?'col-md-2':'col-md-3'">
        <label for="range" class="font-weight-bold">Date Range</label>
        <select class="form-control" name="range" [(ngModel)]="rangeSelected" (change)="getRange($event)">
          <option value="30">Last 30 days</option>
          <option value="90">Last 90 days</option>
          <option value="M">This Month</option>
          <option value="Y">This Year</option>
          <!--<option value="-">---</option>-->
        </select>
      </div>
      <div [ngClass]="(currentUser.roleId < 3)?'col-md-2':'col-md-3'">
        <label for="startdate" class="position-relative ml-2" style="top:40px;">Start</label>
        <input type="date" class="form-control text-right" placeholder="Start Date" name="startdate" [(ngModel)]="start" (change)="getStats($event)" />
      </div>
      <div [ngClass]="(currentUser.roleId < 3)?'col-md-2':'col-md-3'">
        <label for="enddate" class="position-relative ml-2" style="top:40px;">End</label>
        <input type="date" class="form-control text-right" placeholder="End Date" name="enddate" [(ngModel)]="end" (change)="getStats($event)" />
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="industry" class="font-weight-bold">Industry</label>
          <select class="form-control" name="industry" id="industry" [(ngModel)]="industryid" (change)="getStats(null)">
            <option value="0">All Industries</option>
            <option *ngFor="let item of companyIndustries" [value]="item.id">{{item.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3" *ngIf="currentUser.roleId < 3">
        <label for="users" class="font-weight-bold">User</label>
        <select class="form-control" name="users" id="users" [(ngModel)]="userid" (change)="getStats(null)">
          <option value="0">All Users</option>
          <option *ngFor="let item of users" [value]="item.id">{{item.firstName}} {{item.lastName}} &lt;{{item.email}}&gt;</option>
        </select>
      </div>
    </div>
    <!--<div class="row" *ngIf="currentUser.roleId < 3">
      
    </div>-->
  </fieldset>
  

  <div class="row mt-4 font-weight-bold">
    <div class="col-md-12">
      Total Campaigns: <span class="text-big mr-5">{{totalCampaigns | number: '': 'en-US'}}</span>
      Emails Sent: <span class="text-big mr-5">{{totalEmails | number: '': 'en-US'}}</span>
      <!--Emails Opened: <span class="text-big mr-5">{{totalEmailsOpened | number: '': 'en-US'}}</span>-->
      Contacts Reached: <span class="text-big">{{totalContacts | number: '': 'en-US'}}</span>
    </div>
    <!--<div class="col-md-4 text-center">Emails Sent: <span class="text-big">{{totalEmails | number: '': 'en-US'}}</span></div>
    <div class="col-md-4 text-center">Contacts Reached: <span class="text-big">{{totalContacts | number: '': 'en-US'}}</span></div>-->
  </div>
  <div class="row mt-2">
    <div class="col-md-3">
      <div class="card card-header rounded-bottom text-center p-3">
        <span class="font-weight-bold">Opened</span>
        <span class="text-big font-weight-bold">{{totalEmailsOpened/totalEmails | percent:'0.1-2'}}</span>
        <br />
        <span><span class="text-big">{{totalEmailsOpened | number: '': 'en-US'}}</span> emails</span>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card card-header rounded-bottom text-center p-3">
        <span class="font-weight-bold">Responded</span>
        <span class="text-big font-weight-bold">{{contactsResponded/totalContacts | percent:'0.1-2'}}</span>
        <br />
        <span><span class="text-big">{{contactsResponded | number: '': 'en-US'}}</span> contacts</span>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card card-header rounded-bottom text-center p-3">
        <span class="font-weight-bold">Unsubscribed</span>
        <span class="text-big font-weight-bold">{{contactsUnsubscribed/totalContacts | percent:'0.1-2'}}</span>
        <br />
        <span><span class="text-big">{{contactsUnsubscribed | number: '': 'en-US'}}</span> contacts</span>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card card-header rounded-bottom text-center p-3">
        <span class="font-weight-bold">Bounced</span>
        <span class="text-big font-weight-bold">{{contactsBounced/totalContacts | percent:'0.1-2'}}</span>
        <br />
        <span><span class="text-big">{{contactsBounced | number: '': 'en-US'}}</span> contacts</span>
      </div>
    </div>
  </div>

</div>
