import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreviousURLService {
  previousUrl;

  constructor(
    private router: Router
  ) {
    this.router.events.pipe(
      filter(e => e instanceof RoutesRecognized),
      pairwise()
      ).subscribe((event: any[]) => {
        this.previousUrl = event[0]['urlAfterRedirects'];
      });
   }
   getPreviousUrl() {
      return this.previousUrl;
   }
   goToPreviousUrl() {
      this.router.navigateByUrl(this.previousUrl);
   }
}
