<div class="container-fluid text-center" style="margin-top:50px" *ngIf="user_email">
    <p>
      We've sent an email to <strong>{{user_email}}</strong> with a link to verify your email address.
    </p>
    <p>
      This helps to ensure that we have your correct email.
    </p>
    <p>
      Please <a href="mailto:{{support_email}}">contact us</a>, if you do not receive this email and we'll resend to you.
    </p>
</div>
<div class="container-fluid text-center" style="margin-top:50px" *ngIf="sucessMessage">
  <p>{{sucessMessage}}</p>
</div>
