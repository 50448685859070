import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';

import { AuthGuard } from './guards/auth.guard';
import { Role } from './models/role';

import { UserComponent } from './components/user/user.component';
import { MyProfileComponent } from './components/user/my-profile/my-profile.component';

import { LandingComponent } from './components/landing/landing.component';

import { ForgotPasswordComponent } from './components/user/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/user/reset-password/reset-password.component';

import { VerifyEmailComponent } from './components/user/verify-email/verify-email.component';

import { EmailTemplateComponent } from './components/email-template/email-template.component';
import { EditTemplateComponent } from './components/email-template/edit-template/edit-template.component';
import { EmailCampaignUploadComponent } from './components/email-campaign-upload/email-campaign-upload.component';
import { CampaignMessageComponent } from './components/campaign-message/campaign-message.component';
import { EditCampaignMessageComponent } from './components/campaign-message/edit-campaign-message/edit-campaign-message.component';
import { EmailSettingsComponent } from './components/user/email-settings/email-settings.component';
import { CampaignCalendarComponent } from './components/campaign-calendar/campaign-calendar.component';
import { ContactComponent } from './components/contact/contact.component';
import { SignupComponent } from './components/signup/signup.component';
import { MyCompanyComponent } from './components/user/my-company/my-company.component';
import { CompanyComponent } from './components/company/company.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { IndustryComponent } from './components/industry/industry.component';
import { ActivateComponent } from './components/user/activate/activate.component';
import { PaymentProfileComponent } from './components/subscriptions/payment-profile/payment-profile.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { CompanyDetailsComponent } from './components/company/company-details/company-details.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent
  },
  {
    path: 'verifyemail',
    component: VerifyEmailComponent
  },
  {
    path: 'register',
    component: SignupComponent
  },
  {
    path: 'activate',
    component: ActivateComponent
  },
  {
    path: 'users',
    component: UserComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.SiteAdmin] }
  },
  {
    path: 'profile',
    component: MyProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'client',
    component: MyCompanyComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.SiteAdmin] }

  },
  {
    path: 'client/payment',
    component: PaymentProfileComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'emailsettings',
    component: EmailSettingsComponent,
    canActivate: [AuthGuard]
  },
  {
      path: '',
      component: LandingComponent
  },
  {
    path: 'template/add',
    component: EditTemplateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'template/:template_id/edit',
    component: EditTemplateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'template',
    component: EmailTemplateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'template/:template_id/campaign/upload',
    component: EmailCampaignUploadComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'campaigns',
    component: CampaignCalendarComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'campaignlist',
    component: CampaignMessageComponent,
    canActivate: [AuthGuard]
  },
  {
    // create new campaign message
    path: 'campaign/message',
    component: EditCampaignMessageComponent,
    canActivate: [AuthGuard]
  },
  { // edit the existing campaign message
    path: 'campaign/message/:message_id',
    component: EditCampaignMessageComponent,
    canActivate: [AuthGuard]
  },
  {
    // create new followup from the existing message
    path: 'campaign/:campaign_id/message/:message_id/followup',
    component: EditCampaignMessageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contacts',
    component: ContactComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'companies',
    component: CompanyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company/:company_id',
    component: CompanyDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'industries',
    component: IndustryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contactus',
    component: ContactUsComponent
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
