<div class="container-fluid">
  <div class="row">
    <div class="col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
      <div class="jumbotron mt-1">
        <h3>Activate Your Account - <small>{{ user.email}} | {{ user.clientName}}</small></h3>    
        <form [formGroup]="theForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="firstname">First Name</label>
                <input type="text" maxlength="50" id="firstname" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': (submitted || f.firstName.dirty) && f.firstName.errors }" />
                <div *ngIf="f.firstName.errors" class="invalid-feedback">
                  <div *ngIf="f.firstName.errors.required">First Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="lastname">Last Name</label>
                <input type="text" maxlength="50" id="lastname" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': (submitted || f.lastName.dirty) && f.lastName.errors }" />
                <div *ngIf="f.lastName.errors" class="invalid-feedback">
                  <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="password1">Password</label>
                <input type="password" id="password1" formControlName="password" autocomplete="new-password" class="form-control" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="password2">Confirm Password</label>
                <input type="password" id="password2" formControlName="passwordConfirm" class="form-control" />
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <div class="form-group">
                <button type="submit" class="btn btn-secondary btn-lg mr-1" [disabled]="!theForm.valid || theForm.pristine">Save</button>
                <button type="button" class="btn btn-primary btn-lg" (click)="goBack()">Cancel</button>
              </div>
            </div>
          </div>       
        </form>
      </div>
    </div>
  </div>
</div>

