<div id="wrapper" [ngClass]="{'flex-bar': currentUser}">
  <app-loader></app-loader>
  <header *ngIf="!currentUser">
    <nav class="navbar navbar-expand-lg navbar-light py-lg-3" *ngIf="!currentUser">
      <div class="container">
        <a href="/" alt="home"><H4>InvontoCRM</H4></a>
        <ul class="nav justify-content-end">
          <li class="nav-item">
            <a href="#" class="nav-item nav-link text-nowrap btn btn-link" routerLink="/login">Login</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-item nav-link text-nowrap btn btn-outline-primary" routerLink="/register">Sign Up</a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <header id="sidebar" *ngIf="currentUser">
    <nav class="navbar flex-column sticky-top d-none d-lg-block bg-dark">
      <h4 class="text-white p-4">InvontoCRM</h4>
      <!--<img class="navbar-brand" src="/assets/img/logo_w.svg" alt="engagar logo">-->
      <a href="#" class="nav-item nav-link text-nowrap" routerLink="/campaigns" routerLinkActive="active-link">
        <div class="icons"><i class="far fa-calendar-alt"></i></div>
        Campaigns
      </a>
      <a href="#" *ngIf="haveCompanies" class="nav-item nav-link text-nowrap" routerLink="/companies" routerLinkActive="active-link">
        <div class="icons"><i class="far fa-building"></i></div>
        Companies
      </a>
      <a href="#" *ngIf="haveContacts" class="nav-item nav-link text-nowrap" routerLink="/contacts" routerLinkActive="active-link">
        <div class="icons"><i class="far fa-address-card"></i></div>
        Contacts
      </a>
      <a href="#" class="nav-item nav-link text-nowrap" routerLink="/industries" routerLinkActive="active-link">
        <div class="icons"><i class="fas fa-industry"></i></div>
        Industries
      </a>
      <a href="#" class="nav-item nav-link text-nowrap" routerLink="/analytics" routerLinkActive="active-link">
        <div class="icons"><i class="fas fa-chart-line"></i></div>
        Analytics
      </a>
      <a href="#" class="nav-item nav-link text-nowrap" routerLink="/profile" routerLinkActive="active-link">
        <div class="icons"><i class="fas fa-user"></i></div>
        My Profile
      </a>
      <a href="#" *ngIf="isAdmin" class="nav-item nav-link text-nowrap" routerLink="/client" routerLinkActive="active-link">
        <div class="icons"><i class="fas fa-building"></i></div>
        My Company
      </a>
      <a href="#" *ngIf="isAdmin" class="nav-item nav-link text-nowrap" routerLink="/users" routerLinkActive="active-link">
        <div class="icons"><i class="fas fa-users"></i></div>
        Users
      </a>
      <a href="#" class="nav-item nav-link text-nowrap" routerLink="/emailsettings" routerLinkActive="active-link">
        <div class="icons"><i class="fas fa-envelope"></i></div>
        Email Settings
      </a>
      <a href="#" class="nav-item nav-link text-nowrap" (click)="logout()">
        <div class="icons"><i class="fas fa-power-off"></i></div>
        Log Out
      </a>
    </nav>
    <nav class="d-lg-none navbar-light">
      <button id="toggle-button" (click)="toggleSidebar()" class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarToggle" aria-controls="navbarToggle" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse sidebar-menu" id="navbarToggle">
        <a href="#" class="nav-item nav-link" routerLink="/campaigns">
          <div class="nav-logo mr-3 d-lg-none" *ngIf="currentCompany.clientLogo">
            <img [src]="currentCompany.clientLogo" routerLink="/" alt="company logo">
            <label class="float-right mt-3 mr-3">{{currentUser.email}}</label>
          </div>
          <div class="mt-3" *ngIf="!currentCompany.clientLogo">
            <h5>{{currentCompany.clientName}}</h5>
          </div>
        </a>
        <a href="#" class="nav-item nav-link text-nowrap" routerLink="/campaigns">Campaigns</a>

        <a href="#" *ngIf="haveCompanies" class="nav-item nav-link text-nowrap" routerLink="/companies">Companies</a>
        <a href="#" *ngIf="haveContacts" class="nav-item nav-link text-nowrap" routerLink="/contacts">Contacts</a>

        <a href="#" class="nav-item nav-link text-nowrap" routerLink="/industries">Industries</a>

        <a href="#" class="nav-item nav-link text-nowrap" routerLink="/analytics">Analytics</a>

        <a href="#" class="nav-item nav-link text-nowrap" routerLink="/profile">My Profile</a>
        <a href="#" *ngIf="isAdmin" class="nav-item nav-link text-nowrap" routerLink="/client">My Company</a>
        <a href="#" *ngIf="isAdmin" class="nav-item nav-link text-nowrap" routerLink="/users">Users</a>

        <a href="#" class="nav-item nav-link text-nowrap" routerLink="/emailsettings">Email Settings</a>
        <a href="#" class="nav-item nav-link text-nowrap" (click)="logout()">Log Out</a>
      </div>
    </nav>
  </header>
  <main id="content-wrapper">
    <!-- content nav -->
    <nav class="navbar navbar-expand bg-gray sticky-top mb-4" *ngIf="currentUser">
      <div class="container">
        <div class="navbar-nav d-none d-lg-block">
          <a href="#" class="btn btn-primary mr-2" routerLink="/campaign/message">Add Campaign</a>
          <button href="#" class="btn btn-primary" (click)="uploadContacts()">Upload Contacts</button>
        </div>
        <div class="navbar-nav d-lg-none">
          <a href="#" class="btn btn-link" routerLink="/campaign/message"><i class="fas fa-plus mr-1"></i>Campaign</a>
          <button href="#" class="btn btn-link" (click)="uploadContacts()"><i class="fas fa-plus mr-1"></i>Contacts</button>
        </div>
        <div class="navbar-nav d-none d-lg-block">
          <div class="nav-logo" *ngIf="currentCompany.clientLogo">
            <img [src]="currentCompany.clientLogo" routerLink="/" alt="company logo">
            <label class="float-right mt-3 mr-3">{{currentUser.email}}</label>
          </div>
          <div *ngIf="!currentCompany.clientLogo">
            {{currentUser.email}}&nbsp;&nbsp;|&nbsp;&nbsp;<b>{{currentCompany.clientName}}</b>
          </div>
        </div>
      </div>
    </nav>
    <div class="container-fluid sticky-top">
      <flash-messages></flash-messages>
    </div>
    <router-outlet></router-outlet>
  </main>
  <footer *ngIf="!currentUser" class="footer">
    <p class="text-center text-white">
      Copyright © 2020 InvontoCRM. Made by
      <a class="link-white" href="https://www.invonto.com/" target="_blank">Invonto</a>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a class="link-white" href="#" routerLink="/terms" target="_blank">Terms of Use</a>
      &nbsp;|&nbsp;
      <a class="link-white" href="#" routerLink="/privacy" target="_blank">Privacy Policy</a>

    </p>
  </footer>
</div>
