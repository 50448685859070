<div class="container mb-5">
  <div class="row">
    <div class="col-md-12">
      <h2>Email Templates</h2>
    </div>
  </div>
  <hr>
  <div class="row p-1 border-bottom" *ngFor="let template of email_templates">
    <div class="col-md-2">
      <a class="btn btn-secondary btn-sm" [routerLink]="[ '/template', template.id, 'edit']">Edit</a>
      <button class="btn btn-danger btn-sm" (click)="deleteTemplate(template.id)">Delete</button>
      <br /><br />
      <a class="btn btn-link btn-sm" [routerLink]="[ '/template', template.id, 'campaign','upload']">Schedule Campaign</a>
    </div>
    <div class="col-md-3">
      {{template.subject}}
    </div>

    <div class="col-md-7" [innerHTML]="template.body">

    </div>
  </div>
</div>
