<div class="container mb-5">
  <div class="row">
    <div class="col-md-12">
      <h2>{{pageTitle}}</h2>
    </div>
  </div>
  <hr>
  <form [formGroup]="editEmailForm" (ngSubmit)="onSubmit()">

    <div class="form-group">
      <label for="subject">Subject</label>
      <input type="text" class="form-control" id="subject" readonly="readonly" maxlength="250" formControlName="subject" placeholder="Type email subject here!" />
    </div>

    <label for="body">Body</label>
    <!--[config]="EditorConfig"-->
    <!--[(ngModel)]="email_template.body" [ngModelOptions]="{standalone: true}"-->
    <div class="border">
      <div id="toolbar-container"></div>
      <ckeditor [editor]="Editor"
                (change)="onBodyChange($event)"
                (ready)="onReady($event)"
                [config]="EditorConfig"
                [disabled]="true"
                [(ngModel)]="email_template.body" [ngModelOptions]="{standalone: true}">

      </ckeditor>
    </div>
    <br />
    <!--<div class="form-row">
      <button type="submit" class="btn btn-secondary btn-lg">Save</button>
      <button type="button" class="btn btn-primary btn-lg ml-1" (click)="goBack()">Cancel</button>
      <button type="button" class="btn btn-info btn-light btn-lg ml-1 mr-1" (click)="sendTestEmail()">Send To</button>
      <input type="text" class="form-control col-md-4 p-2 mt-2" id="sendto" formControlName="sendto" maxlength="250" placeholder="Email address" />
    </div>-->
  </form>
</div>
