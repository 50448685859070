import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PreviousURLService } from '../../../services/previous-url.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { EmailMessageService } from '../../../services/email-message.service';
import { CampaignMessageService } from '../../../services/campaign-message.service';
import { formatDate } from '@angular/common';
//import * as DocumentEditor from '@ckeditor/ckeditor5-build-decoupled-document';
//import * as DocumentEditor from '@ckeditor/ckeditor5-build-classic';
import * as DocumentEditor from '../../../../ckeditor5-build-classic-custom/build/ckeditor.js';
import { AgGridAngular } from 'ag-grid-angular';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { EmailSendTestDialogComponent } from '../../email-send-test-dialog/email-send-test-dialog.component';
import { ContactService } from '../../../services/contact.service';
import { MatChipsModule } from '@angular/material';

@Component({
  selector: 'app-edit-campaign-message',
  templateUrl: './edit-campaign-message.component.html',
  styleUrls: ['./edit-campaign-message.component.scss']
})
export class EditCampaignMessageComponent implements OnInit {

  @ViewChild('labelImport') labelImport: ElementRef;
  @ViewChild('labelAttach') labelAttach: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private contactsService: ContactService,
    private route: ActivatedRoute,
    private router: Router,
    private urlService: PreviousURLService,
    private flash: FlashMessagesService,
    private cd: ChangeDetectorRef,
    private emailMsgService: EmailMessageService,
    private campaignMsgService: CampaignMessageService,
    public dialog: MatDialog,
    public view: ViewContainerRef,
  ) { }

  public Editor = DocumentEditor;
  public onReady(editor) {
    //editor.ui.getEditableElement().parentElement.insertBefore(
    //  editor.ui.view.toolbar.element,
    //  editor.ui.getEditableElement()
    //);

    //console.log(editor.ui.view.toolbar.element);

    //document.querySelector('#toolbar-container').appendChild(editor.ui.view.toolbar.element);
  }

  EditorConfig = {
    placeholder: 'Type email content here!',
    toolbar: {
      items: [
        'heading',
        '|',
        'fontFamily',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        'highlight',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'subscript',
        'superscript',
        '|',
        'alignment',
        'indent',
        'outdent',
        '|',
        'codeBlock',
        'blockQuote',
        'insertTable',
        //'imageUpload',
        //'mediaEmbed',
        'undo',
        'redo',
      ]
    },
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:full',
        'imageStyle:side',
        'imageStyle:alignLeft',
        'imageStyle:alignRight',
      ],
      styles: ['full','side','alignLeft','alignRight']
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableProperties',
        'tableCellProperties'
      ]
    },
  };

  formattedDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  message_id: any;
  campaign_id: any;
  emailCampaignUploadForm: FormGroup;
  buttonText = "Save";
  pageTitle = "New Campaign"
  formData = new FormData();
  campaign: any = { emailSubject: '', emailBody: '' };
  initDateTime: string;
  isReadOnly: boolean = false;
  isFollowup: boolean = false;
  includeProspects: boolean = false;
  excludeSocial: boolean = false;
  enableTracking: boolean = false;

  passedContactIds: Array<number>;
  passedCompanyIds: Array<number>;
  passedIndustryIds: Array<number>;

  contacts: any = [];
  selectedRows: any = [];
  selectedContactIds: number[] = [];
  setSentStatus = 1; // 0 - Not Sent; 1 - Sent; 2 - Failed
  attachmentFiles: File[] = [];
  attachmentSavedFiles: any[] = [];
  totalAttachSize: number = 0;

  agGridContactsApi;
  columnDefs = [
    { headerName: '#', valueGetter: 'parseInt(node.id) + 1', width: 50 },
    { headerName: 'Email', field: 'email', width: 230, checkboxSelection: true},
    { headerName: 'First Name', field: 'firstName', width: 100,},
    { headerName: 'Last Name', field: 'lastName', width: 100 },
    { headerName: 'Title', field: 'title', width: 100 },
    { headerName: 'Company', field: 'companyName', width: 140 },
    {
      headerName: 'Email Sent Date', field: 'emailSentDate', width: 150,
      cellRenderer: (data) => {
        if (data.value !== null) {
          return formatDate(data.value, 'dd MMM yyyy hh:mm a', 'en-us')
        }
      }
    },
    {
      headerName: 'Email Status', field: 'emailSentStatus', width: 120,
      cellRenderer: (data) => {
        if (data.data.emailLastOpenedDate != null) {
          var ot = `Opened ${data.data.emailOpenedCount} time`;
          if (data.data.emailOpenedCount > 1) {
            ot += 's';
          }
          return ot;
        } else {

          switch (data.value) {
            case 0:
              return 'Not Sent';
            case 1:
              return 'Sent';
            case 2:
              return 'Failed';
            case 3:
              return 'Bounced';
            case 4:
              return 'Unsubscribed';
            case 5:
              return 'Responded';
          }
        }
        
      }
    },
    {
      headerName: 'Contact Status', field: 'contactStatus', width: 100,
      cellRenderer: (data) => {
        switch (data.value) {
          case 0:
            return 'Inactive';
          case 1:
            return 'Active';
          case 2:
            return 'Contacted';
          case 3:
            return 'Bounced';
          case 4:
            return 'Unsubscribed';
          case 5:
            return 'Responded';
          case 6:
            return 'On Hold'
        }
      }
    },
    {
      headerName: 'Last Opened Date', field: 'emailLastOpenedDate', width: 150,
      cellRenderer: (data) => {
        if (data.value !== null) {
          return formatDate(data.value, 'dd MMM yyyy hh:mm a', 'en-us')
        }
      }
    },
    { headerName: 'Error', field: 'emailSentError', width: 200 },
  ];
  defaultColDef = { resizable: true, sortable: true };
  onGridReady(params) {

    this.agGridContactsApi = params.api;
    this.loadContacts();
  }

  private loadContacts() {
    if (this.message_id !== null && this.campaign_id == null) {
          this.campaignMsgService.getCampaignMessageContacts(this.message_id).subscribe(cnt => {
              this.contacts = cnt;
              console.log(this.contacts);
          });
      }
  }

  ngOnInit() {

    this.emailCampaignUploadForm = this.fb.group({
      campaignTitle: [null, Validators.required],
      startDate: [this.formattedDate, Validators.required],
      startTime: ['', Validators.required],
      emailSubject: [null, Validators.required],
      enableTracking: [{ value: false, disabled: false }],
      file: [null]
    });

    if (history.state.data) {
      this.passedContactIds = history.state.data.contactids;
      this.passedCompanyIds = history.state.data.companyids;
      this.passedIndustryIds = history.state.data.industryids;
    }

    console.log("passedContactIds", this.passedContactIds);
    console.log("passedCompanyIds", this.passedCompanyIds);
    console.log("passedIndustryIds", this.passedIndustryIds);


    this.message_id = this.route.snapshot.paramMap.get('message_id');
    this.campaign_id = this.route.snapshot.paramMap.get('campaign_id');
    this.initDateTime = this.route.snapshot.queryParamMap.get('dt');

    console.log("message_id/dt", this.message_id, this.initDateTime);

    if (this.message_id !== null && this.campaign_id == null) {

      this.pageTitle = "Campaign";
      this.campaignMsgService.getCampaignMessage(this.message_id).subscribe(result => {
        this.campaign = result;
        console.log(this.campaign);
        this.isReadOnly = this.campaign.isStarted;
        this.emailCampaignUploadForm.patchValue(this.campaign);

        if (this.campaign.campaignMessageAttachment) {
          this.campaign.campaignMessageAttachment.forEach((item, index) => {
            const fname = item.emailAttachment.attachmentPath.split("/").pop();
            const size = item.emailAttachment.attachmentSize;
            console.log("file name", fname);
            var file = { 'name': fname, 'id': item.id, 'size': size };
            this.attachmentSavedFiles.push(file);
          });

          this.setAllAttachmentsFileSize();
        }

        if (this.isReadOnly) {
          this.emailCampaignUploadForm.controls.enableTracking.disable();
        }

        if (this.campaign.startDate) {
          this.emailCampaignUploadForm.patchValue({
            startDate: formatDate(this.campaign.startDate, 'yyyy-MM-dd', 'en-US')
          });
        }
      });
    }
    // ---- new followup for existing message_id---
    else if (this.message_id !== null && this.campaign_id !== null) {
      this.pageTitle = "Followup Campaign";
      this.isFollowup = true;

      this.campaignMsgService.getCampaignMessage(this.message_id).subscribe(result => {
        //this.campaign = result;
        let sourceCampaign:any = result;
        console.log(sourceCampaign);

        //let dt = new Date(sourceCampaign.startDate.split('T')[0] + 'T' + sourceCampaign.startTime);
        //console.log("sent date", dt);
        ////'EEEE, MMMM d, y, h:mm:ss a zzzz'
        //let d = formatDate(dt, 'EEE, MMM d, y, h:mm a', 'en-US');
        
        this.campaign.emailBody = `<p>&nbsp;</p>
                                   <blockquote>
                                   <p>On {EmailSentDate_${this.message_id}} {SenderName} wrote:</p>
                                   <div style="margin-left:20px;">
                                    ${sourceCampaign.emailBody}
                                   </div>
                                   </blockquote>`;

        let subj: string = sourceCampaign.emailSubject;
        this.campaign.emailSubject = subj;
        if (!subj.startsWith("RE: ")) {
          this.campaign.emailSubject = "RE: " + subj;
        } 
        this.campaign.campaignTitle = sourceCampaign.campaignTitle;
        this.campaign.enableTracking = sourceCampaign.enableTracking;
        this.emailCampaignUploadForm.patchValue(this.campaign);


      });
    }
    else
    {

      if (this.initDateTime) {

        let hasTime = this.initDateTime.split('T').length > 1;

        let dt = new Date(this.initDateTime);
        if (!hasTime) {
          dt = new Date(this.initDateTime+"T00:00:00");
        }
        let d = formatDate(dt, 'yyyy-MM-dd', 'en-US');
        console.log("formatted date", d);
        this.emailCampaignUploadForm.patchValue({
          startDate: d
        });

        if (hasTime) {
          let t = formatDate(dt, 'HH:mm:ss', 'en-US');
          console.log("formatted time", t);
          this.emailCampaignUploadForm.patchValue({
            startTime: t
          });
        }

      }

    }
    //this.emailMsgService.get(this.message_id).subscribe(result => {
    //  this.email_template = result;
    //  console.log(this.email_template);
    //});
  };

  get f() { return this.emailCampaignUploadForm.controls; }

  onFileChange(files) {
    if (!files) {
      return;
    }
    if (files) {
      const excelFile = files[0];
      console.log(excelFile);
      this.labelImport.nativeElement.innerText = excelFile.name;

      this.formData.set('ExcelFile', excelFile);

      // need to run CD since file load runs outside of zone
      this.cd.markForCheck();
    }
  }

  setAllAttachmentsFileSize() {

    this.totalAttachSize = 0;
    this.attachmentFiles.forEach((item) =>
    {
      this.totalAttachSize += (item.size / 1024 / 1024);
    });
    this.attachmentSavedFiles.forEach((item) => {
      this.totalAttachSize += (item.size / 1024 / 1024);
    });

    if (this.totalAttachSize > 10) {
      this.flash.show("Total attachments size exceeds maximun of 10 MB.", { cssClass: 'alert-danger', timeout: 10000 });
      return false;
    }
    return true;

  }
  onAttachFileChange(files) {
    if (!files) {
      return;
    }
    if (files) {

      console.log(files)
      //this.labelAttach.nativeElement.innerText = '';

      for (let i = 0; i < files.length; i++) {
        //this.labelAttach.nativeElement.innerText += files[i].name;
        this.attachmentFiles.push(<File>files[i]);
      }
      this.setAllAttachmentsFileSize();
      // need to run CD since file load runs outside of zone
      this.cd.markForCheck();
    }
  }
  removeAttachment(attach) {
    const index = this.attachmentFiles.indexOf(attach);
    if (index >= 0) {
      this.attachmentFiles.splice(index,1);
    }
    this.setAllAttachmentsFileSize();

  }

  removeSavedAttachment(attach) {

    let root_elm = this.view;
    console.log(root_elm);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {

        data: `Are you sure you want to delete the attachment "${attach.name}"?`,
        viewContainerRef: root_elm,
      });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // -----
        const index = this.attachmentSavedFiles.indexOf(attach);
        if (index >= 0) {
          console.log(attach);
          // ---  call API to delete attachment --
          this.campaignMsgService.deleteCampaignMessageAttachment(this.message_id, attach.id)
            .subscribe(() => {

              this.flash.show(`Attachment ${attach.name} have been deleted.`, { cssClass: 'alert-success', timeout: 3000 });

              this.attachmentSavedFiles.splice(index, 1);

              this.setAllAttachmentsFileSize();

            });
          // ----------------------------------
        }
      }
    });


  }


  onSelectionChanged() {

    this.selectedRows = this.agGridContactsApi.getSelectedRows();

  }
  setSelectedAsSent() {

    this.selectedContactIds = [];
    this.selectedRows.forEach((item, index) => {
      this.selectedContactIds.push(item.id);
    });

    //console.log(this.selectedContactIds);

    if (this.message_id !== null) {
      this.campaignMsgService.setCampaignMessageContactStatus(this.message_id, this.setSentStatus, this.selectedContactIds)
        .subscribe(() => {
            this.loadContacts();
      });
    }
  }
  deleteSelected() {

    this.selectedContactIds = [];
    this.selectedRows.forEach((item, index) => {
      this.selectedContactIds.push(item.id);
    });

    //console.log(this.selectedContactIds);

    if (this.message_id !== null) {
      this.campaignMsgService.deleteCampaignMessageContacts(this.message_id, this.selectedContactIds)
        .subscribe(result => {
          console.log(result);
          var deleted: any = result;
          this.flash.show(`<b>${deleted.contacts}</b> contacts have been deleted.`, { cssClass: 'alert-success', timeout: 3000 });
          this.loadContacts();
        });
    }
  }

  onSubmit() {
    if (this.totalAttachSize > 10) {
      this.flash.show("Total attachments size exceeds maximun of 10 MB.", { cssClass: 'alert-danger', timeout: 10000 });
      return;
    }
    this.buttonText = 'Submitting...';
    this.setFormData();

    if (this.message_id == null) {

      console.log(this.formData);

      //alert(this.includeProspects);
      this.campaignMsgService.createCampaignMessages(this.formData, this.includeProspects, this.excludeSocial)
        .subscribe(result => {
          let ret: any = result;
          let batches = Math.ceil(ret.totalContacts / ret.maxContactsInBatch);
          let msg = `Successfully started campaign creation job with <b>${ret.totalContacts}</b> total contacts<br>
<b>${batches}</b> email batches will be created in background<br>
You will receive email when job is complete.`

          this.flash.show(msg, { cssClass: 'alert-success', timeout: 10000 });
          this.goBack();
          //--- move it to app.component.ts since no contacts is created yet at this point
          //this.contactsService.getContactsCounts().subscribe(response => {
          //  this.authService.setNewContactCounts(response);
          //  this.goBack();
          //});
        }, error => {
          // got back to the list to prevent multiple new campaigns saved
          console.log(error);
          //this.goBack();
        });
    }
    else if (this.message_id !== null && this.campaign_id !== null)
    {
      this.campaignMsgService.createCampaignFollowupMessage(this.formData, this.campaign_id, this.message_id).subscribe(result => {
        this.flash.show('Successfully scheduled followup campaign', { cssClass: 'alert-success', timeout: 3000 });
        this.goBack();
      }, error => {
          console.log(error);
        // got back to the list to prevent multiple new campaigns saved
        //this.goBack();
      });
    }
    else
    {
      this.campaignMsgService.updateCampaignMessage(this.formData, this.campaign.campaignId, this.campaign.id ).subscribe(result => {
        this.flash.show('Successfully updated campaign', { cssClass: 'alert-success', timeout: 3000 });
        this.goBack();
      });
    }
    this.buttonText = 'Save';
  }
  goBack() {
    this.router.navigate(['/campaigns']);
  }
  followup() {

    //alert(this.campaign.campaignId + ":" + this.message_id);

    this.router.navigate(['/', 'campaign', this.campaign.campaignId, 'message', this.message_id, 'followup']);
  }
  setFormData() {

    let entries = Object.entries(this.emailCampaignUploadForm.value);
    for (let [key, value] of entries) {

      if (key === "startTime" && value === null) {
        value = '';
      }
      this.formData.set(key, String(value));

      console.log(key, String(value));
    }
    //
    //
    if (this.attachmentFiles.length > 0) {
      for (let i = 0; i < this.attachmentFiles.length; i++) {
        this.formData.append('EmailAttachments', this.attachmentFiles[i], this.attachmentFiles[i].name);
      }
    }
    // ---- append passed contacts ----
    if (this.passedContactIds) {

      this.formData.set("ContactIds", this.passedContactIds.join(','));
    }
    if (this.passedCompanyIds) {

      this.formData.set("CompanyIds", this.passedCompanyIds.join(','));
    }
    if (this.passedIndustryIds) {

      this.formData.set("IndustryIds", this.passedIndustryIds.join(','));
    }
  }
  public onBodyChange({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.formData.set("EmailBody", data);
  }

  sendTestEmail(): void {
    const dialogRef = this.dialog.open(EmailSendTestDialogComponent, {
      width: '400px',
      disableClose: false,
      data: {
        firstname: '',
        lastname: '',
        companyname: '',
        email: '',
        campaignMessageId: this.message_id
      }
    });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      if (result && result.email) {
        //--- save the data 1st ---
        this.setFormData();
        this.campaignMsgService.updateCampaignMessage(this.formData, this.campaign.campaignId, this.campaign.id)
          .subscribe(() => {

            this.emailMsgService.send(this.campaign.emailMessageId, result).subscribe(response => {
              console.log(response);
              let r: any = response;
              if (r !== null && r.message) {
                this.flash.show(`Error sending email: ${r.message}`, { cssClass: 'alert-danger', timeout: 6000 });
              }
              else {
                this.flash.show(`Successfully send email to ${result.email}`, { cssClass: 'alert-success', timeout: 3000 });
              }
            });

        });
      }
    });

  }

  resendFailed() {

    this.campaignMsgService.resendCampaignMessage(this.campaign.campaignId, this.campaign.id)
      .subscribe(response => {
        this.flash.show(`Successfully restarted campaign to resend failed emails`, { cssClass: 'alert-success', timeout: 5000 });
        this.goBack();

    });
  }
}
