<div class="container-fluid">
  <div class="row">
    <div class="col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
      <div class="jumbotron mt-1">
        <h3>Payment Profile | {{currentUser.clientName}}</h3>
        <form [formGroup]="theForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-sm-12">
              
              <div class="form-group">
                <label for="email">Email</label>
                <input type="text" maxlength="50" formControlName="customerEmail" class="form-control" [ngClass]="{ 'is-invalid': (submitted || f.customerEmail.dirty) && f.customerEmail.errors }" />
                <div *ngIf="f.customerEmail.errors" class="invalid-feedback">
                  <div *ngIf="f.customerEmail.errors.required">Email is required</div>
                </div>
              </div>
              <hr />
              <div class="form-group" *ngIf="currentUser.paymentProfileId">
                <div>Current Card</div>
                <div class="btn btn-link float-right" (click)="createStripeElements()">Update Card Info</div>
                <div class="border p-md-2">
                  <i class="fab fa-lg fa-cc-{{cardTypeIcon}}"></i>&nbsp;{{cardType}}&nbsp;&nbsp;&nbsp;**** {{cardLast4}} expires on {{cardExpDate}}
                </div>
              </div>
              <div class="form-group" [hidden]="!card">
                <label for="card-info">New Card</label>
                
                <div id="card-info" #cardInfo></div>

                <div *ngIf="cardErrors" role="alert" class="stripe-error">
                  {{ cardErrors }}
                </div>
              </div>
            </div>
          </div>

        <div class="form-group">
        <button type="submit" class="btn btn-secondary btn-lg mr-1" [disabled]="!theForm.valid || cardErrors">Save</button>
        <button type="button" class="btn btn-primary btn-lg" (click)="goBack()">Cancel</button>
        <!--<button type="button" class="btn btn-link float-right" (click)="deleteProfile()" *ngIf="currentUser.paymentProfileId && !currentUser.subscriptionPlanId">Delete Profile</button>-->
        </div>
        </form>
      </div>
    </div>
  </div>
</div>

