<h1 mat-dialog-title>Invite New User</h1>

  <div mat-dialog-content>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Email Address</mat-label>
        <input type="email" class="form-control" id="emailaddress" [(ngModel)]="data.email">
      </mat-form-field>
      <div class="small" *ngIf="data.payment!=='free'">Your account will be charged additioanl $25.00 each month after invited user account is activated.</div>
    </div>
  </div>
  <div mat-dialog-actions class="float-right p-3">
    <button class="btn btn-primary" [mat-dialog-close]="data" cdkFocusInitial>Invite</button>
  </div>

