<div class="container mb-3">
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item">
      <a class="nav-link active" id="campaign-tab" data-toggle="tab" href="#campaign_edit" role="tab" aria-controls="azure"
         aria-selected="true"><h3>{{pageTitle}}</h3></a>
    </li>
    <li class="nav-item" *ngIf="message_id">
      <a class="nav-link" id="contacts-tab" data-toggle="tab" href="#contact_list" role="tab" aria-controls="amazon"
         aria-selected="false"><h3>Contacts</h3></a>
    </li>
  </ul>
  <div class="tab-content">
    <div class="tab-pane fade show active" id="campaign_edit" role="tabpanel" aria-labelledby="campaign-tab">
      <form [formGroup]="emailCampaignUploadForm" (ngSubmit)="onSubmit()">
        <div class="card square mb-3" style="border-top: none!important">
          <!--<div class="row no-gutters">
            <div class="col-md-12">-->
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="campaignTitle">Campaign Title</label>
                  <input type="text" class="form-control" [readonly]="isReadOnly || isFollowup" id="campaignTitle" formControlName="campaignTitle" required
                         [ngClass]="{ 'is-invalid':  (f.campaignTitle.dirty || f.campaignTitle.touched) && f.campaignTitle.errors }" autocomplete="off">
                  <div *ngIf="f.campaignTitle.errors" class="invalid-feedback">
                    <div *ngIf="f.campaignTitle.errors.required">Title is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="StartDate">Start Date</label>
                  <input type="date" class="form-control" [readonly]="isReadOnly" id="startDate" formControlName="startDate" [min]="formattedDate" required
                         [ngClass]="{ 'is-invalid':  (f.startDate.dirty || f.startDate.touched) && f.startDate.errors }">
                  <div *ngIf="f.startDate.errors" class="invalid-feedback">
                    <div *ngIf="f.startDate.errors.required">Start Date is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="startTime">Start Time</label>
                  <input type="time" class="form-control" [readonly]="isReadOnly" id="startTime" formControlName="startTime" required
                         [ngClass]="{ 'is-invalid':  (f.startTime.dirty || f.startTime.touched) && f.startTime.errors }">
                  <div *ngIf="f.startTime.errors" class="invalid-feedback">
                    <div *ngIf="f.startTime.errors.required">Start Time is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <div class="custom-control custom-switch mt-4 pt-3">
                    <input type="checkbox" class="custom-control-input" id="enableTracking" formControlName="enableTracking">
                    <label class="custom-control-label" for="enableTracking">Enable Tracking</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="emailSubject">Subject</label>
                  <input type="text" required class="form-control" [readonly]="isReadOnly || isFollowup" id="emailSubject" maxlength="250"
                         formControlName="emailSubject" placeholder="Type email subject here!"
                         [ngClass]="{ 'is-invalid':  (f.emailSubject.dirty || f.emailSubject.touched) && f.emailSubject.errors }" />
                  <div *ngIf="f.emailSubject.errors" class="invalid-feedback">
                    <div *ngIf="f.emailSubject.errors.required">Email Subject is required</div>
                  </div>
                </div>
              </div>
            </div>



            <label for="body">Body</label>
            <!--[config]="EditorConfig"-->
            <!--[(ngModel)]="email_template.body" [ngModelOptions]="{standalone: true}"-->
            <div class="border">
              <div id="toolbar-container"></div>
              <ckeditor [editor]="Editor"
                        (change)="onBodyChange($event)"
                        (ready)="onReady($event)"
                        [config]="EditorConfig"
                        [disabled]="isReadOnly"
                        [(ngModel)]="campaign.emailBody" [ngModelOptions]="{standalone: true}">

              </ckeditor>
            </div>

            <div class="row">
              <div class="col-md-5 mt-3">

                <label for="attachments" *ngIf="!isReadOnly">
                  Attachment(s)
                  <span *ngIf="totalAttachSize > 0" [ngClass]="{'text-danger': totalAttachSize > 10 }">
                      {{totalAttachSize | number:'.2' }}MB
                  </span>
                </label>
                <div class="custom-file" *ngIf="!isReadOnly">
                  <label class="custom-file-label" #labelAttach for="attachments"></label>
                  <input type="file" class="custom-file-input"
                         id="attachments"
                         multiple
                         (change)="onAttachFileChange($event.target.files)" />
                </div>
                <label class="small" *ngIf="!isReadOnly">Attachments cannot exceed 10 MB</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-chip-list>
                  <mat-chip *ngFor="let i of attachmentFiles" [selectable]=false
                            [removable]=true (removed)="removeAttachment(i)">
                    {{i.name}} ({{i.size/1024/1024 | number:'.2'}} MB)
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <mat-chip *ngFor="let i of attachmentSavedFiles" [selectable]=false
                            [removable]=!isReadOnly (removed)="removeSavedAttachment(i)">
                    {{i.name}} ({{i.size/1024/1024 | number:'.2'}} MB)
                    <mat-icon matChipRemove *ngIf="!isReadOnly">cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>

            <hr *ngIf="!isReadOnly && !isFollowup && !passedContactIds && !passedCompanyIds && !passedIndustryIds"/>

            <div class="row">
              <div class="col-md-5">
                <label for="file" *ngIf="!isReadOnly && !isFollowup && !passedContactIds && !passedCompanyIds && !passedIndustryIds">
                  Choose contacts
                </label>
                <div class="custom-file mb-3" *ngIf="!isReadOnly && !isFollowup && !passedContactIds && !passedCompanyIds && !passedIndustryIds">
                  <label class="custom-file-label" #labelImport for="file">Choose an Excel file with contacts</label>

                  <input type="file" class="custom-file-input"
                         accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                         id="file"
                         formControlName="file"
                         (change)="onFileChange($event.target.files)" />
                </div>

              </div>

            </div>

            <div class="border p-2" *ngIf="passedContactIds">Campaign has <b>{{passedContactIds[0].length}}</b> pre-selected contacts.</div>
            <div class="border p-2" *ngIf="passedCompanyIds">Campaign has <b>{{passedCompanyIds[0].length}}</b> pre-selected companies.</div>
            <div class="border p-2" *ngIf="passedIndustryIds">Campaign has <b>{{passedIndustryIds[0].length}}</b> pre-selected industry.</div>

            <div class="row">
              <div class="col-md-3">
                <div class="custom-control custom-switch align-bottom mt-3" *ngIf="!message_id">
                  <input type="checkbox" [(ngModel)]="includeProspects" [ngModelOptions]="{standalone: true}" class="custom-control-input" id="includeProspects">
                  <label class="custom-control-label mr-lg-5" for="includeProspects">Include Prospects</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="custom-control custom-switch align-bottom mt-3" *ngIf="!message_id">
                  <input type="checkbox" [(ngModel)]="excludeSocial" [ngModelOptions]="{standalone: true}" class="custom-control-input" id="excludeSocial">
                  <label class="custom-control-label mr-lg-5" for="excludeSocial">Exclude Social Connections</label>
                </div>
              </div>
            </div>
          </div>
            <!--</div>
          </div>-->

        </div>
        <button type="submit" *ngIf="!isReadOnly" class="btn btn-secondary btn-lg" [disabled]="!emailCampaignUploadForm.valid">{{ buttonText }}</button>
        <button type="button" *ngIf="isReadOnly" class="btn btn-primary btn-lg mr-1" (click)="followup()">Followup</button>

        <button type="button" class="btn btn-primary btn-lg mr-1" (click)="goBack()">Cancel</button>
        <button *ngIf="message_id && !isReadOnly && !isFollowup" type="button" class="btn btn-info btn-lg ml-1 mr-1" (click)="sendTestEmail()">Send Test Email</button>
      </form>
    </div>
    <div class="tab-pane fade" id="contact_list" role="tabpanel" aria-labelledby="contacts-tab">
      <div class="card square mb-3" style="border-top: none!important">
        <div class="mt-md-1">
          <ag-grid-angular style="width: auto; height: 500px;"
                           class="ag-theme-balham"
                           [rowData]="contacts"
                           [animateRows]="true"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="defaultColDef"
                           rowSelection="multiple"
                           (selectionChanged)="onSelectionChanged()"
                           (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
      <button *ngIf="message_id && isReadOnly" type="button" class="btn btn-info btn-lg ml-1 mr-1" (click)="resendFailed()">Resend Failed/Not Sent</button>

      <button *ngIf="message_id" [disabled]="selectedRows.length==0" type="button" class="btn btn-secondary btn-lg ml-1 mr-1" (click)="setSelectedAsSent()">Set Selected As</button>
      <select id="sentStatus" [(ngModel)]="setSentStatus" class="custom-select-lg align-bottom">
        <option value="0">Not Sent</option>
        <option value="1">Sent</option>
        <option value="2">Failed</option>
        <option value="3">Bounced</option>
        <option value="4">Unsubscribed</option>
        <option value="5">Responded</option>
      </select>

      <button *ngIf="message_id" [disabled]="selectedRows.length==0" type="button" class="btn btn-danger btn-lg ml-5 mr-1" (click)="deleteSelected()">Delete Selected</button>

    </div>
  </div>
</div>

