import { Component, Inject, ViewContainerRef, ChangeDetectorRef, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../../services/contact.service';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user';

@Component({
  selector: 'app-company-edit-dialog',
  templateUrl: './company-edit-dialog.component.html',
  styleUrls: ['./company-edit-dialog.component.scss']
})
export class CompanyEditDialogComponent implements OnInit {

  currentUser;
  company;

  constructor(
    public dialogRef: MatDialogRef<CompanyEditDialogComponent>,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    public view: ViewContainerRef,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private contactService: ContactService,
    private userService: UserService,

    @Inject(MAT_DIALOG_DATA) public company_id: number
  ) { }

  companyCategories;
  companyIndustries;
  companyRevenueRanges;
  companyEmployeeRanges;
  users: User[];
  formTitle = "Edit Company";

  urlValidatorRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  companyEditForm: FormGroup;

  formData = new FormData();

  onNoClick(): void {
    this.dialogRef.close();
  }
  onDelete(): void {
    this.dialogRef.close("delete");
  }
  ngOnInit() {

    this.authService.currentUser.subscribe(x => this.currentUser = x);

    this.contactService.getCategories().subscribe(categories => {
      this.companyCategories = categories
    });

    this.contactService.getIndustries().subscribe(ind => {
      this.companyIndustries = ind
    });

    this.contactService.getRevenueRanges().subscribe(rev => {
      this.companyRevenueRanges = rev
    });

    this.contactService.getEmployeeRanges().subscribe(emp => {
      this.companyEmployeeRanges = emp;
    });

    this.userService.getAll().subscribe(users => {

      this.users = users.filter((u) => u.status == 'Active');

    });

    this.companyEditForm = this.fb.group({
      id: this.company_id,
      name: ['', Validators.required],
      shortName: null,
      website: [null, Validators.pattern(this.urlValidatorRegex)],
      phone: null,
      streetaddress: null,
      city: null,
      state: null,
      zip: null,
      categoryId: null,
      industryId: null,
      employeeRangeId: null,
      revenueRangeId: null,
      assignedUserId: null,
      tags: null,
      facebook: [null, Validators.pattern(this.urlValidatorRegex)],
      twitter: [null, Validators.pattern(this.urlValidatorRegex)],
      linkedin: [null, Validators.pattern(this.urlValidatorRegex)]
    });

    if (this.company_id > 0) {
      this.contactService.getCompany(this.company_id)
        .subscribe(company => {
          this.company = company;

          console.log(this.company);

          this.companyEditForm.patchValue(this.company);
          //
        });
    } else {
      this.formTitle = "New Company";
    }


  }

  get f() { return this.companyEditForm.controls; }

  //onFileChange(files) {
  //  const reader = new FileReader();
  //  if (!files) {
  //    return;
  //  }
  //  if (files) {
  //    const excelFile = files[0];
  //    console.log(excelFile);

  //    this.formData.set('ExcelFile', excelFile);

  //    // need to run CD since file load runs outside of zone
  //    this.cd.markForCheck();
  //  }
  //}

  onSubmit() {

    if (this.companyEditForm.invalid) {
      return;
    }
    this.setFormData();

    //this.dialogRef.close();
    this.contactService.saveCompany(this.formData).subscribe(result => {

      console.log(result);
      this.dialogRef.close(result);

    });

  }

  setFormData() {
    let entries = Object.entries(this.companyEditForm.value);

    console.log(entries);

    this.formData.set("id", this.company_id.toString());


    for (let [key, value] of entries) {

      if (this.company_id > 0) {
        if (this.companyEditForm.value[key] != this.company[key]) {
          // allow to clear existing backend value by setting it to one space
          if (value === '') {
            value = ' ';
          }
          this.formData.set(key, String(value));
        }
      } else {

        if (value) {
          this.formData.set(key, String(value));
        }
        
      }

    }
  }
}
