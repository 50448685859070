import { Component, Input } from '@angular/core';
import { User } from './models/user';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { Role } from './models/role';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ContactService } from './services/contact.service';
import { ContactUploadDialogComponent } from './components/contact-upload-dialog/contact-upload-dialog.component';
import { MatDialog } from '@angular/material';
import { FlashMessagesService } from 'angular2-flash-messages';
import { SignalViewModel } from './models/signal-view-model';
import { SignalRService } from './services/signal-r.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  currentUser: User;
  currentCompany;
  public state$: Observable<object>;
  contactCounts;
  public signalRMessage: SignalViewModel;

  constructor(
    private router: Router,
    private flash: FlashMessagesService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private contactsService: ContactService,
    private signalRService: SignalRService

  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    this.authenticationService.currentCompany.subscribe(x => this.currentCompany = x);

    this.authenticationService.contactCounts.subscribe(x => this.contactCounts = x);

    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          var button = document.getElementById("toggle-button");
          if (button && !button.classList.contains('collapsed')) {
            document.getElementById("toggle-button").click();
          }
        }
      });
  }
  ngOnInit() {
    this.state$ = this.router.events
      .pipe(
        filter(e => e instanceof NavigationStart),
        map(() => this.router.getCurrentNavigation().extras.state)
    );

    this.signalRService.signalReceived.subscribe((signal: SignalViewModel) => {
      this.signalRMessage = signal;

      console.log("signalR", this.signalRMessage);

      if (this.signalRMessage.client == this.currentCompany.clientId &&
            this.signalRMessage.user == this.currentUser.id) {

        var msg;
        if (this.signalRMessage.campaign === undefined) {
          msg = `<b>${this.signalRMessage.contactsCount}</b> contacts out of <b>${this.signalRMessage.contactsTotal}</b> have been uploaded.
<br>Please, don't schedule new campaigns until all contacts have been uploaded and you receive email notification.
<br>You may click [Contacts] link to see uploaded records`;
        } else {
          msg = `<b>${this.signalRMessage.campaign}</b> campaign batch has been scheduled on <b>${this.signalRMessage.date}</b> with <b>${this.signalRMessage.contactsCount}</b> contacts.
<br>Please, click <b>[Campaigns]</b> link to see scheduled emails`;
        }


        this.flash.show(msg, { cssClass: 'alert-success', timeout: 10000 });
        //
        this.contactsService.getContactsCounts().subscribe(response => {
          this.authenticationService.setNewContactCounts(response);
        }
        );
      }

    });
  }

  toggleSidebar (): void {
    document.body.classList.toggle('no-scroll');
  }

  get isAdmin() {
    return this.currentUser &&
      (this.currentUser.roleId !== Role.User);
  }

  get haveCompanies() {
    return this.contactCounts && this.contactCounts.companies > 0;
  }
  get haveContacts() {
    return this.contactCounts && this.contactCounts.contacts > 0;
  }
  logout() {
    this.authenticationService.logout();
  }
  uploadContacts(): void {
    const dialogRef = this.dialog.open(ContactUploadDialogComponent, {
      width: '600px',
      data: {}
    });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      console.log(result);

      if (result) {
        //this.contactsService.getContactsCounts().subscribe(response => {
        //  this.authenticationService.setNewContactCounts(response);
        //
        if (result.contacts) {
          let msg = `Successfully started upload job with ${result.contacts} contacts<br>
You will receive email when job is complete.`;
          this.flash.show(msg, { cssClass: 'alert-success', timeout: 10000 });

          this.router.navigate(['/campaigns']);
        }
        //else if (result.contacts == 0) {
        //  this.flash.show(`No valid contact records found`, { cssClass: 'alert-danger', timeout: 3000 });
        //}

        //});
      }
    });

  }
}
