import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { EmailMessageService } from '../../services/email-message.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private emailMsgService: EmailMessageService,
    public dialog: MatDialog,
    public view: ViewContainerRef,
    private flash: FlashMessagesService,
  ) { }
  email_templates: any = [];
  currentUser;

  ngOnInit() {
    this.authService.currentUser.subscribe(x => this.currentUser = x);
    this.emailMsgService.getAll().subscribe(emails => {
      this.email_templates = emails;

      console.log(this.email_templates);
      console.log("currentUser", this.currentUser)
    });
  }

  deleteTemplate(mediaID) {


    let root_elm = this.view;
    console.log(root_elm);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {

        data: `Are you sure you want to delete this email template?`,
        viewContainerRef: root_elm
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.emailMsgService.delete(mediaID).subscribe(response => {
          this.flash.show('Successfully deleted template', { cssClass: 'alert-success', timeout: 5000 });
          this.email_templates.forEach((item, index) => {
            if (item.id == mediaID) {
              this.email_templates.splice(index, 1);
            }
          });
        })
      }
    });
  }

}
