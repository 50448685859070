import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private flash: FlashMessagesService,
    private route: ActivatedRoute,
    private router: Router
  ) { }
  contactForm: FormGroup;
  formData = new FormData();

  ngOnInit() {

    this.contactForm = this.fb.group({
      Phone: '',
      Firstname: ['', Validators.required],
      Lastname: ['', Validators.required],
      Email: ['', Validators.compose([Validators.required, Validators.email])],
      InquiryReason: ['0', Validators.required],
      InquiryMessage: ['', Validators.required]
    });
  }
  get f() { return this.contactForm.controls; }

  setFormData() {
    let entries = Object.entries(this.contactForm.value);
    for (let [key, value] of entries) {
      this.formData.set(key, String(value));
    }
  }

  onSubmit() {
    this.setFormData();

    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }

    console.log(this.formData);

    this.userService.contactus(this.formData)
      .subscribe(data => {
        window.scroll(0, 0);
        this.flash.show("Inquiry message was succesfully sent!", { cssClass: 'alert-success', timeout: 3000 })

        setTimeout(() => {
        this.router.navigate(['/']);
        }, 6000);

      });
  }

}
