import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PreviousURLService } from '../../../services/previous-url.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { DomSanitizer } from '@angular/platform-browser';
import * as DocumentEditor from '../../../../ckeditor5-build-classic-custom/build/ckeditor.js';
//import * as DocumentEditor from '@ckeditor/ckeditor5-build-classic';
//import * as DocumentEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { EmailMessageService } from '../../../services/email-message.service';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss']
})
export class EditTemplateComponent implements OnInit {

  constructor(
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private urlService: PreviousURLService,
    private flash: FlashMessagesService,
    private emailMsgService: EmailMessageService
  ) { }

  public Editor = DocumentEditor;
  public onReady(editor) {
    //editor.ui.getEditableElement().parentElement.insertBefore(
    //  editor.ui.view.toolbar.element,
    //  editor.ui.getEditableElement()
    //);
    //document.querySelector('#toolbar-container').appendChild(editor.ui.view.toolbar.element);
  }

  EditorConfig = {
    placeholder: 'Type email content here!',
  };
  pageTitle = "View Campaign Email";
  template_id: any;
  email_template: any = { subject: '', body: '' };
  editEmailForm: FormGroup;
  formData = new FormData();

  ngOnInit() {

    this.template_id = this.route.snapshot.paramMap.get('template_id');

    console.log("template_id",this.template_id);

    this.editEmailForm = this.fb.group({

      subject: [null, Validators.required],
      body: [null],
      sendto: [null]

    });

    if (this.template_id === null) {
      this.pageTitle = "New Campaign Email";
      this.email_template = { subject: '', body: '' }
      this.editEmailForm.patchValue(this.email_template);
    }
    else
    {
      this.emailMsgService.get(this.template_id).subscribe(result => {

        this.email_template = result;
        console.log(this.email_template);
        this.editEmailForm.patchValue(this.email_template);
      });
    }
    

  };

  get f() { return this.editEmailForm.controls; }

  public onBodyChange({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.formData.set("body", data);
  }
  sendTestEmail() {

    let email = this.editEmailForm.value.sendto;
    if (email !== '') {
      this.emailMsgService.send(this.template_id, email).subscribe(response => {
        this.flash.show(`Successfully send email to ${email}`, { cssClass: 'alert-success', timeout: 3000 });
      });
    }
  }

  onSubmit() {

    console.log(this.editEmailForm);
    console.log("body", this.formData.get("body"))
    let msg = this.formData.get("body");
    // stop here if form is invalid
    if (this.editEmailForm.invalid) {
      return;
    }
    //this.setFormData();
    this.formData.set("subject", this.editEmailForm.value.subject);

    if (this.template_id === null) {
      this.emailMsgService.create(this.formData).subscribe(response => {
        this.flash.show('Successfully created template', { cssClass: 'alert-success', timeout: 3000 });
        this.goBack();
      });
    }
    else {
      this.emailMsgService.update(this.template_id, this.formData).subscribe(response => {
        this.flash.show('Successfully updated template', { cssClass: 'alert-success', timeout: 3000 });
        this.goBack();
      });
    }
  }
  goBack() {
    this.router.navigate(['/campaigns']);
    // this.urlService.goToPreviousUrl();
  }
}
