import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ActivatedRoute } from '@angular/router';
import { PreviousURLService } from 'src/app/services/previous-url.service';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

@Component({
  selector: 'app-my-company',
  templateUrl: './my-company.component.html',
  styleUrls: ['./my-company.component.scss']
})
export class MyCompanyComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
    private fb: FormBuilder,
    private flash: FlashMessagesService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    public urlService: PreviousURLService
  ) { }
  currentUser;
  currentCompany;
  company;
  companyForm: FormGroup;
  states;
  formChanges;
  companyLogoFile: any;
  formData = new FormData();
  companyID;
  companyToken;
  companyCategories;
  urlValidatorRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  imageChangedEvent: any = '';
  isCropping: Boolean = false;

  //@ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  ngOnInit() {
    this.authService.currentUser.subscribe(x => this.currentUser = x);
    this.authService.currentCompany.subscribe(x => this.currentCompany = x);
    //this.userService.getListOfStates().subscribe(states => {
    //  this.states = states;
    //});
    //this.userService.getCampaignCategories().subscribe(categories => this.companyCategories = categories);
    console.log(this.currentUser);
    this.companyID = this.route.snapshot.paramMap.get('company_id') || this.currentUser.clientId;
    console.log(this.companyID);

    this.companyForm = this.fb.group({
      clientName: ['', Validators.required],
      //companyDescription: null,
      website: [null, Validators.pattern(this.urlValidatorRegex)],
      phone: null,
      streetaddress: null,
      city: null,
      state: null,
      zip: null,
      logoFile: null,
      //bannerFile: null,
      //categoryid: null,
      //brandColor: null,
      //facebookUrl: [null, Validators.pattern(this.urlValidatorRegex)],
      //twitterUrl: [null, Validators.pattern(this.urlValidatorRegex)],
      //instagramUrl: [null, Validators.pattern(this.urlValidatorRegex)]
    });
    this.userService.getClient(this.companyID)
      .subscribe(client => {
        console.log(client);
        this.company = client;
        this.currentCompany = this.company;
        this.companyLogoFile = this.company.clientLogo;

        //if (this.companyLogoFile === null) {
        //  //alert("set logo as required");
        //  this.companyForm.controls['logoFile'].setValidators([Validators.required]);
        //  this.companyForm.controls['logoFile'].updateValueAndValidity();
        //}
        // do this instead of calling pathForm()
        this.companyForm.patchValue(client);
        //
        //console.log("this.company", this.company);
        //this.userService.companyToken = this.company.companyGuid;

        //this.companyToken = this.userService.companyToken;
        //console.log("this.companyID", this.companyID);
        //console.log("this.companyToken", this.companyToken);
      });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.isCropping = true;
  }

  //cropImage() {
  //  this.imageCropper.crop();
  //}

  goBack() {
    this.urlService.goToPreviousUrl();
  }

  //imageCropped(event: ImageCroppedEvent) {
  //  //console.log(event);
  //  this.companyBannerFile = event.base64;
  //  this.formData.set('banner', event.file, this.imageChangedEvent.target.files[0].name);
  //  this.isCropping = false;
  //}
  imageLoaded() {
    // this.isCropping = true;
  }
  //cropperReady() {
  //  // cropper ready
  //}
  loadImageFailed() {
    // show message
  }
  setFormData() {
    let entries = Object.entries(this.companyForm.value);
    for (let [key, value] of entries) {
      if (this.companyForm.value[key] != this.company[key]) {
        // allow to clear existing backend value by setting it to one space
        if (value === '') {
          value = ' ';
        }
        this.formData.set(key, String(value));
      }
    }
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.companyForm.invalid) {
      return;
    }
    this.setFormData();

    this.userService.updateClient(this.formData).subscribe(response => {
      this.flash.show('Successfully edited company', { cssClass: 'alert-success', timeout: 3000 });

      console.log(response);
      console.log(this.currentUser.clientId, this.companyID, this.companyToken);
      // Updates the company object once the company is updated
      if (this.currentUser.clientId === this.companyID) {
        this.authService.setNewCompanyValue(response);
      }
      this.companyForm.markAsPristine();
    });
  }

  get f() { return this.companyForm.controls; }

  onFileChange(files, imageFileType): void {
    const reader = new FileReader();
    if (!files) {
      return;
    }
    const MultimediaFile = files[0];
    reader.readAsDataURL(MultimediaFile);
    reader.onload = () => {
      this[imageFileType] = reader.result;
    }
    // Checks to see which image file is changed in order to update the right image
    //if (imageFileType === 'companyBannerFile') {
    //  this.formData.set('banner', MultimediaFile);
    //} else {
      this.formData.set('logo', MultimediaFile);
    //}

    // need to run CD since file load runs outside of zone
    this.cd.markForCheck();
  }

  //removeBanner() {
  //  this.companyBannerFile = null;
  //  this.formData.set('banner', null);
  //  this.formData.set('RemoveBanner', 'true');
  //  this.companyForm.markAsDirty();
  //}
}
