<!-- loader.component.html -->
  <!--<div class="loading-screen-wrapper" *ngIf="loading">
    <div class="loading-screen-icon">
    Loading...
    </div>
  </div>-->
  <div class="overlay" *ngIf="loading">
    <mat-progress-spinner class="spinner"
                          [color]="color"
                          [mode]="mode"
                          [value]="value">
    </mat-progress-spinner>
  </div>
