<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<mat-dialog-title>
  <h2 class="ml-3">Company Notes</h2>
</mat-dialog-title>
<mat-dialog-content>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <textarea placeholder="Enter your comments here" class="form-control" rows="8" [(ngModel)]="data.notes"></textarea>
        </div>
      </div>
    </div>
  </div>

</mat-dialog-content>

<div mat-dialog-actions class="p-4">
  <!--<button class="btn btn-primary mr-4" [mat-dialog-close]="data" cdkFocusInitial>Submit</button>-->
  <div class="form-group pt-3 pb-3">
    <button type="submit" class="btn btn-secondary btn-lg" [mat-dialog-close]="data">Save</button>
    &nbsp;
    <button class="btn btn-primary btn-lg" (click)="onNoClick()" cdkFocusInitial>Cancel</button>
  </div>
</div>
