<div class="container-fluid mb-2">
  <div class="row">
    <div class="col-md-12">
      <button type="button" *ngIf="filterByCompanyByExactName" class="btn btn-secondary float-right" (click)="newContact()">New Contact</button>

      <h2 class="mb-2">Contacts</h2>

    </div>
  </div>
  <fieldset class="border mb-2 rounded">
    <!--<legend class="w-auto pl-2 pr-2">Filter </legend>-->
    <div class="row pb-2 pl-2">

      <div class="col-md-2">Email<br /><input class="form-control" type="search" [(ngModel)]="filterByEmail" name="email" (change)="onFilterChange($event)" /></div>
      <div class="col-md-2">Company<br /><input class="form-control" type="search" [(ngModel)]="filterByCompany" name="companyName" (change)="onFilterChange($event)" /></div>
      <div class="col-md-2">Industry<br /><input class="form-control" type="search" [(ngModel)]="filterByIndustry" name="companyIndustry" (change)="onFilterChange($event)" /></div>
      <div class="col-md-2">
        Status<br />
        <select id="sentStatus" [(ngModel)]="filterByStatus" (change)="onStatusFilterChange()" class="form-control">
          <option value='-1'>All</option>
          <option value='1'>Active</option>
          <option value='6'>On Hold</option>
          <option value='5'>Responded</option>
          <option value='4'>Unsubscribed</option>
          <option value='3'>Bounced</option>
        </select>
      </div>
      <div class="col-md-2 pt-2" *ngIf="currentUser.roleId < 3">
        <br />
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="companyAssignedUserId" name="companyAssignedUserId" (change)="onFilterChange($event)" [(ngModel)]="showMyCompanies">
          <label class="custom-control-label" for="companyAssignedUserId">My Companies</label>
        </div>
      </div>
      <div class="col-md-2">
        <br />
        <button class="btn btn-link" *ngIf="allFilters" (click)="removeFilters()">Remove Filters</button>
      </div>
    </div>
    <!--<div class="row">
      <div class="col-md-12 ml-2">
        {{allFilters}}
      </div>
    </div>-->
  </fieldset>
  <!--form [formGroup]="contactUploadForm" (ngSubmit)="onSubmit()">-->
  <div class="mb-3">
    <!--<div class="row no-gutters">
    <div class="col-md-6">
      <div class="card-body">

        <div class="custom-file mb-3">
          <label for="file">Upload excel file with contacts</label>
          <input type="file" class="form-control-file"
                 accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                 id="file"
                 formControlName="file"
                 (change)="onFileChange($event.target.files)" />

        </div>


      </div>
    </div>
    <div class="col-md-6 p-4">

      <button type="submit" class="btn btn-secondary btn-lg float-right" [disabled]="!f.file.value">Submit</button>

    </div>
  </div>-->
    <!--
    [rowData]="contacts"
    [getRowNodeId]="getRowNodeId"
    -->
    <ag-grid-angular style="width: auto; height: 400px"
                     class="ag-theme-balham"
                     [getRowNodeId]="getRowNodeId"
                     [animateRows]="true"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="defaultColDef"
                     rowSelection="multiple"
                     [pagination]="true"
                     [paginationPageSize]="50"
                     (selectionChanged)="onSelectionChanged()"
                     (cellClicked)="onCellClicked($event)"
                     (gridReady)="onGridReady($event)"
                     (filterChanged)="onFilterModified($event)"
                     (firstDataRendered)="applyAllFilters()"
                     >
    </ag-grid-angular>

  </div>
  <!--<button type="button" class="btn btn-outline-primary btn-lg ml-1 mr-1" (click)="uploadContacts()">Upload Contacts</button>-->
  <button type="button" class="btn btn-outline-secondary btn-lg ml-1 mr-1" (click)="downloadContacts()">Download Contacts</button>

  <button type="button" class="btn btn-link ml-1 mr-1" (click)="createNewCampaign()">New Campaign</button>

  <select id="sentStatus" [(ngModel)]="setContactStatus" class="custom-select-lg align-bottom float-right">
    <option value="1">Active</option>
    <option value="6">On Hold</option>
    <option value="5">Responded</option>
    <option value="4">Unsubscribed</option>
    <option value="3">Bounced</option>
  </select>
  <button [disabled]="selectedRows.length==0" type="button" class="btn btn-secondary btn-lg ml-1 mr-1 float-right" (click)="updateContactsStatus()">Set Selected As</button>

  <!--</form>-->


</div>
