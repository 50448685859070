import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private flash: FlashMessagesService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        console.log("error interceptor", err);
        if ([401, 403].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          this.authenticationService.logout();
          //location.reload(true);
        }
        var msg = err.error.message || err.error.Message || err.message;
        if (err.error.errors && err.error.errors.length > 1) {
          msg += ": " + err.error.errors.join(" | ");
        }
        //
        window.scroll(0, 0);
        this.flash.show(msg, { cssClass: 'alert-danger', timeout: 10000 })

        //const error = err.error || err.status;
        return throwError(msg);
      }))
  }
}
