
<!-- main app container -->

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-6 offset-sm-3 col-lg-4 offset-lg-4">
            <div class="jumbotron mt-5">
                <h2>Login</h2>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="username">Email</label>
                        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">Email is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                        <a [routerLink]="[ '/forgotpassword']">Forgot Password?</a>
                    </div>
                    <div class="form-group">
                        <button [disabled]="loading" class="btn btn-primary">Login</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
