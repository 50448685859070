import { ChangeDetectorRef, Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthenticationService } from '../../../services/authentication.service';
import { ContactService } from '../../../services/contact.service';
import { PreviousURLService } from '../../../services/previous-url.service';
import { UserService } from '../../../services/user.service';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { ContactEditDialogComponent } from '../../contact/contact-edit-dialog/contact-edit-dialog.component';
import { CompanyEditDialogComponent } from '../company-edit-dialog/company-edit-dialog.component';
import { CompanyNotesDialogComponent } from '../company-notes-dialog/company-notes-dialog.component';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {

  currentUser;
  company;
  company_id;
  companySavedFiles: any[] = [];
  companyFiles: File[] = [];

  constructor(
    private authService: AuthenticationService,
    public dialog: MatDialog,
    public view: ViewContainerRef,
    private contactService: ContactService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private urlService: PreviousURLService,
    private flash: FlashMessagesService,
    private prevUrl: PreviousURLService,
    private cd: ChangeDetectorRef,

  ) { }

  ngOnInit() {

    this.company_id = this.route.snapshot.paramMap.get('company_id');

    this.authService.currentUser.subscribe(x => this.currentUser = x);

    this.getCompany();

  }

  private getCompany() {

    this.companySavedFiles.length = 0;

    this.contactService.getCompany(this.company_id)
      .subscribe(company => {
        this.company = company;

        console.log(this.company);

        if (this.company.companyFile) {
          this.company.companyFile.forEach((item, index) => {
            //const fname = item.fileUrl.split("/").pop();
  
            //var file = { 'name': fname, 'id': item.id, 'title': item.fileName, '' };

            this.companySavedFiles.push(item);
          });

        }
      });
  }

  goBack() {
    this.router.navigate(["companies"]);
  }

  editContact(contact_id:number, company_name: string ) {

    const dialogRef = this.dialog.open(ContactEditDialogComponent, {
      width: '850px',
      disableClose: false,
      panelClass: 'custom-modalbox',
      data: {
        companyname: company_name,
        companyid: this.company_id,
        id: contact_id
      }
    });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        this.getCompany();
      }
    });
  }
  editCompany(company_id: number) {

    const dialogRef = this.dialog.open(CompanyEditDialogComponent, {
      width: '850px',
      disableClose: false,
      panelClass: 'custom-modalbox',
      data: company_id
    });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        if (result === "delete") {

          let root_elm = this.view;
          console.log(root_elm);
          const dialogDeleteRef = this.dialog.open(ConfirmationDialogComponent,
            {

              data: `Are you sure you want to delete <b>${this.company.name}</b> company?`,
              viewContainerRef: root_elm
            });

          dialogDeleteRef.afterClosed().subscribe(result => {
            if (result) {
              // TODO: implement company delete here ---
              this.contactService.deleteCompany(company_id).subscribe(x => {

                this.flash.show(`Successfully deleted <b>${this.company.name}</b> company`, { cssClass: 'alert-success', timeout: 3000 });

                this.goBack();

              });
            }
          });

        } else {

          this.getCompany();
        }
        
      }
    });
  }
  deleteCompanyNote(note_id: number) {

    this.contactService.deleteCompanyNote(this.company_id, note_id).subscribe(() => {
      this.getCompany();
    });
  }
  createCompanyNote(note_id: number = 0, note: string = '') {

    const dialogRef = this.dialog.open(CompanyNotesDialogComponent, {
      width: '800px',
      disableClose: false,
      data:
      {
        note_id: note_id,
        notes: note,
      }
    });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.afterClosed().subscribe(r => {
      if (r) {

        console.log(r);
        let formData = new FormData();
        formData.set('notes', r.notes);

        this.contactService.updateCompanyNotes(this.company_id, note_id, formData).subscribe(att => {
          this.getCompany();
        });


      }
    });
  }
  createNewCampaign(contact = null) {

    console.log(contact);
    let msg = `Are you sure you want to create new campaign for <b>${this.company.name}</b> company?`;
    if (contact) {
      msg = `Are you sure you want to create new campaign for <b>${contact.firstName} ${contact.lastName}&lt;${contact.email}&gt;</b>?`;
    }
    let root_elm = this.view;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {

        data: msg,
        viewContainerRef: root_elm,
      });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log(rows);
        if (contact) {
          this.router.navigate(['/', 'campaign', 'message'], { state: { data: { contactids: [[parseInt(contact.id)]] } } });

        } else {
          this.router.navigate(['/', 'campaign', 'message'], { state: { data: { companyids: [[parseInt(this.company_id)]] } } });
        }
      }
    });

  }

  onFileChange(files) {
    if (!files) {
      return;
    }
    if (files) {

      console.log(files)
      const formData = new FormData();
      this.companyFiles.length = 0;

      for (let i = 0; i < files.length; i++) {
        this.companyFiles.push(<File>files[i]);
        formData.append('files', this.companyFiles[i], this.companyFiles[i].name);
      }
      
      // need to run CD since file load runs outside of zone
      this.cd.markForCheck();

      this.contactService.updateCompanyFiles(this.company_id, formData).subscribe(() => {
        this.getCompany();
      });

    }
  }
  removeAttachment(attach) {
    const index = this.companyFiles.indexOf(attach);
    if (index >= 0) {
      this.companyFiles.splice(index, 1);
    }

  }

  removeSavedFile(file) {

    let root_elm = this.view;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {

        data: `Are you sure you want to delete the file "${file.fileName}"?`,
        viewContainerRef: root_elm,
      });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // -----
        const index = this.companySavedFiles.indexOf(file);
        if (index >= 0) {
          console.log(file);
          // ---  call API to delete attachment --
          this.contactService.deleteCompanyFile(this.company_id, file.id)
            .subscribe(() => {

              this.flash.show(`The file ${file.fileName} has been deleted.`, { cssClass: 'alert-success', timeout: 3000 });

              this.companySavedFiles.splice(index, 1);

            });
          // ----------------------------------
        }
      }
    });


  }
}
