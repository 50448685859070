<h1 mat-dialog-title>Send Test Email</h1>
<div mat-dialog-content>
  <div class="form-group" *ngIf="data.firstname!==undefined">
    <mat-form-field>
      <mat-label>First Name</mat-label>
      <input type="text" class="form-control" id="firstname" [(ngModel)]="data.firstname">
    </mat-form-field>
  </div>
  <div class="form-group" *ngIf="data.lastname!==undefined">
    <mat-form-field>
      <mat-label>Last Name</mat-label>
      <input type="text" class="form-control" id="lastname" [(ngModel)]="data.lastname">
    </mat-form-field>
  </div>
  <div class="form-group" *ngIf="data.companyname!==undefined">
    <mat-form-field>
      <mat-label>Company Name</mat-label>
      <input type="text" class="form-control" id="companyname" [(ngModel)]="data.companyname">
    </mat-form-field>
  </div>
  <div class="form-group">
    <mat-form-field>
      <mat-label>Email Address</mat-label>
      <input type="text" class="form-control" id="emailaddress" [(ngModel)]="data.email">
    </mat-form-field>
  </div>
  <div class="form-group" *ngIf="data.subject!==undefined">
    <mat-form-field>
      <mat-label>Email Subject</mat-label>
      <input type="text" class="form-control" id="emailsubject" [(ngModel)]="data.subject">
    </mat-form-field>
  </div>
  <div class="form-group" *ngIf="data.message!==undefined">
    <mat-form-field>
      <mat-label>Email Message</mat-label>
      <textarea class="form-control" id="emailmessage" [(ngModel)]="data.message">

      </textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions class="float-right p-3">
  <button class="btn btn-primary" [mat-dialog-close]="data" cdkFocusInitial>Send</button>
</div>
