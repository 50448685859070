import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { PreviousURLService } from 'src/app/services/previous-url.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private userAuth: AuthenticationService,
    private flash: FlashMessagesService,
    public urlService: PreviousURLService
  ) { }

  theForm: FormGroup;
  loading = false;
  submitted = false;
  resetToken: string;
  error = '';
  userid: number;
  user: User;
  currentUser: User;
  validationToken: string;
  formData = new FormData();

  ngOnInit() {
    // --- kill user session ---
    this.userAuth.logout(false);

    this.validationToken = this.route.snapshot.queryParams['token'];

    this.theForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      officePhone: null,
      mobilePhone: null,
      password: '',
      passwordConfirm: ''
    });

    ////this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //this.currentUser = this.userAuth.currentUserValue;

    ////TODO: consider that userid can be passed via url parameter
    //this.userid = this.currentUser.id;

    this.loaduser();

  }
  // convenience getter for easy access to form fields
  get f() { return this.theForm.controls; }

  loaduser(): void {
    this.userService.getByToken(this.validationToken).subscribe(u => {
      this.user = u;
      console.log(this.user);
      this.theForm.patchValue(this.user);
      this.theForm.patchValue({ password: '', passwordConfirm: '' });
    });
  }
  setFormData() {
    let entries = Object.entries(this.theForm.value);
    for (let [key, value] of entries) {
      if (this.theForm.value[key] != this.user[key]) {
        this.formData.set(key, String(value));
      }
    }
    // The ID doesn't change so we have to append it manually
    // --AV-- ID is part of URL don't need it here
    //this.formData.append('id', this.theForm.value.id);
    this.formData.set("email", this.user.email);

  }
  goBack() {
    this.urlService.goToPreviousUrl();
  }
  onSubmit() {
    // stop here if form is invalid
    if (this.theForm.invalid) {
      return;
    }

    this.setFormData();

    this.userService.activate(this.formData)
      .subscribe(
        result => {
          this.flash.show('Successfully acivated new user',
            { cssClass: 'alert-success', timeout: 3000 })

          this.router.navigate(['/login']);
          
        }
      );
  }

}
