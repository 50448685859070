import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }
  url = environment.apiUrl;

  analytics(start, end, industry, userid) {
    let params = new HttpParams()
      .set("start", start)
      .set("end", end)
      .set("industryid", industry)
      .set("userid", userid);
    return this.http.get(`${this.url}/CRM/Analytics`, { params: params });
  }
  getContactsCounts() {
    return this.http.get(`${this.url}/CRM/Contacts/Count`)
  }
  getAllContacts(status: number = -1) {
    let params = new HttpParams().set("status", status.toString());
    //return this.http.get(`${this.url}/CRM/Campaigns/Messages`, { params: params });
    return this.http.get(`${this.url}/CRM/Contacts`, { params: params });
  }
  getAllCompanies(status: number = -1) {
    let params = new HttpParams().set("status", status.toString());
    //return this.http.get(`${this.url}/CRM/Campaigns/Messages`, { params: params });
    return this.http.get(`${this.url}/CRM/Companies`, { params: params });
  }

  getCompany(id: number) {
    return this.http.get(`${this.url}/CRM/Companies/${id}`);
  }
  getContact(id: number) {
    return this.http.get(`${this.url}/CRM/Contacts/${id}`);
  }
  deleteCompany(id: number) {
    return this.http.delete(`${this.url}/CRM/Companies/${id}`);
  }
  deleteContact(id: number) {
    return this.http.delete(`${this.url}/CRM/Contacts/${id}`);
  }
  getCategories() {
    return this.http.get(`${this.url}/CRM/Company/Categories`);
  }
  getIndustries() {
    return this.http.get(`${this.url}/CRM/Company/Industries`);
  }
  getIndustryStats(sentEmails: boolean = false) {
    if (sentEmails) {
      return this.http.get(`${this.url}/CRM/Company/Industries/Stats?sentEmails=true`);
    } else {
      return this.http.get(`${this.url}/CRM/Company/Industries/Stats`);
    }
  }
  getRevenueRanges() {
    return this.http.get(`${this.url}/CRM/Company/RevenueRanges`);
  }
  getEmployeeRanges() {
    return this.http.get(`${this.url}/CRM/Company/EmployeeRanges`);
  }
  getTitles() {
    return this.http.get(`${this.url}/CRM/Contact/Titles`);
  }
  saveCompany(company) {
    return this.http.post(`${this.url}/CRM/Company`, company);
  }
  updateCompanyNotes(company_id, note_id, notes) {
    return this.http.post(`${this.url}/CRM/Companies/${company_id}/Notes/${note_id}`, notes);
  }
  deleteCompanyNote(company_id, note_id) {
    return this.http.delete(`${this.url}/CRM/Companies/${company_id}/Notes/${note_id}`);
  }
  deleteCompanyFile(company_id, file_id) {
    return this.http.delete(`${this.url}/CRM/Companies/${company_id}/Files/${file_id}`);
  }
  updateCompanyFiles(company_id, files) {
    return this.http.post(`${this.url}/CRM/Companies/${company_id}/Files/`, files);
  }
  saveContact(contact) {
    return this.http.post(`${this.url}/CRM/Contact`, contact);
  }
  uploadContacts(f) {
    return this.http.post(`${this.url}/CRM/UploadCompanyContacts`, f);
  }
  updateContactsStatus(status_id: number, ids: number[]) {
    return this.http.post(`${this.url}/CRM/Contacts/Status/${status_id}`, ids);
  }
  assignCompanyUser(user_id: number, ids: number[]) {
    return this.http.post(`${this.url}/CRM/Company/User/${user_id}`, ids);
  }
}
