import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-company-notes-dialog',
  templateUrl: './company-notes-dialog.component.html',
  styleUrls: ['./company-notes-dialog.component.scss']
})
export class CompanyNotesDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CompanyNotesDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }


  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
