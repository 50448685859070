import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) { }

  theForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  sucessMessage: string;

  ngOnInit() {
    this.theForm = this.formBuilder.group({
      email: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.sucessMessage = '';
    this.error = '';
  }
  // convenience getter for easy access to form fields
  get f() { return this.theForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.error = '';

    // stop here if form is invalid
    if (this.theForm.invalid) {
      return;
    }

    this.loading = true;
    this.userService.forgotPassword(this.f.email.value)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.sucessMessage = `Email was sent to ${this.f.email.value}. Please, follow instructions to reset your password.`;
          //this.router.navigate([this.returnUrl]);
          this.loading = false;
        },
        error => {
          this.error = error;
          this.sucessMessage = '';
          this.loading = false;
        });
  }
}
