
<section>
  <div>
    <img style="width:100%;" src="../../../assets/img/people-in-meeting.jpg" />
  </div>
</section>

<section>
  <div class="contact">
    <div class="container text-center text-white">
      <h2 class="display-5">Try InvontoCRM Today</h2>
      <p class="lead mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      <a routerLink="/contactus" class="btn btn-cta btn-lg btn-primary mt-5">Contact Us</a>
    </div>
  </div>
</section>
