import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private currentCompanySubject: BehaviorSubject<any>;
  public currentCompany: Observable<any>;

  private contactCountsSubject: BehaviorSubject<any>;
  public contactCounts: Observable<any>;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    // User observable
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    // Company observable
    this.currentCompanySubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentCompany')));
    this.currentCompany = this.currentCompanySubject.asObservable();

    this.contactCountsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUserCounts')));
    this.contactCounts = this.contactCountsSubject.asObservable();
  }

  public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }

  setNewUserValue(user) {
    this.currentUserSubject.next(user);
    localStorage.setItem('currentUser', JSON.stringify(user));
  }
  setNewCompanyValue(company) {
    this.currentCompanySubject.next(company);
    localStorage.setItem('currentCompany', JSON.stringify(company));
  }

  setNewContactCounts(counts) {
    this.contactCountsSubject.next(counts);
    localStorage.setItem('currentUserCounts', JSON.stringify(counts));
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/users/login`, { email, password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.jwt) {

          console.log(user);

          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('currentCompany', JSON.stringify(this.getCompanyFromUser(user)));
          this.currentUserSubject.next(user);
          this.currentCompanySubject.next(this.getCompanyFromUser(user));

          //
          this.http.get<any>(`${environment.apiUrl}/CRM/Contacts/Count`).subscribe(counts => {
            //console.log("counts", counts);
            localStorage.setItem('currentUserCounts', JSON.stringify(counts));
            this.contactCountsSubject.next(counts);
          }

          );
        }

        return user;
      }));
  }

  logout(redirect2login: boolean = true) {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentCompany');
    localStorage.removeItem('currentUserCounts');

    this.currentUserSubject.next(null);
    this.currentCompanySubject.next(null);
    this.contactCountsSubject.next(null);
    if (redirect2login) {
      this.router.navigate(['/login']);
    }
  }
  getCompanyFromUser(user) {
    console.log(user);
    var c = {
      clientGuid: user.clientGuid,
      clientLogo: user.clientLogo,
      clientId: user.clientId,
      clientName: user.clientName
    }
    return c;
  }
}
