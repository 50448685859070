import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor, LoaderInterceptor } from './helpers';

import { UserComponent } from './components/user/user.component';
import { MyProfileComponent } from './components/user/my-profile/my-profile.component';

import { LandingComponent } from './components/landing/landing.component';

import { FlashMessagesModule } from 'angular2-flash-messages';

import { ForgotPasswordComponent } from './components/user/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/user/reset-password/reset-password.component';

import { VerifyEmailComponent } from './components/user/verify-email/verify-email.component';

import { ChartsModule } from 'ng2-charts';
import { AgmCoreModule } from '@agm/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderService } from './services/loader.service';
import { MatProgressSpinnerModule, MatDialogModule, MatButtonModule, MAT_DIALOG_DEFAULT_OPTIONS, MatIconModule, MatChipsModule } from '@angular/material';

//import '@google/model-viewer';

import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { EmailTemplateComponent } from './components/email-template/email-template.component';
import { EditTemplateComponent } from './components/email-template/edit-template/edit-template.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EmailCampaignUploadComponent } from './components/email-campaign-upload/email-campaign-upload.component';
import { CampaignMessageComponent } from './components/campaign-message/campaign-message.component';
import { EditCampaignMessageComponent } from './components/campaign-message/edit-campaign-message/edit-campaign-message.component';
import { AgGridModule } from 'ag-grid-angular';
import { CellCallbackRendererComponent } from './components/campaign-message/cell-callback-renderer/cell-callback-renderer.component';
import { EmailSendTestDialogComponent } from './components/email-send-test-dialog/email-send-test-dialog.component';
import { EmailSettingsComponent } from './components/user/email-settings/email-settings.component';
import { CampaignCalendarComponent } from './components/campaign-calendar/campaign-calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ContactComponent } from './components/contact/contact.component';
import { ContactUploadDialogComponent } from './components/contact-upload-dialog/contact-upload-dialog.component';
import { SignupComponent } from './components/signup/signup.component';
import { MyCompanyComponent } from './components/user/my-company/my-company.component';
import { CompanyComponent } from './components/company/company.component';
import { CompanyEditDialogComponent } from './components/company/company-edit-dialog/company-edit-dialog.component';
import { ContactEditDialogComponent } from './components/contact/contact-edit-dialog/contact-edit-dialog.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { IndustryComponent } from './components/industry/industry.component';
import { InviteDialogComponent } from './components/user/invite-dialog/invite-dialog.component';
import { ActivateComponent } from './components/user/activate/activate.component';

import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { PaymentProfileComponent } from './components/subscriptions/payment-profile/payment-profile.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { CompanyDetailsComponent } from './components/company/company-details/company-details.component';
import { CompanyNotesDialogComponent } from './components/company/company-notes-dialog/company-notes-dialog.component';
import { ContactNotesDialigComponent } from './components/contact/contact-notes-dialig/contact-notes-dialig.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    UserComponent,
    MyProfileComponent,
    LandingComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    LoaderComponent,

    ConfirmationDialogComponent,

    EmailTemplateComponent,

    EditTemplateComponent,

    EmailCampaignUploadComponent,

    CampaignMessageComponent,

    EditCampaignMessageComponent,

    CellCallbackRendererComponent,

    EmailSendTestDialogComponent,

    EmailSettingsComponent,

    CampaignCalendarComponent,

    ContactComponent,

    ContactUploadDialogComponent,

    SignupComponent,

    MyCompanyComponent,

    CompanyComponent,

    CompanyEditDialogComponent,

    ContactEditDialogComponent,

    AnalyticsComponent,

    IndustryComponent,

    InviteDialogComponent,

    ActivateComponent,

    SubscriptionsComponent,
    PaymentProfileComponent,

    ContactUsComponent,

    CompanyDetailsComponent,

    CompanyNotesDialogComponent,

    ContactNotesDialigComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    ImageCropperModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    FlashMessagesModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCiclI0UC00rZ_V6iHeUy6IaCY3nGzGs4k',
      libraries:['places','drawing','geometry']
    }),
    CKEditorModule,
    AgGridModule.withComponents([CellCallbackRendererComponent]),
    FullCalendarModule 
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    EmailSendTestDialogComponent,
    ContactUploadDialogComponent,
    CompanyEditDialogComponent,
    ContactEditDialogComponent,
    InviteDialogComponent,
    CompanyNotesDialogComponent,
    ContactNotesDialigComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, closeOnNavigation: true, disableClose: true } }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
