import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
declare var Stripe;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, AfterViewInit, OnDestroy {

  // --- Stripe ---
  @ViewChild('cardInfo') cardInfo: ElementRef;
  card: any;
  cardHandler = this.onChange.bind(this);
  cardErrors: string = " ";
  stripe: any;
  //---------------

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private flash: FlashMessagesService,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,

  ) { }

  registerForm: FormGroup;
  formData = new FormData();
  needSubscription: boolean = true;

  ngOnInit() {

    this.registerForm = this.fb.group({
      ClientName: ['', Validators.required],
      Website: '',
      Phone: '',
      Streetaddress: '',
      City: '',
      State: '',
      Zip: '',
      UserFirstName: ['', Validators.required],
      UserLastName: ['', Validators.required],
      UserEmail: ['', Validators.required],
      UserOfficePhone: '',
      UserMobilePhone: '',
      UserPassword: ['', Validators.required],
      UserPasswordConfirm: ['', Validators.required],
      CardToken: null
    });
  }

  ngAfterViewInit(): void {
    //throw new Error('Method not implemented.');

    this.userService.needSubscription().subscribe(data => {

      this.needSubscription = <boolean>data;

      console.log(this.needSubscription);

      if (this.needSubscription) {
        this.createStripeElements();
      } else {

        this.cardErrors = null;
      }
    });
  }

  ngOnDestroy(): void {
    //alert('onDestroy');
    if (this.card) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }
  onChange({ error }) {
    console.log("card errors", error);
    if (error) {
      this.cardErrors = error.message;
    } else {
      this.cardErrors = null;
    }
    this.cd.detectChanges();
  }

  public createStripeElements() {
    this.stripe = Stripe(environment.stripePublicKey);
    const elements = this.stripe.elements();
    var style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: '18px',
        color: "#32325d",
      }
    };
    this.card = elements.create('card', {
      hidePostalCode: true,
      style: style
    });
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  get f() { return this.registerForm.controls; }

  setFormData() {
    let entries = Object.entries(this.registerForm.value);
    for (let [key, value] of entries) {
      this.formData.set(key, String(value));
    }
  }

  async onSubmit() {

    this.setFormData();
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    if (this.card) {
      const { token, error } = await this.stripe.createToken(this.card);
      if (error) {
        console.log('Something is wrong:', error);
        return;
      } else {
        console.log('Success!', token);
        //
        this.formData.set("CardToken", token.id);
        //this.registerForm.patchValue({
        //  CardToken: token.id,
        //})
      }
    }

    console.log(this.formData);

    this.userService.signup(this.formData)
      .subscribe(data => {
        var response: any = data;

        console.log(data);

        //setTimeout(() => {
        // --- pass email as state.data ---
        this.router.navigate(['/verifyemail'], {
          state: {
            data: {
              user_email: response.user_email,
              support_email: response.support_email
              }
          }
        });

        //this.router.navigate(['/verifyemail'], { queryParams: { email: this.f.UserEmail.value } });
        //}, 1000);

      });
  }
}
