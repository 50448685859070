import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailMessageService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  url = environment.apiUrl;

  getAll() {
    return this.http.get(`${this.url}/CRM/EmailTemplates`);
  }
  get(id) {
    return this.http.get(`${this.url}/CRM/EmailTemplate/${id}`);
  }
  send(id, contact) {
    //let params = new HttpParams().set("to", email);
    return this.http.post(`${this.url}/CRM/EmailTemplate/${id}/Send`, contact);
  }
  create(email_template) {
    return this.http.post(`${this.url}/CRM/EmailTemplate`, email_template);
  }
  update(id, email_template) {
    return this.http.put(`${this.url}/CRM/EmailTemplate/${id}`, email_template);
  }
  delete(id) {
    return this.http.delete(`${this.url}/CRM/EmailTemplate/${id}`);
  }

  uploadCampaignContacts(newCampaign) {
    return this.http.post(`${this.url}/CRM/UploadExcel`, newCampaign)
  }
}
