import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { PreviousURLService } from 'src/app/services/previous-url.service';
import { UserService } from 'src/app/services/user.service';
import { LoaderService } from '../../../services/loader.service';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { environment } from '../../../../environments/environment';
declare var Stripe;

@Component({
    selector: 'app-payment-profile',
    templateUrl: './payment-profile.component.html',
    styleUrls: ['./payment-profile.component.scss']
})
export class PaymentProfileComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('cardInfo') cardInfo: ElementRef;
    card: any;
    cardHandler = this.onChange.bind(this);
    cardErrors: string;
    stripe: any;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        private paymentsService: PaymentsService,
        private userService: UserService,
        private userAuth: AuthenticationService,
        private flash: FlashMessagesService,
        public urlService: PreviousURLService,
        private loaderService: LoaderService,
        public dialog: MatDialog,
        private cd: ChangeDetectorRef,
    ) { }

    theForm: FormGroup;
    loading = false;
    submitted = false;
    currentUser: User;
    paymentProfile;
    cardType: string;
    cardTypeIcon: string;
    cardLast4: number;
    cardExpDate;
    //formData = new FormData();

    public plan2subscribe: any;

    ngAfterViewInit(): void {
        //alert('onAfterViewInit');
        //throw new Error("Method not implemented.");
        if (!this.currentUser.paymentProfileId) {
            this.createStripeElements();
        }
    }
    public createStripeElements() {
        this.stripe = Stripe(environment.stripePublicKey);
        const elements = this.stripe.elements();
        var style = {
            base: {
                // Add your base input styles here. For example:
                fontSize: '18px',
                color: "#32325d",
            }
        };
        this.card = elements.create('card', {
            hidePostalCode: true,
            style: style
        });
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
    }

    ngOnDestroy(): void {
        //alert('onDestroy');
        if (this.card) {
            this.card.removeEventListener('change', this.cardHandler);
            this.card.destroy();
        }
    }
    onChange({ error }) {
        console.log("card errors", error);
        if (error) {
            this.cardErrors = error.message;
        } else {
            this.cardErrors = null;
        }
        this.cd.detectChanges();
    }

    ngOnInit() {

        //alert('onInit');
        // --- passed from subscription component via state object
      this.plan2subscribe = {
        Interval: 1,
        Name: "Per User",
        Price: 25,
        Quantity: 1
      }//window.history.state.plan;

        console.log(this.plan2subscribe);

        this.theForm = this.formBuilder.group({
            customerFirstName: ['', Validators.required],
            customerLastName: ['', Validators.required],
            customerEmail: ['', Validators.required],
            cardToken: null
            //cardNumber: ['', Validators.required],
            //cardExpDate: ['', Validators.required],
            //cardCode: [null]
        });

        this.currentUser = this.userAuth.currentUserValue;
        if (this.currentUser.paymentProfileId) {

            this.loadProfile();
        }
        else {

            this.theForm.patchValue({
                customerFirstName: this.currentUser.firstName,
                customerLastName: this.currentUser.lastName,
                customerEmail: this.currentUser.email
            });
        }

    }

    // convenience getter for easy access to form fields
    get f() { return this.theForm.controls; }

    loadProfile(): void {
        this.paymentsService.getPaymentProfile().subscribe(p => {
            this.paymentProfile = p;
            console.log("paymentProfile", this.paymentProfile);
            let r = this.paymentProfile.paymentServiceResponse;
            this.theForm.patchValue({
                //customerFirstName: r.paymentProfiles[0].billTo.firstName,
                //customerLastName: r.paymentProfiles[0].billTo.lastName,
                customerFirstName: this.currentUser.firstName,
                customerLastName: this.currentUser.lastName,
                customerEmail: r.email,
                //cardNumber: r.paymentProfiles[0].payment.item.cardNumber,
                //cardExpDate: r.paymentProfiles[0].payment.item.expirationDate,
            }
            );
            this.cardType = r.sources.data[0].brand;
            this.cardTypeIcon = this.cardType.toLowerCase();
            if (this.cardType === "American Express") {
                this.cardTypeIcon = "amex"
            } else if (this.cardType === "Diners Club") {
                this.cardTypeIcon = "diners-club"
            }
            this.cardLast4 = r.sources.data[0].last4;
            this.cardExpDate = r.sources.data[0].exp_month + "/" + r.sources.data[0].exp_year;


            if (!this.currentUser.paymentProfileId) {
                this.currentUser.paymentProfileId = r.customerProfileId;
            }
        });
    }

    goBack() {
        this.urlService.goToPreviousUrl();
    }
    deleteProfile() {

        const dialogRef = this.dialog.open(ConfirmationDialogComponent,
            {

              data: `Are you sure you want to delete payment profile for ${this.currentUser.clientName}`

            });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.paymentsService.deletePaymentProfile().subscribe(ret => {
                    console.log(ret);
                    this.flash.show('Successfully deleted the customer payment profile',
                        { cssClass: 'alert-success', timeout: 3000 });
                    // remove payment profile
                    this.currentUser.paymentProfileId = null;
                    this.cardType = null;
                    this.userAuth.setNewUserValue(this.currentUser);
                    this.router.navigate(['/subscriptions']);

                });
            }
        });

    }
    async onSubmit() {
        // stop here if form is invalid
        if (this.theForm.invalid) {
            return;
        }
        if (this.card) {
            const { token, error } = await this.stripe.createToken(this.card);
            if (error) {
                console.log('Something is wrong:', error);
                return;
            } else {
                console.log('Success!', token);
                this.theForm.patchValue({
                    cardToken: token.id,
                })
            }
        }

        let postJson: object = this.theForm.value;
        console.log("postJson", postJson);
        //this.setFormData();
        if (this.currentUser.paymentProfileId) {

            this.paymentsService.updatePaymentProfile(postJson)
                .subscribe(
                    result => {
                        this.flash.show('Successfully updated the customer payment profile',
                            { cssClass: 'alert-success', timeout: 3000 })

                        this.loadProfile();
                        this.router.navigate(['/client']);
                    }
                );
        } else {

            //
            Object.assign(postJson, { plan: this.plan2subscribe });

            console.log("postJson", postJson);

            this.paymentsService.createSubscription(postJson)
                .subscribe(
                    result => {

                        this.paymentProfile = result;
                        this.flash.show(`Successfully subscribed to ${this.plan2subscribe.Name} plan`, { cssClass: 'alert-success', timeout: 3000 });
                        console.log(this.paymentProfile);

                        this.currentUser.paymentProfileId = this.paymentProfile.profileId;
                        this.currentUser.subscriptionName = this.paymentProfile.subscriptionName;
                        this.currentUser.subscriptionPlanId = this.paymentProfile.subscriptionPlanId;
                        this.currentUser.subscriptionStatus = this.paymentProfile.subscriptionStatus;
                        this.userAuth.setNewUserValue(this.currentUser);

                        this.router.navigate(['/client']);

                    }
                );
        }
    }

    //createSubscription() {

    //  console.log("createSubscription", this.plan2subscribe);

    //  if (this.plan2subscribe) {
    //    // create new subscription
    //    //alert(this.plan2subscribe);
    //    this.paymentsService.createSubscriptionFromProfile(this.plan2subscribe)
    //      //.pipe(delay(1000))
    //      .subscribe(ret => {

    //        this.flash.show(`Successfully subscribed to ${this.plan2subscribe.name} plan`, { cssClass: 'alert-success', timeout: 3000 });
    //        console.log(ret);

    //        this.router.navigate(['/subscriptions']);
    //        //this.paymentService.getPaymentProfile().subscribe(
    //        //  profile => {
    //        //    this.profile = profile;
    //        //    console.log(this.profile);
    //        //    // TODO: update current user current/next subscription datapoints
    //        //    this.updateCurrentUserSubscription();
    //        //  });
    //      }
    //      );
    //  }
    //}
}
