<div class="container-fluid" *ngIf="company">
  <div class="btn btn-link p-0" (click)="goBack()">
    <i class="fas fa-chevron-circle-left"></i> back to Companies
  </div>
  <h1 class="my-2">
    {{company.name}}
    <span class="small ml-2">
      <a href="{{company.linkedin}}" target="_blank">
        <i class="fab fa-linkedin text-secondary"></i>
      </a>
    </span>
    <button class="btn btn-link ml-2" (click)="createNewCampaign()" title="Create new campaign"><i class="far fa-envelope fa-2x text-secondary"></i></button>
    <button class="btn btn-link" (click)="editCompany(company.id)" title="Edit Company"><i class="far fa-edit fa-lg text-secondary"></i></button>
  </h1>
  <h6 class="text-black-50">
    <span class="mr-2">
      <i class="fas fa-map-marker-alt"></i> {{company.streetaddress}} {{company.city}}, {{company.state}} {{company.zip}}
    </span>
    |
    <span class="ml-2">
      <a class='text-secondary' href="//{{company.website}}" target='_blank'>{{company.website}}</a>
    </span>
  </h6>
  <div class="row mt-4">
    <div class="col-md-3">
      <div class="company-card card p-3 bg-light">
        <label>Assigned to</label>
        <h6>{{(company.assignedUserName ? company.assignedUserName : '--')}}</h6>
        <label>Phone</label>
        <h6>{{(company.phone ? company.phone : '--')}}</h6>
        <label>Industry</label>
        <h6>{{(company.industry ? company.industry : '--')}}</h6>

      </div>
    </div>
    <div class="col-md-3">
      <div class="company-card card p-3 bg-light">
        <label>Category</label>
        <h6>{{(company.category ? company.category : '--')}}</h6>
        <label>Number of employees</label>
        <h6>{{(company.employeeRange ? company.employeeRange : '--')}}</h6>
        <label>Annual revenue</label>
        <h6>{{company.revenueRange ? company.revenueRange : '--'}}</h6>
      </div>
    </div>
    <!--<div class="col-md-3">
      <div class="company-card card p-3 rounded">
        <p>Active contacts <span class="rounded font-weight-bolder float-right">{{company.contactActiveCount}}</span></p>

        <p>Bounced contacts <span class="rounded font-weight-bolder float-right">{{company.contactBouncedCount}}</span></p>

        <p>Responded contacts <span class="rounded font-weight-bolder float-right">{{company.contactRespondedCount}}</span></p>

        <p>Unsubscribed contacts <span class="rounded font-weight-bolder float-right">{{company.contactUnsubscribedCount}}</span></p>

      </div>
    </div>-->
    <div class="col-md-6">
      <div class="company-card card p-3 bg-light">
        <h6 class="bg-light"><span (click)="createCompanyNote()" class="btn btn-secondary btn-sm float-right cursor-pointer">+ Note</span> Notes </h6>
        <ul class="mb-3">
          <li *ngFor="let n of company.companyNotes" class="border-bottom">

            <i (click)="deleteCompanyNote(n.id)" class="fas fa-trash-alt text-danger float-right m-1 cursor-pointer"></i>
            <i (click)="createCompanyNote(n.id,n.notes)" class="far fa-edit text-secondary float-right m-1 cursor-pointer"></i>

            {{n.notes}}
            <div class="small text-muted">
              <sup>{{n.createddate | date: 'short'}} - {{n.createdbyName}}</sup>

            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-12">
      <button class="btn btn-secondary float-right" (click)="editContact(0,company.name)">New Contact</button>
      <h2>Contacts</h2>
      <div>
        Active: <span class="font-weight-bolder mr-4">{{company.contactActiveCount}}</span>
        Bounced: <span class="font-weight-bolder mr-4">{{company.contactBouncedCount}}</span>
        Responded: <span class="font-weight-bolder mr-4">{{company.contactRespondedCount}}</span>
        Unsubscribed: <span class="font-weight-bolder mr-4">{{company.contactUnsubscribedCount}}</span> 
      </div>
    </div>
  </div>
  <div class="row" *ngIf="company">
    <table class="col-12 table table-hover table-responsive">
      <thead class="bg-light">
        <tr>
          <th>&nbsp;</th>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Job Title</th>
          <th scope="col">Phone</th>
          <th scope="col">LinkedIn</th>
          <!--<th scope="col">Source</th>-->
          <th scope="col">Status</th>
          <th scope="col">Last Email Sent Date</th>
          <th scope="col">Emails sent/opened</th>
          <th scope="col">New Campaign</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let c of company.contact">
          <td><i type="button" (click)="editContact(c.id,company.name)" class="far fa-edit fa-lg btn-link text-secondary"></i></td>
          <td>{{c.firstName}} {{c.lastName}}</td>
          <td>{{c.email }}</td>
          <td>{{c.title }}</td>
          <td>{{c.phone}}</td>
          <td><a class='text-secondary' href="{{c.linkedin}}" target='_blank'><i class="fab fa-2x fa-linkedin text-secondary"></i></a></td>

          <!--<td>{{c.contactSource}}</td>-->
          <td>{{c.contactStatus}}</td>
          <td>{{c.lastContactedDate | date:'short'}}</td>
          <td>{{c.totalEmailsSent}}/{{c.totalEmailsOpened}}</td>
          <td>
            <button class="btn btn-link ml-2" (click)="createNewCampaign(c)" title="Create new campaign"><i class="far fa-envelope fa-2x text-secondary"></i></button>
          </td>
        </tr>
      </tbody>

    </table>


  </div>
  <div class="row mt-3">
    <div class="col-md-12">
      <label class="btn btn-sm btn-secondary cursor-pointer float-right" style="text-transform:none" for="files">Upload files(s)</label>
      <input type="file" style="display:none;"
             id="files"
             multiple
             (change)="onFileChange($event.target.files)" />
      <h3>
        Documents
      </h3>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-12">
      <mat-chip-list>
        <!--<mat-chip *ngFor="let i of companyFiles" [selectable]=false
                  [removable]=true (removed)="removeAttachment(i)">
          {{i.name}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>-->
        <mat-chip class="bg-light border" *ngFor="let i of companySavedFiles"
                  [selectable]=true
                  [removable]=true
                  (removed)="removeSavedFile(i)">
          <a class="text-secondary" href="{{i.fileUrl}}" target="_blank">{{i.fileName}}</a>
          <mat-icon matChipRemove class="text-danger">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>

