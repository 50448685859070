import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PreviousURLService } from '../../services/previous-url.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { EmailMessageService } from '../../services/email-message.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-email-campaign-upload',
  templateUrl: './email-campaign-upload.component.html',
  styleUrls: ['./email-campaign-upload.component.scss']
})
export class EmailCampaignUploadComponent implements OnInit {

  constructor(
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private urlService: PreviousURLService,
    private flash: FlashMessagesService,
    private cd: ChangeDetectorRef,
    private emailMsgService: EmailMessageService
  ) { }

  formattedDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  template_id: any;
  email_template: any;
  emailCampaignUploadForm: FormGroup;
  buttonText = "Save";
  formData = new FormData();

  ngOnInit() {

    this.template_id = this.route.snapshot.paramMap.get('template_id');

    console.log("template_id", this.template_id);
    this.emailMsgService.get(this.template_id).subscribe(result => {
      this.email_template = result;
      console.log(this.email_template);
    });

    this.emailCampaignUploadForm = this.fb.group({
      CampaignName: [null, Validators.required],
      StartDate: [this.formattedDate, Validators.required],
      StartTime: [null, Validators.required],
      file: [null, Validators.required]
    });


  };

  get f() { return this.emailCampaignUploadForm.controls; }

  onFileChange(files) {
    const reader = new FileReader();
    if (!files) {
      return;
    }
    if (files) {
      const excelFile = files[0];
      console.log(excelFile);
      //reader.readAsDataURL(MultimediaFile);
      //reader.onload = () => {
      //  this.campaignImageFile = reader.result;
      //}

      this.formData.set('ExcelFile', excelFile);

      // need to run CD since file load runs outside of zone
      this.cd.markForCheck();
    }
  }

  onSubmit() {
    this.buttonText = 'Submitting...';
    this.setFormData();

    this.emailMsgService.uploadCampaignContacts(this.formData).subscribe(result => {
      this.flash.show('Successfully scheduled new email campaign', { cssClass: 'alert-success', timeout: 3000 });
      this.goBack();

    });
    this.buttonText = 'Save';
  }
  goBack() {
    this.router.navigate(['/template']);
    // this.urlService.goToPreviousUrl();
  }
  setFormData() {

    this.formData.set("EmailTemplateId", this.template_id);

    let entries = Object.entries(this.emailCampaignUploadForm.value);
    for (let [key, value] of entries) {
      this.formData.set(key, String(value));

      console.log(key, String(value));
    }
  }
}
