<div class="container-fluid mb-2">
  <div class="row">
    <div class="col-md-12">
      <button type="button" class="btn btn-secondary float-right" (click)="newCompany()">New Company</button>

      <h2 class="mb-2">Companies</h2>

    </div>
  </div>

  <fieldset class="border mb-2 rounded link">
    <!--<legend class="w-auto pl-2 pr-2">Filter </legend>-->
    <div class="row pb-2 pl-2">

      <div class="col-md-3">Company<br /><input class="form-control" type="search" [(ngModel)]="filterByCompany" name="name" (change)="onFilterChange($event)" /></div>
      <div class="col-md-3">Industry<br /><input class="form-control" type="search" [(ngModel)]="filterByIndustry" name="industry" (change)="onFilterChange($event)" /></div>
      <div class="col-md-2" *ngIf="currentUser.roleId < 3">
        Assigned To<br />
        <select name="assignedUserId" [(ngModel)]="filterByAssignedUser" (change)="onFilterChange($event)" class="form-control">
          <option value='-1'></option>
          <option *ngFor="let item of users" [value]="item.id">{{item.firstName}} {{item.lastName}}</option>
        </select>
      </div>
      <div class="col-md-2 pt-2" *ngIf="currentUser.roleId < 3">
        <br />
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="assignedUserId" name="assignedUserId" (change)="onFilterChange($event)" [(ngModel)]="showMyCompanies">
          <label class="custom-control-label" for="assignedUserId">My Companies</label>
        </div>
      </div>
      <div class="col-md-2">
        <br />
        <button class="btn btn-link" *ngIf="allFilters" (click)="removeFilters()">Remove Filters</button>
      </div>
    </div>
  </fieldset>

  <div class="mb-3">
    <!--[pinnedBottomRowData]="pinnedBottomRowData"-->
    <ag-grid-angular style="width: auto; height: 400px"
                     class="ag-theme-balham"
                     [rowData]="companies"
                     [animateRows]="true"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="defaultColDef"
                     rowSelection="multiple"
                     [pagination]="true"
                     [paginationPageSize]="50"
                     (selectionChanged)="onSelectionChanged()"
                     (gridReady)="onGridReady($event)"
                     (cellClicked)="onCellClicked($event)"
                     (filterChanged)="onFilterModified($event)"
                     (firstDataRendered)="applyAllFilters()"
                     [getRowNodeId]="getRowNodeId"
                     [pinnedBottomRowData]="pinnedBottomRowData">
    </ag-grid-angular>

  </div>

  <button type="button" class="btn btn-outline-secondary btn-lg ml-1 mr-1" (click)="downloadCompanies()">Download</button>

  <button type="button" class="btn btn-link btn-lg ml-1 mr-1" (click)="createNewCampaign()">New Campaign</button>
  <span *ngIf="currentUser.roleId < 3">
    <select id="assignToUser" [(ngModel)]="assignUserId" class="custom-select-lg align-bottom float-right">
      <option value="-1"></option>
      <option *ngFor="let item of users" [value]="item.id">{{item.firstName}} {{item.lastName}}</option>
    </select>
    <button [disabled]="selectedRows.length==0 || assignUserId < 0" type="button" class="btn btn-secondary btn-lg ml-1 mr-1 float-right" (click)="assignCompanyUser()">Assign Selected Companies To</button>
  </span>

</div>
