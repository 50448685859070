import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  getAll() {
    return this.http.get<User[]>(`${environment.apiUrl}/users`);
  }

  getById(id: number) {
    return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  getByToken(token: string) {
    return this.http.get<User>(`${environment.apiUrl}/users/invitedUser?token=${token}`);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/users/${id}`);
  }

  status(id: number, active: number) {
    return this.http.put(`${environment.apiUrl}/users/${id}/status?active=${active}`, "");
  }

  invite(email: string) {
    return this.http.post(`${environment.apiUrl}/users/invite`, { email });
  }

  resendInvite(id: number) {
    return this.http.post(`${environment.apiUrl}/users/${id}/sendInvite`, "");
  }
  forgotPassword(useremail: string) {

    return this.http.post(`${environment.apiUrl}/users/forgotpassword?email=${useremail}`, "")
      .pipe(
        catchError(this.configService.handleError)
      )
  }
  verifyEmail(token: string) {

    return this.http.post<User>(`${environment.apiUrl}/users/verifyemail?token=${token}`, "")
      .pipe(
        catchError(this.configService.handleError)
      )
  }
  resetPassword(password1: string, password2: string, token: string) {
    return this.http.post<any>(`${environment.apiUrl}/Users/resetpassword`, { password1, password2, token })
      .pipe(
        catchError(this.configService.handleError)
      );
  }

  update(user_id: number, userform) {
    
    return this.http.post(`${environment.apiUrl}/Users/${user_id}/Update`, userform);
   
  }
  activate(userform) {

    return this.http.post(`${environment.apiUrl}/Users/Activate`, userform);

  }
  signup(client) {
    return this.http.post(`${environment.apiUrl}/Clients/Signup`, client);
  }
  getClient(id: number) {
    return this.http.get(`${environment.apiUrl}/Clients/${id}`);
  }
  needSubscription() {
    return this.http.get(`${environment.apiUrl}/Subscription`);
  }
  updateClient(client) {
    return this.http.post(`${environment.apiUrl}/Clients`, client);
  }
  getEmailSettings(id:number) {
    return this.http.get(`${environment.apiUrl}/Users/${id}/EmailSettings`);
  }
  updateEmailSettings(id:number, emailsettings) {
    return this.http.post(`${environment.apiUrl}/Users/${id}/EmailSettings`, emailsettings)
      .pipe(
        catchError(this.configService.handleError)
      );
  }
  contactus(contactform) {
    return this.http.post(`${environment.apiUrl}/contactus`, contactform);
  }
}
