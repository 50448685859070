import { Component, OnInit, ViewContainerRef, ViewChild, LOCALE_ID } from '@angular/core';
import { EmailMessageService } from '../../services/email-message.service';
import { CampaignMessageService } from '../../services/campaign-message.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
//import "ag-grid-community/dist/styles/ag-grid.css";
//import "ag-grid-community/dist/styles/ag-theme-blue.css";
//import { AllCommunityModules } from "ag-grid-community";
import { formatDate } from '@angular/common';
import { CellCallbackRendererComponent } from '../campaign-message/cell-callback-renderer/cell-callback-renderer.component';

@Component({
  selector: 'app-campaign-message',
  templateUrl: './campaign-message.component.html',
  styleUrls: ['./campaign-message.component.scss']
})
export class CampaignMessageComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private emailMsgService: EmailMessageService,
    private campaignMsgService: CampaignMessageService,
    public dialog: MatDialog,
    public view: ViewContainerRef,
    private flash: FlashMessagesService,
    private route: ActivatedRoute,
    private router: Router
  ) {


    this.context = { componentParent: this };
    this.frameworkComponents = {
      cellCallbackRenderer: CellCallbackRendererComponent
    };
  }

  /***** interface ICellRendererParams {
  value: any, // value to be rendered
  valueFormatted: any, // value to be rendered formatted
  getValue: () => any, // convenience function to get most recent up to date value
  setValue: (value: any) => void, // convenience to set the value
  formatValue: (value: any) => any, // convenience to format a value using the columns formatter
  data: any, // the rows data
  node: RowNode, // row rows row node
  colDef: ColDef, // the cells column definition
  column: Column, // the cells column
  rowIndex: number, // the current index of the row (this changes after filter and sort)
  api: GridApi, // the grid API
  eGridCell: HTMLElement, // the grid's cell, a DOM div element
  eParentOfValue: HTMLElement, // the parent DOM item for the cell renderer, same as eGridCell unless using checkbox selection
  columnApi: ColumnApi, // grid column API
  context: any, // the grid's context
  refreshCell: () => void // convenience function to refresh the cell
  }
  */

  //@ViewChild('agGridCampaigns') agGridCamp: AgGridAngular;
  private agGridCampaignsApi;
  private agGridContactsApi;
  private locale: string;
  public context;
  public frameworkComponents;
  campaigns: any = [];
  contacts: any = [];
  currentUser;
  columnDefs = [
    {
      headerName: '',
      width: 35,
      checkboxSelection: true,
      resizable: false,
      //pinned: 'left'
      //{
        //return '<button class="btn btn-secondary btn-sm" onClick="editCampaign('+ params.data.id.value+')"> Edit < /button>' +
        //       '<button class="btn btn-danger btn-sm" onClick="deleteCampaign('+ params.data.id.value+')"> Delete < /button>'

      //}
    },
    {
      headerName: '',
      width: 85,
      resizable: false,
      cellRenderer: "cellCallbackRenderer",
      autoHeight: true,
      cellStyle: { "white-space": "normal" },
      //pinned:'left'
    },
    { headerName: 'Campaign Title', field: 'campaignTitle', width: 250 },
    {
      headerName: 'Start Date', field: 'startDate', width: 100,
      cellRenderer: (data) => { return formatDate(data.value,'dd MMM yyyy', 'en-us')}
    },
    {
      headerName: 'Start Time', field: 'startTime', width: 80,
      cellRenderer: (data) => {
        if (data.value != null) {
          let dt: Date = new Date(data.data.startDate);
          let y = dt.getFullYear();
          let m = dt.getMonth() + 1;
          let d = dt.getDate();
          var dd = y + '-' + m + '-' + d + ' ' + data.value;
          return formatDate(new Date(dd), 'hh:mm a', 'en-us');
        }
      }
    },
    {
      headerName: 'Created Date', field: 'createddate', width: 150,
      sort: 'desc',
      cellRenderer: (data) => {
        return formatDate(data.value, 'dd MMM yyyy hh:mm a', 'en-us')
      }
    },
    { headerName: 'Completed', field: 'isStarted', width: 80 },
    {
      headerName: 'Pause/Start', field: 'isPaused', width: 100,
      cellRenderer: "cellCallbackRenderer"
    },
    { headerName: '# of contacts', field: 'campaignContactsCount', width: 50 },
    {
      headerName: 'Email Subject', field: 'emailSubject', width: 250,
      //cellRenderer: "cellCallbackRenderer"
      //cellRenderer: (params) => {
      //  return '<a class="btn btn-link btn-sm" href="template/' + params.data.emailMessageId + '/edit">' + params.value
      //}
    }
  ];
  //columnDefs2 = [
  //  { headerName: 'Email', field: 'email', width: 230, checkboxSelection: true},
  //  { headerName: 'First Name', field: 'firstName', width: 100,},
  //  { headerName: 'Last Name', field: 'lastName', width: 100 },
  //  { headerName: 'Title', field: 'title', width: 100 },
  //  { headerName: 'Company', field: 'companyName', width: 140 },
  //  {
  //    headerName: 'Email Sent Date', field: 'emailSentDate', width: 150,
  //    cellRenderer: (data) => {
  //      if (data.value !== null) {
  //        return formatDate(data.value, 'dd MMM yyyy hh:mm a', 'en-us')
  //      }
  //    }
  //  },
  //  {
  //    headerName: 'Status', field: 'emailSentStatus', width: 80,
  //    cellRenderer: (data) => {
  //      switch (data.value)
  //      {
  //        case 0:
  //          return 'Not Sent';
  //        case 1:
  //          return 'Sent';
  //        case 2:
  //          return 'Failed';
  //      }
  //    }
  //  },
  //  { headerName: 'Error', field: 'emailSentError', width: 200}

  //];
  defaultColDef = { resizable: true, sortable: true, rowHeight: 40 };
  //defaultColDef2 = { resizable: true, sortable: true };
  
  ngOnInit() {
    this.authService.currentUser.subscribe(x => this.currentUser = x);

  }

  onGridReady(params) {

    this.agGridCampaignsApi = params.api;
    this.campaignMsgService.getAllCampaignMessages().subscribe(cmp => {
      this.campaigns = cmp;

      console.log(this.campaigns);
      console.log("currentUser", this.currentUser)
    });

    //this.agGridCampaignsApi.sizeColumnsToFit();
  }

  //onGridReady2(params) {

  //  this.agGridContactsApi = params.api;

  //}
  onSelectionChanged() {
    var selectedRows = this.agGridCampaignsApi.getSelectedRows();
    console.log("selectedCampaignRows", selectedRows);
    //if (selectedRows.length > 0) {
    //  var msgid = selectedRows[0].id;
    //  this.campaignMsgService.getCampaignMessageContacts(msgid).subscribe(cnt => {
    //    this.contacts = cnt;

    //    console.log(this.contacts);
    //  });
    //}
  }

  //onSelectionChanged2() {
  //  var selectedRows = this.agGridContactsApi.getSelectedRows();
  //  console.log("selectedContactRows", selectedRows);
  //}

  getRowHeight(params) {
    return 34;
  }
  actionFromCell(action, data) {
    //alert(action + ":" + data.id);
    if (action === 'edit') {
      this.router.navigate(['/', 'campaign', 'message', data.id]);
    }
    else if (action === 'delete') {
      this.deleteCampaign(data.id, data.campaignTitle);
    }
    else if (action == 'template') {
      this.router.navigate(['/', 'template', data.emailMessageId , 'edit']);
    }
    else if (action === 'pause' || action === 'restart') {
      //alert(action);
      let pause_param: boolean = (action === 'pause' ? true : false);
      this.campaignMsgService.pauseCampaignMessage(data.campaignId, data.id, pause_param).subscribe(response => {
        this.flash.show(`Successfully ${action}ed campaign`, { cssClass: 'alert-success', timeout: 5000 });
        this.campaigns.forEach((item, index) => {
          if (item.id == data.id) {
            //alert(data.id + ":" + pause_param);
            item.isPaused = pause_param;
          }
        });
        //this.removeSelected()
        this.agGridCampaignsApi.updateRowData(this.campaigns);
      })
    }
  }

  deleteCampaign(id, name) {


    let root_elm = this.view;
    console.log(root_elm);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {

        data: `Are you sure you want to delete the campaign "${name}"?`,
        viewContainerRef: root_elm,
      });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.campaignMsgService.deleteCampaignMessage(id).subscribe(response => {
          this.flash.show('Successfully deleted campaign', { cssClass: 'alert-success', timeout: 5000 });
          this.campaigns.forEach((item, index) => {
            if (item.id == id) {
              this.campaigns.splice(index, 1);
            }
          });
          //this.removeSelected()
          this.agGridCampaignsApi.setRowData(this.campaigns);
        })
      }
    });
  }

  //removeSelected() {
  //  var selectedRowNodes = this.agGridCampaignsApi.getSelectedNodes();
  //  var selectedIds = selectedRowNodes.map(function (rowNode) {
  //    return rowNode.id;
  //  });
  //  this.campaigns = this.campaigns.filter(function (dataItem) {
  //    return selectedIds.indexOf(dataItem.id) < 0;
  //  });
  //  this.agGridCampaignsApi.setRowData(this.campaigns);
  //}
}
