import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PaymentsService {

    constructor(
        private http: HttpClient,
        private configService: ConfigService
    ) { }
    url = environment.apiUrl;

    getPaymentPlans() {
        return this.http.get(`${this.url}/Payments/Plans`);
    }

    getPaymentPlanAvailableContent(companyToken) {
        let params = new HttpParams().set("companyToken", companyToken);
        return this.http.get(`${this.url}/Payments/Plans/AvailableContent`, { params: params });
    }

    getPaymentProfile() {
        //return this.http.get(`${this.url}/Payments/AuthorizeNet/CustomerProfile`);
        return this.http.get(`${this.url}/Payments/Stripe/CustomerProfile`);
    }

    createPaymentProfile(profile) {
        return this.http.post(`${this.url}/Payments/Stripe/CustomerProfile`, profile)
            .pipe(
                catchError(this.configService.handleError)
            )
    }
    deletePaymentProfile() {
        return this.http.delete(`${this.url}/Payments/Stripe/CustomerProfile`)
            .pipe(
                catchError(this.configService.handleError)
            )
    }
    updatePaymentProfile(profile) {
        return this.http.put(`${this.url}/Payments/Stripe/CustomerProfile`, profile)
            .pipe(
                catchError(this.configService.handleError)
            )
    }
    createSubscription(profile_plan) {
        return this.http.post(`${this.url}/Payments/Stripe/Subscription/Create`, profile_plan)
            .pipe(
                catchError(this.configService.handleError)
            )
    }
    createSubscriptionFromProfile(plan) {
        //--- since service is using the same endpoint as for createSubscription
        //--- need to have a plan as nested object 
        var profile_plan = Object.create({}, { plan: { writable: true, enumerable: true } });
        profile_plan.plan = plan;

        console.log("createSubscriptionFromProfile", profile_plan);

        return this.http.post(`${this.url}/Payments/Stripe/Subscription/Create`, profile_plan)
            .pipe(
                catchError(this.configService.handleError)
            )
    }
    updateSubscription(plan) {
        return this.http.post(`${this.url}/Payments/Stripe/Subscription/Update`, plan)
            .pipe(
                catchError(this.configService.handleError)
            )
    }
    cancelSubscription() {
        return this.http.post(`${this.url}/Payments/Stripe/Subscription/Cancel`, "")
            .pipe(
                catchError(this.configService.handleError)
            )
    }
    reactivateSubscription() {
        return this.http.post(`${this.url}/Payments/Stripe/Subscription/Reactivate`, "")
            .pipe(
                catchError(this.configService.handleError)
            )
    }
    cancelSubscriptionImmediately() {
        return this.http.post(`${this.url}/Payments/Stripe/Subscription/CancelImmediately`, "")
            .pipe(
                catchError(this.configService.handleError)
            )
    }
}
