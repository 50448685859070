import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) { }

  user_email: string = '';
  support_email: string = '';
  token: string = '';
  sucessMessage: string = '';
  error: string = '';
  verifieduser: User;

  ngOnInit() {

    //this.email = this.route.snapshot.queryParams['email'];
    console.log(history.state.data);
    if (history.state.data) {
      this.user_email = history.state.data.user_email;
      this.support_email = history.state.data.support_email;
    }

    this.token = this.route.snapshot.queryParams['token'];

    if (typeof this.token != 'undefined') {

      this.userService.verifyEmail(this.token)
        .subscribe(
          data => {
            console.log(data);
            this.verifieduser = data;
            this.sucessMessage = `Email address ${this.verifieduser.email} was successfully verified!`;

            setTimeout(() => {
              this.router.navigate(['login']);
            }, 6000);
          },
          error => {
            this.error = error;
            this.sucessMessage = '';
          });

    }

  }

}
