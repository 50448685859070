import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) { }

  theForm: FormGroup;
  loading = false;
  submitted = false;
  resetToken: string;
  error = '';
  sucessMessage: string;

  ngOnInit() {
    this.theForm = this.formBuilder.group({
      password1: ['', Validators.required],
      password2: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.resetToken = this.route.snapshot.queryParams['token'];
    this.sucessMessage = '';
  }
  // convenience getter for easy access to form fields
  get f() { return this.theForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.error = '';

    // stop here if form is invalid
    if (this.theForm.invalid) {
      return;
    }

    this.loading = true;
    this.userService.resetPassword(this.f.password1.value, this.f.password2.value, this.resetToken)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.sucessMessage = `Password was reset successfully!`;

          setTimeout(() => {
            this.router.navigate(['login']);
          }, 3000);  

          this.loading = false;
        },
        error => {
          this.error = error;
          this.sucessMessage = '';
          this.loading = false;
        });
  }

}
