
import { Component, OnInit, ViewContainerRef, ViewChild, LOCALE_ID, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { EmailMessageService } from '../../services/email-message.service';
import { CampaignMessageService } from '../../services/campaign-message.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../models/user';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';

import { formatDate } from '@angular/common';
import { ContactService } from '../../services/contact.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CellCallbackRendererComponent } from '../campaign-message/cell-callback-renderer/cell-callback-renderer.component';
import { ContactUploadDialogComponent } from '../contact-upload-dialog/contact-upload-dialog.component';
import { ContactEditDialogComponent } from './contact-edit-dialog/contact-edit-dialog.component';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  routeSubscription: any;

  constructor(
    private authService: AuthenticationService,
    private emailMsgService: EmailMessageService,
    private campaignMsgService: CampaignMessageService,
    private contactService: ContactService,
    private userService: UserService,
    public dialog: MatDialog,
    public view: ViewContainerRef,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private flash: FlashMessagesService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });

    this.context = { componentParent: this };
    this.frameworkComponents = {
      cellCallbackRenderer: CellCallbackRendererComponent
    };
  }

  //contactUploadForm: FormGroup;

  //formData = new FormData();

  private agGridContactsApi;
  public context;
  public frameworkComponents;

  contacts: any = [];
  selectedRows: any = [];
  filteredRows: any = [];
  selectedContactIds: number[] = [];
  filteredContactIds: number[] = [];
  setContactStatus = 1; // 1 - Active
  filterByStatus = '1'; // 1 - Active
  filterByEmail = '';
  filterByCompany = '';
  filterByCompanyByExactName: boolean = false;
  filterByIndustry = '';
  savedFilterModel;
  allFilters;
  resetFilters: boolean = false;
  showMyCompanies: boolean = true;
  users: User[];

  getRowNodeId = function (data) {
      return data.id;
  }

  currentUser;
  columnDefs = [
    {
      headerName: ' ', field: 'id', width: 45,
      pinned: true,
      filter: false,
      resizable: false,
      sortable: false,
      cellRenderer: () => {
        return `<i class="far fa-edit fa-lg btn-link text-secondary"></i>`
      }
    },
    //{
    //  headerName: 'Id', field: 'id', width: 60, pinned: true,
    //},
    {
      headerName: 'Email', field: 'email', width: 220,
      checkboxSelection: true,
      pinned: true,

    },
    {
      headerName: 'First Name', field: 'firstName', width: 100,
    },
    {
      headerName: 'Last Name', field: 'lastName', width: 100,
    },
    {
      headerName: 'Job Title', field: 'title', width: 200,
    },
    {
      headerName: 'Department', field: 'department', width: 100, hide: true,
    },
    {
      headerName: 'Phone', field: 'phone', width: 100, hide: true,
    },
    {
      headerName: 'LinkedIn', field: 'linkedin', width: 180,
      cellRenderer: (data) => {
        if (data.value === null) {
          return '';
        } else {
          return `<a class='text-secondary' href="${data.value}" target='_blank'>${data.value}</a>`
        }
      }
    },
    {
      headerName: 'Status', field: 'contactStatus', width: 80,
    },
    {
      headerName: 'Social Connection', field: 'isSocialConnection', width: 80,
      cellStyle: function () {
        return { "text-align": "center" }
      },
      cellRenderer: (data) => {
        return data.value === true ? `<i class="fas fa-check"></i>` : ``
      }
    },
    {
      headerName: 'Company Name', field: 'companyName', width: 250,
      sort: 'asc',
    },
    //{
    //  headerName: 'Company Short Name', field: 'companyShortName', width: 150, hide:true,
    //},
    {
      headerName: 'Company Website', field: 'companyWebsiteUrl', width: 180,
      cellRenderer: (data) => {
        if (data.value === null) {
          return '';
        } else {
          return `<a class='text-secondary' href="//${data.value}" target='_blank'>${data.value}</a>`

        }
      }
    },
    {
      headerName: 'Company Linkedin', field: 'companyLinkedinUrl', width: 180,
      cellRenderer: (data) => {
        if (data.value === null) {
          return '';
        } else {
          return `<a class='text-secondary' href="${data.value}" target='_blank'>${data.value}</a>`
        }
      }
    },
    {
      headerName: 'Company Phone', field: 'companyPhone', width: 70, hide: true
    },
    {
      headerName: 'Street Address', field: 'companyAddress', width: 70, hide: true
    },
    {
      headerName: 'City', field: 'companyCity', width: 70, hide: true
    },
    {
      headerName: 'State', field: 'companyState', width: 70,
    },
    {
      headerName: 'Postal Code', field: 'companyZip', width: 70, hide: true,
    },
    {
      headerName: 'Company Industry', field: 'companyIndustry', width: 200,
    },
    {
      headerName: 'Annual Revenue', field: 'companyRevenueRange', width: 120,
    },
    {
      headerName: 'Total Employees', field: 'companyEmployeeRange', width: 100,
    },
    {
      headerName: 'User Id', field: 'companyAssignedUserId', width: 100, hide: true,
    },
    {
      headerName: 'Created Date', field: 'createddate', width: 120,
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      cellRenderer: (data) => {
        return formatDate(data.value, 'dd MMM yyyy', 'en-us')
      }
    },
    {
      headerName: 'Last Email Sent', field: 'lastContactedDate', width: 160,
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      cellRenderer: (data) => {
        if (data.value != null) {
          return formatDate(data.value, 'dd MMM yyyy hh:mm a', 'en-us')
        }
      }
    },
    {
      headerName: 'Total Email Sent', field: 'totalEmailsSent', width: 100,
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Total Email Read', field: 'totalEmailsOpened', width: 100,
      filter: 'agNumberColumnFilter',
    },
  ];

  defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    editable: false,
    filterParams: {
      resetButton: true,
      applyButton: false,
      debounceMs: 500
    },

  };

  onSelectionChanged() {
    this.selectedRows = this.agGridContactsApi.getSelectedRows();
    console.log("selectedContactRows", this.selectedRows);
  }

  applyAllFilters() {

    console.log("applyAllFilters - savedFilterModel", this.savedFilterModel);

    this.agGridContactsApi.setFilterModel(this.savedFilterModel);
    //
    var filterComponent = this.agGridContactsApi.getFilterInstance("companyAssignedUserId");
    //console.log("company filter", filterComponent.appliedModel);
    if (filterComponent.appliedModel) {
      this.showMyCompanies = true;
    } else {
      this.showMyCompanies = false;
    }

    //// email filter
    var filterComponent = this.agGridContactsApi.getFilterInstance("email");
    console.log("email filter", filterComponent.appliedModel);
    if (filterComponent.appliedModel) {
      this.filterByEmail = filterComponent.appliedModel.filter;
    }

    //// company filter
    var filterComponent = this.agGridContactsApi.getFilterInstance("companyName");
    console.log("company filter", filterComponent.appliedModel);
    if (filterComponent.appliedModel) {

      this.filterByCompany = filterComponent.appliedModel.filter;
      if (filterComponent.appliedModel.type == "equals") {
        this.filterByCompanyByExactName = true;
      }

    } else if (this.filterByCompany.length > 0) {

      filterComponent.setModel({
        type: 'equals',
        filter: this.filterByCompany
      });
      this.filterByCompanyByExactName = true;
    }

    //// industry filter
    var filterComponent = this.agGridContactsApi.getFilterInstance("companyIndustry");
    console.log("industry filter", filterComponent.appliedModel);
    if (filterComponent.appliedModel) {
      this.filterByIndustry = filterComponent.appliedModel.filter;
    } else if (this.filterByIndustry.length > 0) {

      filterComponent.setModel({
        type: 'equals',
        filter: this.filterByIndustry
      });
    }

    // status filter
    var filterComponent = this.agGridContactsApi.getFilterInstance("contactStatus");
    console.log("status filter", filterComponent.appliedModel);
    if (filterComponent.appliedModel) {
      this.filterByStatus = this.statusId(filterComponent.appliedModel.filter);
    } else {
      this.filterByStatus = '-1';
    }

    this.agGridContactsApi.onFilterChanged();
    //
    this.filteredRows = this.agGridContactsApi.getModel().rowsToDisplay;
    if (this.filteredRows == 0) {
      this.filterByCompanyByExactName = false;
    }
  }

  saveFilterModel($event) {

    this.savedFilterModel = this.agGridContactsApi.getFilterModel();
    var keys = Object.keys(this.savedFilterModel);
    this.allFilters = keys.join(',');
    console.log("savedFilterModel", this.savedFilterModel);

    localStorage.setItem('contacts_filter', JSON.stringify(this.savedFilterModel));
  }
  removeFilters() {

    this.agGridContactsApi.setFilterModel(null);
    this.filterByIndustry = '';
    this.filterByCompany = '';
    this.filterByCompanyByExactName = false;
    this.filterByEmail = '';
    this.filterByStatus = '-1';

  }
  newContact() {
    this.filteredRows = this.agGridContactsApi.getModel().rowsToDisplay;
    if (this.filteredRows.length > 0) {
      let companyid = this.filteredRows[0].data.companyId;
      let companyname = this.filteredRows[0].data.companyName;
      const dialogRef = this.dialog.open(ContactEditDialogComponent, {
        width: '800px',
        disableClose: false,
        panelClass: 'custom-modalbox',
        data: {
          companyname: companyname,
          companyid: companyid,
          id: 0
        }
      });

      this.router.events
        .subscribe(() => {
          dialogRef.close();
        });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.agGridContactsApi.updateRowData({
            add: [result]
          });

          this.flash.show(`Successfully created new contact`, { cssClass: 'alert-success', timeout: 3000 });

        }
      });
    }
    
  }
  createNewCampaign() {

    this.filteredRows = this.agGridContactsApi.getModel().rowsToDisplay;

    let root_elm = this.view;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {

        data: `Are you sure you want to create new campaign with <b>${this.filteredRows.length}</b> contacts?`,
        viewContainerRef: root_elm,
      });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log(rows);
        this.filteredContactIds.length = 0;
        this.filteredRows.forEach((item, index) => {
          //console.log(item.data.id, item.data.email);
          this.filteredContactIds.push(item.data.id);
        });

        this.router.navigate(['/', 'campaign', 'message'], { state: { data: { contactids: [this.filteredContactIds] } } });
      }
    });

  }

  onFilterModified($event) {
    this.saveFilterModel($event);
  }

  onFilterChange($event) {

    var filterCol = $event.target.name;
    var filterComponent = this.agGridContactsApi.getFilterInstance(filterCol);
    var filterVal;
    let filterOper = 'contains';
    if (filterCol == 'email')
      filterVal = this.filterByEmail;
    else if (filterCol == 'companyName') {
      filterVal = this.filterByCompany;
      this.filterByCompanyByExactName = false;
    }
    else if (filterCol == 'companyIndustry')
      filterVal = this.filterByIndustry;
    else if (filterCol == 'companyAssignedUserId') {
      if (this.showMyCompanies) {
        filterOper = 'equals';
        filterVal = this.currentUser.id;
      }
    }
    if (filterVal) {
      filterComponent.setModel({
        type: filterOper,
        filter: filterVal
      });
    } else {
      // clear filter 
      filterComponent.setModel(null);
    }

    this.agGridContactsApi.onFilterChanged();

    this.filteredRows = this.agGridContactsApi.getModel().rowsToDisplay;
    if (this.filteredRows == 0) {
      this.filterByCompanyByExactName = false;
    }

  }

  onStatusFilterChange() {
    console.log(this.filterByStatus);

    //this.loadContacts(this.filterByStatus);
    var statusFilterComponent = this.agGridContactsApi.getFilterInstance('contactStatus');

    if (this.filterByStatus !== '-1') {
      statusFilterComponent.setModel({
        type: 'equals',
        filter: this.statusName(this.filterByStatus)
      });
    } else {
      // clear filter 
      statusFilterComponent.setModel(null);
    }


    this.agGridContactsApi.onFilterChanged();

  }
  onGridReady(params) {

    this.agGridContactsApi = params.api;

    if (this.resetFilters) {
      localStorage.removeItem('contacts_filter');
    }

    var fltr = JSON.parse(localStorage.getItem('contacts_filter'));
    console.log("localstorage filter", fltr);
    if (fltr) {
      this.savedFilterModel = fltr;
      console.log(this.savedFilterModel);
      if (this.savedFilterModel.companyAssignedUserId) {
        this.savedFilterModel.companyAssignedUserId.filter = this.currentUser.id;
      }
    }

    this.agGridContactsApi.setFilterModel(this.savedFilterModel);

    console.log("before load contacts", this.agGridContactsApi.getFilterModel())

    //var compFilter = this.agGridContactsApi.getFilterInstance("companyName");
    //if (compFilter && compFilter.type === "equals") {
    //  this.filterByCompany = compFilter.filter;
    //  this.filterByCompanyByExactName = true;
    //}
    //console.log("company filter",compFilter);

    this.loadContacts();
    //console.log("after load contacts", this.agGridContactsApi.getFilterModel())

    //this.getRowNodeId = function (data) {
    //  return data.id;
    //}
  }

  onCellClicked(event) {

    if (event.column.colId == "id") {

      console.log(event.data);
      this.editrow(event.data.id, event.data.companyName);

    }
  }


  editrow(id: number, company: string): void {
    const dialogRef = this.dialog.open(ContactEditDialogComponent, {
      width: '800px',
      disableClose: false,
      panelClass: 'custom-modalbox',
      data: {
        companyname: company,
        id: id
      }
    });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        var editedRow = this.agGridContactsApi.getRowNode(id.toString());

        editedRow.setData(result);

        this.flash.show(`Successfully updated contact details`, { cssClass: 'alert-success', timeout: 3000 });

        //this.loadContacts(true);

      }
    });

  }

  ngOnInit() {

    this.authService.currentUser.subscribe(x => this.currentUser = x);
    this.showMyCompanies = true;

    if (history.state.data) {
      this.resetFilters = true;
      if (history.state.data.company) {
        this.filterByCompany = history.state.data.company;
      }
      if (history.state.data.industry) {
        this.filterByIndustry = history.state.data.industry;
      }
    } else {
      this.resetFilters = false;
    }
    //
    this.userService.getAll().subscribe(users => {

      this.users = users.filter((u) => u.status !== 'Invited');

      console.log(this.users, this.currentUser);
    })

    console.log("oninit", this.savedFilterModel);
  }
  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
  updateContactsStatus() {

    this.selectedContactIds = [];
    this.selectedRows.forEach((item, index) => {
      this.selectedContactIds.push(item.id);
    });

    //console.log(this.selectedContactIds);

    this.contactService.updateContactsStatus(this.setContactStatus, this.selectedContactIds)
      .subscribe(() => {
        this.loadContacts(true);
      });

  }
  //uploadContacts(): void {
  //  const dialogRef = this.dialog.open(ContactUploadDialogComponent, {
  //    width: '600px',
  //    data: {}
  //  });

  //  this.router.events
  //    .subscribe(() => {
  //      dialogRef.close();
  //    });

  //  dialogRef.afterClosed().subscribe(result => {
  //    //console.log('The dialog was closed');
  //    console.log(result);
  //    if (result && result.contacts) {
  //      this.flash.show(`Successfully processed ${result.contacts} contacts`, { cssClass: 'alert-success', timeout: 3000 });
  //      this.loadContacts(true);
  //    } else if (result && result.contacts == 0) {
  //      this.flash.show(`No valid contact records found`, { cssClass: 'alert-danger', timeout: 3000 });
  //      this.loadContacts(true);

  //    }
  //  });

  //}

  downloadContacts() {

    var cols = [];
    // exclude some columns from download
    this.columnDefs.forEach((item) => {
      if (item.field !== 'id' &&
        item.field !== 'companyAssignedUserId' &&
        //item.field !== 'contactStatus' &&
        item.field !== 'isSocialConnection' &&
        item.field !== 'createddate' //&&
        //item.field !== 'lastContactedDate'
      )
      {
        cols.push(item.field);
      }
    })

    var status = this.statusName(this.filterByStatus).toLowerCase();
    var filename = `contacts_${status}`;
    var params = {
      allColumns: true,
      columnKeys: cols,
      fileName: filename,
    }
    this.agGridContactsApi.exportDataAsCsv(params);

  }

  private statusName(value: any) {
    switch (value) {
      case '0':
        return 'Deactivated';
      case '1':
        return 'Active';
      case '2':
        return 'Contacted';
      case '3':
        return 'Bounced';
      case '4':
        return 'Unsubscribed';
      case '5':
        return 'Responded';
      case '6':
        return 'OnHold';
      default:
        return 'All';
    }
  }
  private statusId(value: any) {
    switch (value) {
      case 'Deactivated':
        return '0';
      case 'Active':
        return '1';
      case 'Contacted':
        return '2';
      case 'Bounced':
        return '3';
      case 'Unsubscribed':
        return '4';
      case 'Responded':
        return '5';
      case 'OnHold':
        return '6';
      default:
        return 'All';
    }
  }
  private loadContacts(applyFilters: boolean = false) {

    this.contactService.getAllContacts().subscribe(cnt => {

      this.contacts = cnt;

      this.agGridContactsApi.setRowData(this.contacts);
      console.log(cnt);
      // --- clear selected if any ---
      this.selectedRows.length = 0;
      this.selectedContactIds.length = 0;

      if (applyFilters) {
        setTimeout(() => {
          this.applyAllFilters()
        }, 500)
      }
    });
  }
}

var filterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    //var dateParts = dateAsString.split('-');
    //var cellDate = new Date(
    //  Number(dateParts[2]),
    //  Number(dateParts[1]) - 1,
    //  Number(dateParts[0])
    //);
    var cellDate = new Date(dateAsString);

    //console.log(cellValue, cellDate);

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
