<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 mat-dialog-title class="strong">{{formTitle}} | {{company_name}}</h1>
<mat-dialog-content>
  <form [formGroup]="contactEditForm" (ngSubmit)="onSubmit()">
    <!--<div class="row mb-1 mt-2">
      <div class="col-sm-8">
        <div class="form-group">
          <label class="required-label" for="name">Company Name</label>
          <input type="text" id="name" maxlength="250" formControlName="name" class="form-control"
                 [ngClass]="{ 'is-invalid':  f.name.dirty && f.name.errors }" />
          <div *ngIf="f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Company Name is required</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label for="shortname">Short Name</label>
              <input type="text" id="shortname" maxlength="250" formControlName="shortName" class="form-control" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="category">Category</label>
              <select name="category" id="category" formControlName="categoryId" class="form-control">
                <option value="0"> </option>
                <option *ngFor="let category of companyCategories" [value]="category.id">{{category.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label for="website">Website</label>
              <input type="url" id="website" maxlength="250" formControlName="website" class="form-control"
                     [ngClass]="{ 'is-invalid':  f.website.dirty && f.website.errors }" />
              <div *ngIf="f.website.errors" class="invalid-feedback">
                <div *ngIf="f.website.errors.pattern">Please enter a valid URL</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="phone">Phone</label>
              <input type="tel" formControlName="phone" id="phone" class="form-control" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="streetaddress">Street Address</label>
          <input type="text" id="streetaddress" maxlength="250" formControlName="streetaddress" class="form-control" />
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="city">City</label>
              <input type="text" id="city" maxlength="250" formControlName="city" class="form-control" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="required-label" for="state">State</label>
              <input type="text" id="state" maxlength="2" formControlName="state" class="form-control" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="zip">Zip</label>
              <input type="text" id="zip" maxlength="50" formControlName="zip" class="form-control" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">

        <div class="form-group">
          <label for="industry">Industry</label>
          <select name="industry" id="industry" formControlName="industryId" class="form-control">
            <option *ngFor="let item of companyIndustries" [value]="item.id">{{item.name}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="revenue">Revenue</label>
          <select name="revenue" id="revenue" formControlName="revenueRangeId" class="form-control">
            <option *ngFor="let item of companyRevenueRanges" [value]="item.id">{{item.name}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="employee">Employees</label>
          <select name="employee" id="employee" formControlName="employeeRangeId" class="form-control">
            <option *ngFor="let item of companyEmployeeRanges" [value]="item.id">{{item.name}}</option>
          </select>
        </div>
      </div>
    </div>-->
    <div class="row mb-1 mt-2">
      <div class="col-md-6">
        <div class="form-group">
          <label class="required-label" for="firstname">First Name</label>
          <input type="text" id="firstmame" maxlength="250" formControlName="firstName" class="form-control"
                 [ngClass]="{ 'is-invalid':  f.firstName.dirty && f.firstName.errors }" />
          <div *ngIf="f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required">First Name is required</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="required-label" for="lastname">Last Name</label>
          <input type="text" id="lastname" maxlength="250" formControlName="lastName" class="form-control"
                 [ngClass]="{ 'is-invalid':  f.firstName.dirty && f.firstName.errors }" />
          <div *ngIf="f.lastName.errors" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label class="required-label" for="email">Email</label>
          <input type="email" id="email" maxlength="250" formControlName="email" class="form-control"
                 [ngClass]="{ 'is-invalid':  f.email.dirty && f.email.errors }" />
          <div *ngIf="f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Last Name is required</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="title">Title</label>
          <select name="title" id="title" formControlName="titleId" class="form-control">
            <option *ngFor="let item of contactTitles" [value]="item.id">{{item.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="status">Status</label>
          <select name="status" id="status" formControlName="status" class="form-control">
            <option *ngFor="let item of contactStatus" [value]="item.id">{{item.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="source">Source</label>
          <select name="source" id="status" formControlName="source" class="form-control">
            <option *ngFor="let item of contactSource" [value]="item.id">{{item.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <input type="checkbox" id="socialconnection" formControlName="isSocialConnection" />
          <label for="socialconnection" class="ml-2">Social Connection</label>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="linkedin">Linked In</label>
          <input type="url" id="linkedin" formControlName="linkedin" class="form-control"
                 [ngClass]="{ 'is-invalid':  f.linkedin.dirty && f.linkedin.errors }" />
          <div *ngIf="f.linkedin.errors" class="invalid-feedback">
            <div *ngIf="f.linkedin.errors.pattern">Please enter a valid URL</div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="address">Street Address</label>
          <input type="text" id="address" maxlength="250" formControlName="address" class="form-control" />
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="city">City</label>
          <input type="text" id="city" maxlength="250" formControlName="city" class="form-control" />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label for="state">State</label>
          <input type="text" id="state" maxlength="2" formControlName="state" class="form-control" />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label for="zip">Zip</label>
          <input type="text" id="zip" maxlength="50" formControlName="zip" class="form-control" />
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="department">Department</label>
          <input type="text" maxlength="250" id="department" formControlName="department" class="form-control" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="phone">Phone</label>
          <input type="text" maxlength="250" id="phone" formControlName="phone" class="form-control" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="mobile">Mobile</label>
          <input type="text" maxlength="250" id="mobile" formControlName="mobile" class="form-control" />
        </div>
      </div>

    </div>
  </form>
  <div class="form-group pb-3 pt-3">
    <button type="submit" (click)="onSubmit()" class="btn btn-secondary btn-lg" [disabled]="!contactEditForm.valid || contactEditForm.pristine">Save</button>
    &nbsp;
    <button class="btn btn-primary btn-lg" (click)="onNoClick()" cdkFocusInitial>Cancel</button>
  </div>
</mat-dialog-content>
<!--<mat-dialog-action class="float-right mt-4">
  <button class="btn btn-primary" (click)="onNoClick()" cdkFocusInitial>Cancel</button>
  &nbsp;
  <button type="submit" (click)="onSubmit()" class="btn btn-secondary" [disabled]="false">Submit</button>
</mat-dialog-action>-->
