import { Component, Inject, ViewContainerRef, ChangeDetectorRef, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContactService } from '../../services/contact.service';


@Component({
  selector: 'app-contact-upload-dialog',
  templateUrl: './contact-upload-dialog.component.html',
  styleUrls: ['./contact-upload-dialog.component.scss']
})

export class ContactUploadDialogComponent implements OnInit {

  currentUser;
  @ViewChild('labelImport') labelImport: ElementRef;



  constructor(
    public dialogRef: MatDialogRef<ContactUploadDialogComponent>,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    public view: ViewContainerRef,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private contactService: ContactService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  contactUploadForm: FormGroup;

  formData = new FormData();

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

    this.authService.currentUser.subscribe(x => this.currentUser = x);

    this.contactUploadForm = this.fb.group({
      file: [null]
    });
  }

  get f() { return this.contactUploadForm.controls; }

  onFileChange(files) {
    const reader = new FileReader();
    if (!files) {
      return;
    }
    if (files) {
      const excelFile = files[0];
      console.log(excelFile);

      this.labelImport.nativeElement.innerText = excelFile.name;

      this.formData.set('ExcelFile', excelFile);

      // need to run CD since file load runs outside of zone
      this.cd.markForCheck();
    }
  }

  onSubmit() {

    this.contactService.uploadContacts(this.formData).subscribe(result => {

      console.log(result);
      this.dialogRef.close(result);
      //this.flash.show('Successfully uploaded contacts', { cssClass: 'alert-success', timeout: 3000 });

    });

  }
}
