<!-- main app container -->

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
      <div class="jumbotron mt-3">
        <h2 class="mb-3">Contact Us</h2>
        <hr>
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" autocomplete="off">

          <div class="form-group">
            <label for="firstname" class="required-label">First Name</label>
            <input type="text" maxlength="50" formControlName="Firstname" class="form-control" [ngClass]="{ 'is-invalid': f.Firstname.dirty && f.Firstname.errors }" />
            <div *ngIf="f.Firstname.errors" class="invalid-feedback">
              <div *ngIf="f.Firstname.errors.required">First Name is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="lastname" class="required-label">Last Name</label>
            <input type="text" maxlength="50" formControlName="Lastname" class="form-control" [ngClass]="{ 'is-invalid': f.Lastname.dirty && f.Lastname.errors }" />
            <div *ngIf="f.Lastname.errors" class="invalid-feedback">
              <div *ngIf="f.Lastname.errors.required">Last Name is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="email" class="required-label">Email</label>
            <input id="email" maxlength="50" type="email" formControlName="Email" class="form-control" [ngClass]="{ 'is-invalid': f.Email.dirty && f.Email.errors }" />
            <div *ngIf="f.Email.errors" class="invalid-feedback">
              <div *ngIf="f.Email.errors.required">Email is required</div>
              <div *ngIf="f.Email.errors.email">Email is not in valid format</div>
            </div>
          </div>
          <div class="form-group">
            <label for="phone">Phone</label>
            <input id="phone" maxlength="50" type="text" formControlName="Phone" class="form-control" />
          </div>
          <div class="form-group">
            <label for="inquiryreason" class="required-label">Inquiry for</label>
            <select id="inquiryreason" formControlName="InquiryReason" class="form-control" [ngClass]="{ 'is-invalid': f.InquiryReason.dirty && f.InquiryReason.errors }">
              <option value="0">Customer Support</option>
              <option value="1">Media/PR Inquiry</option>
              <option value="2">Billing</option>
              <option value="3">Techincal Support</option>
              <option value="4">General</option>
            </select>
            <div *ngIf="f.InquiryReason.errors" class="invalid-feedback">
              <div *ngIf="f.InquiryReason.errors.required">Inquiry Reason is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="message" class="required-label">Message</label>
            <textarea id="message" rows=3 maxlength="500" formControlName="InquiryMessage" class="form-control" [ngClass]="{ 'is-invalid': f.InquiryMessage.dirty && f.InquiryMessage.errors }"></textarea>
            <div *ngIf="f.InquiryMessage.errors" class="invalid-feedback">
              <div *ngIf="f.InquiryMessage.errors.required">Message is required</div>
            </div>
          </div>
          <div class="form-group">
            <button [disabled]="!contactForm.valid || contactForm.pristine" class="btn btn-primary">Submit Inquiry</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
