<div class="container-fluid mb-2">

  <div class="custom-control custom-switch float-right">
    <label for="isActive" style="position:relative;left:-50px;">All</label>
    <input type="checkbox" class="custom-control-input" id="isActive" (change)="setFilter()" [(ngModel)]="isActive">
    <label class="custom-control-label" for="isActive">Active</label>
  </div>
  <br /><br />
  <!--<div class="mw-100 mh-100">-->

  <full-calendar #calendar
                 [weekends]="calendarWeekends"
                 [header]="{
                            left: 'today, prev,next',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
                          }"
                 height="auto"
                 [plugins]="calendarPlugins"
                 [events]="calendarEvents"
                 (eventRender)="eventFormatter($event)"
                 (eventClick)="eventClick($event)"
                 (dateClick)="handleDateClick($event)"
                 [navLinks]="true"
                 [nowIndicator]="true"
                 [selectable]="true"
                 (navLinkDayClick)="handleDayClick($event)"
                 deepChangeDetection="true"></full-calendar>
  <!--</div>-->
</div>
 
