<div class="container-fluid mb-2">
  <div class="row">
    <div class="col-md-12">
      <h2>Campaigns</h2>
    </div>
  </div>
  <div style="height:100%; width:100%;">
    <ag-grid-angular 
                     class="ag-theme-balham"
                     [rowData]="campaigns"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="defaultColDef"
                     [singleClickEdit]="true"
                     [getRowHeight]="getRowHeight"
                     [headerHeight]="40"
                     rowSelection="single"
                     domLayout ="autoHeight"
                     [context]="context"
                     [frameworkComponents]="frameworkComponents"
                     (selectionChanged)="onSelectionChanged()"
                     (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
  <!--<hr />
  <div class="row">
    <div class="col-md-12">
      <h2>Contacts</h2>
    </div>
  </div>
  <ag-grid-angular style="width: auto; height: 200px;"
                   class="ag-theme-balham"
                   [rowData]="contacts"
                   [animateRows]="true"
                   [columnDefs]="columnDefs2"
                   [defaultColDef]="defaultColDef2"
                   rowSelection="single"
                   (selectionChanged)="onSelectionChanged2()"
                   (gridReady)="onGridReady2($event)">
  </ag-grid-angular>-->
</div>
