<div class="container-fluid mb-2">
  <div class="row">
    <div class="col-md-12">

      <h2 class="mb-2">Industries</h2>

    </div>
  </div>

  <div class="mb-3">
    <ag-grid-angular style="width: auto; height: 400px"
                     class="ag-theme-balham"
                     [rowData]="industries"
                     [animateRows]="true"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="defaultColDef"
                     rowSelection="multiple"
                     [pagination]="true"
                     [paginationPageSize]="50"
                     (selectionChanged)="onSelectionChanged()"
                     (gridReady)="onGridReady($event)"
                     (cellClicked)="onCellClicked($event)"
                     [getRowNodeId]="getRowNodeId">
    </ag-grid-angular>

  </div>

  <!--<button type="button" class="btn btn-outline-secondary btn-lg ml-1 mr-1" (click)="downloadCompanies()">Download</button>

  <button type="button" class="btn btn-link btn-lg ml-1 mr-1" (click)="createNewCampaign()">New Campaign</button>-->
  <button [disabled]="selectedRows.length==0" type="button" class="btn btn-outline-primary btn-lg ml-1" (click)="createNewCampaign()">New Campaign</button>

</div>
