<div class="container-fluid">
  <div class="row">
    <div class="col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
      <div class="jumbotron mt-1">
        <h3>User Profile</h3>
        <form [formGroup]="theForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="firstname">First Name</label>
            <input type="text" maxlength="50" id="firstname" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': (submitted || f.firstName.dirty) && f.firstName.errors }" />
            <div *ngIf="f.firstName.errors" class="invalid-feedback">
              <div *ngIf="f.firstName.errors.required">First Name is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="lastname">Last Name</label>
            <input type="text" maxlength="50" id="lastname" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': (submitted || f.lastName.dirty) && f.lastName.errors }" />
            <div *ngIf="f.lastName.errors" class="invalid-feedback">
              <div *ngIf="f.lastName.errors.required">Last Name is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="text" maxlength="50" id="email" formControlName="email" autocomplete="off" class="form-control" [ngClass]="{ 'is-invalid': (submitted || f.email.dirty) && f.email.errors }" />
            <div *ngIf="f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="officephone">Office Phone</label>
            <input type="text" maxlength="15" id="officephone" formControlName="officePhone" class="form-control" />
          </div>
          <div class="form-group">
            <label for="mobilephone">Mobile Phone</label>
            <input type="text" maxlength="15" id="mobilephone" formControlName="mobilePhone" class="form-control" />
          </div>
          <div class="form-group">
            <label for="password1">New Password</label>
            <input type="password" id="password1" formControlName="password" autocomplete="new-password" class="form-control" />
          </div>
          <div class="form-group">
            <label for="password2">Confirm New Password</label>
            <input type="password" id="password2" formControlName="passwordConfirm" class="form-control" />
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-secondary btn-lg mr-1" [disabled]="!theForm.valid || theForm.pristine">Save</button>
            <button type="button" class="btn btn-primary btn-lg" (click)="goBack()">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

