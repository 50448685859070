
import { Component, OnInit, ViewContainerRef, ViewChild, LOCALE_ID, ChangeDetectorRef } from '@angular/core';
import { EmailMessageService } from '../../services/email-message.service';
import { CampaignMessageService } from '../../services/campaign-message.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';

import { formatDate } from '@angular/common';
import { ContactService } from '../../services/contact.service';
import { CompanyEditDialogComponent } from './company-edit-dialog/company-edit-dialog.component';
import { UserService } from '../../services/user.service';
import { ContactEditDialogComponent } from '../contact/contact-edit-dialog/contact-edit-dialog.component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  currentUser: User;
  savedFilterModel: any;
  allFilters: string;

  constructor(
    private authService: AuthenticationService,
    private emailMsgService: EmailMessageService,
    private campaignMsgService: CampaignMessageService,
    private contactService: ContactService,
    private userService: UserService,
    public dialog: MatDialog,
    public view: ViewContainerRef,
    private cd: ChangeDetectorRef,
    private flash: FlashMessagesService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.context = { componentParent: this };
  }
  private agGridCompaniesApi;
  public context;
  public frameworkComponents;
  companies: any[] = [];
  selectedRows: any = [];
  filteredRows: any = [];
  selectedCompanyIds: number[] = [];
  filteredCompanyIds: number[] = [];
  filterByCompany = '';
  filterByIndustry = '';
  filterByAssignedUser: number = -1;
  assignUserId: number = -1;
  showMyCompanies: boolean = true;
  users: User[];

  getRowNodeId = function (data) {
    return data.id;
  }
  resetFilters: boolean = false;

  pinnedBottomRowData = [
    {
    "id": "",
    "name": "",
    "active": 0,
    "unsubscribed": 0,
    "bounced": 0,
    "responded": 0,
      "website": "",
      "linkedin": "",
      "city": "",
      "state": "",
      "industry": "",
      "rev": "",
      "empl": "",
      "created": "",
    }
  ];

  columnDefs = [
    {
      headerName: ' ', field: 'id', width: 80,
      pinned: true,
      filter: false,
      resizable: false,
      sortable: false,
      cellRenderer: () => {
        return `<i class="far fa-edit fa-lg text-secondary mr-2" type="button" title="Edit company"></i>
        <i class="fas fa-info-circle fa-lg text-secondary company_view" type="button" title="View company details"></i>`
        //<i class="far fa-trash-alt fa-lg text-danger"></i>`
      },
      pinnedRowCellRenderer: () => {
        return ""
      }
    },

    {
      headerName: 'Company Name', field: 'name', width: 220,
      checkboxSelection: true,
      pinned: true,
      sort: 'asc',
      cellRenderer: (data) => {
        return `<div class='btn-link text-secondary'>${data.value}</div>`
      },
      pinnedRowCellRenderer: (data) => {

        return `${data.value}`
      }
    },
    {
      headerName: 'Active Contacts', field: 'contactActiveCount', width: 100,
      filter: 'agNumberColumnFilter',
      cellStyle: function () {
        return { "text-align": "center"}
      },
      cellRenderer: (data) => {
        return `<i class="fas fa-user-plus fa-lg text-primary mr-3" type="button" title="Add new contact"></i>${data.value}`
      },
      pinnedRowCellRenderer: (data) => {
        return `<b>${data.data.active}</b>`;
      }
    },
    {
      headerName: 'Unsubscribed Contacts', field: 'contactUnsubscribedCount', width: 100,
      filter: 'agNumberColumnFilter',
      cellStyle: function () {
        return { "text-align": "center" }
      },
      pinnedRowCellRenderer: (data) => {
        return `<b>${data.data.unsubscribed}</b>`;
      }
    },
    {
      headerName: 'Bounced Contacts', field: 'contactBouncedCount', width: 100,
      filter: 'agNumberColumnFilter',
      cellStyle: function () {
        return { "text-align": "center" }
      },
      pinnedRowCellRenderer: (data) => {
        return `<b>${data.data.bounced}</b>`;
      }
    },
    {
      headerName: 'Responded Contacts', field: 'contactRespondedCount', width: 100,
      filter: 'agNumberColumnFilter',
      cellStyle: function () {
        return { "text-align": "center" }
      },
      pinnedRowCellRenderer: (data) => {
        return `<b>${data.data.responded}</b>`;
      }
    },
    {
      headerName: 'Website', field: 'website', width: 120,
      cellRenderer: (data) => {
        if (data.value) {
          return `<a class='text-secondary' href="//${data.value}" target='_blank'>${data.value}</a>`
        } else {
          return "";
        }
      },
      pinnedRowCellRenderer: () => {
        return ""
      }
    },
    {
      headerName: 'LinkedIn', field: 'linkedin', width: 180,
      cellRenderer: (data) => {
        if (data.value) {
          return `<a class='text-secondary' href="${data.value}" target='_blank'>${data.value}</a>`
        } else {
          return "";
        }
      },
      pinnedRowCellRenderer: () => {
        return ""
      }
    },
    {
      headerName: 'Phone', field: 'phone', width: 100, hide: true,
    },
    {
      headerName: 'Street Address', field: 'streetaddress', width: 100, hide: true,
    },
    {
      headerName: 'City', field: 'city', width: 120,
      pinnedRowCellRenderer: () => {
        return ""
      }
    },
    {
      headerName: 'State', field: 'state', width: 80,
      pinnedRowCellRenderer: () => {
        return ""
      }
    },
    {
      headerName: 'Industry', field: 'industry', width: 200,
      pinnedRowCellRenderer: () => {
        return ""
      }
    },
    {
      headerName: 'Revenue', field: 'revenueRange', width: 120,
      pinnedRowCellRenderer: () => {
        return ""
      }
    },
    {
      headerName: 'Employees', field: 'employeeRange', width: 100,
      pinnedRowCellRenderer: () => {
        return ""
      }
    },
    {
      headerName: 'Tags', field: 'tags', width: 120,
      pinnedRowCellRenderer: () => {
        return ""
      }
    },
    {
      headerName: 'Created Date', field: 'createddate', width: 120,
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      cellRenderer: (data) => {
        return formatDate(data.value, 'dd MMM yyyy', 'en-us')
      },
      pinnedRowCellRenderer: () => {
        return ""
      }
    },
    {
      headerName: 'Created By', field: 'createdByName', width: 100,
      pinnedRowCellRenderer: () => {
        return ""
      }
    },
    {
      headerName: 'Assigned To', field: 'assignedUserName', width: 100,
      pinnedRowCellRenderer: () => {
        return ""
      }
    },
    {
      headerName: 'User Id', field: 'assignedUserId', width: 100,
      hide: true,
      filter: true,
    },
  ];

  defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    filterParams: {
      resetButton: true,
      applyButton: false,
      debounceMs: 500
    },

  };

  private statusName(value: any) {
    switch (value) {
      case '0':
        return 'Deactivated';
      case '1':
        return 'Active';
      case '2':
        return 'Contacted';
      case '3':
        return 'Bounced';
      case '4':
        return 'Unsubscribed';
      case '5':
        return 'Responded';
      default:
        return 'All';
    }
  }

  onSelectionChanged() {
    this.selectedRows = this.agGridCompaniesApi.getSelectedRows();

    console.log("selectedCompanyRows", this.selectedRows);
  }

  onMyCompanyChange() {
    //alert(this.showMyCompanies);
  }


  onFilterChange($event) {

    var filterCol = $event.target.name;
    console.log($event.target);

    var filterComponent = this.agGridCompaniesApi.getFilterInstance(filterCol);
    var filterVal;
    let filterOper = 'contains';
    if (filterCol == 'name')
      filterVal = this.filterByCompany;
    else if (filterCol == 'industry')
      filterVal = this.filterByIndustry;
    else if (filterCol == 'assignedUserId') {
      if ($event.target.type == "checkbox") {
        if (this.showMyCompanies) {
          filterOper = 'equals';
          filterVal = this.currentUser.id;
          this.filterByAssignedUser = this.currentUser.id;
        }
      }

      // --- anly for admins ---
      if (this.currentUser.roleId < 3) {
        if (this.filterByAssignedUser > 0) {
          filterOper = 'equals';
          filterVal = this.filterByAssignedUser;
          if (this.filterByAssignedUser == this.currentUser.id) {
            this.showMyCompanies = true;
          } else {
            this.showMyCompanies = false;
          }
        } else {
          this.showMyCompanies = false;
        }
      }
    }

    //alert(filterCol + ' ' + filterOper + ' ' + filterVal);

    if (filterVal) {
      filterComponent.setModel({
        type: filterOper,
        filter: filterVal
      });
    } else {
      // clear filter 
      filterComponent.setModel(null);
    }

    this.agGridCompaniesApi.onFilterChanged();

  }

  onFilterModified($event) {
    this.saveFilterModel($event);
    // calc totals
    this.updateTotals();
  }

  saveFilterModel($event) {

    this.savedFilterModel = this.agGridCompaniesApi.getFilterModel();
    var keys = Object.keys(this.savedFilterModel);
    this.allFilters = keys.join(',');
    console.log("savedFilterModel", this.savedFilterModel);

    localStorage.setItem('companies_filter', JSON.stringify(this.savedFilterModel));
  }

  applyAllFilters() {

    console.log("applyAllFilters - savedFilterModel", this.savedFilterModel);

    this.agGridCompaniesApi.setFilterModel(this.savedFilterModel);
    //
    //// company filter
    var filterComponent = this.agGridCompaniesApi.getFilterInstance("name");
    //console.log("company filter", filterComponent.appliedModel);
    if (filterComponent.appliedModel) {

      this.filterByCompany = filterComponent.appliedModel.filter;

    }
    var filterComponent = this.agGridCompaniesApi.getFilterInstance("assignedUserId");
    //console.log("company filter", filterComponent.appliedModel);
    if (filterComponent.appliedModel) {
      this.filterByAssignedUser = filterComponent.appliedModel.filter;
      if (this.filterByAssignedUser == this.currentUser.id) {
        this.showMyCompanies = true;
      } else {
        this.showMyCompanies = true;
      }
    } else {
      this.showMyCompanies = false;
      this.filterByAssignedUser = -1;
    }
    //// industry filter
    var filterComponent = this.agGridCompaniesApi.getFilterInstance("industry");
    //console.log("industry filter", filterComponent.appliedModel);
    if (filterComponent.appliedModel) {
      this.filterByIndustry = filterComponent.appliedModel.filter;
    } 
    else if (this.filterByIndustry.length > 0) {

      filterComponent.setModel({
        type: 'equals',
        filter: this.filterByIndustry
      });
    }
    

    this.agGridCompaniesApi.onFilterChanged();
  }
  removeFilters() {

    this.agGridCompaniesApi.setFilterModel(null);
    this.filterByIndustry = '';
    this.filterByCompany = '';
    this.filterByAssignedUser = -1;
    if (this.currentUser.roleId < 3) {
      this.showMyCompanies = false;
    }
  }
  updateTotals() {

    console.log("update totals called");

    this.filteredRows = this.agGridCompaniesApi.getModel().rowsToDisplay;
    //console.log("updateTotals", this.filteredRows);
    var totalActive = 0;
    var totalUnsubscribed = 0;
    var totalBounced = 0;
    var totalResponded = 0;
    this.filteredRows.forEach((item, index) => {
      //console.log(item.data.id, item.data.email);
      totalActive += item.data.contactActiveCount;
      totalUnsubscribed += item.data.contactUnsubscribedCount;
      totalBounced += item.data.contactBouncedCount;
      totalResponded += item.data.contactRespondedCount;
    });
    // --- can do the same w/reduce ---
    //totalActive = this.filteredRows.reduce((totalActive, item) => totalActive + item.data.contactActiveCount, 0);

    this.pinnedBottomRowData[0].active = totalActive;
    this.pinnedBottomRowData[0].unsubscribed = totalUnsubscribed;
    this.pinnedBottomRowData[0].bounced = totalBounced;
    this.pinnedBottomRowData[0].responded = totalResponded;

    this.agGridCompaniesApi.setPinnedBottomRowData(this.pinnedBottomRowData);
  }
 
  downloadCompanies() {

    var cols = [];
    this.columnDefs.forEach((item) => {
      if (item.field !== 'id' &&
          item.field !== 'assignedUserId') {
        cols.push(item.field);
      }
    })
    //var status = this.statusName(this.filterByStatus).toLowerCase();
    var filename = 'companies' ; //`contacts_${status}`;
    var params = {
      allColumns: false,
      columnKeys: cols,
      fileName: filename,
    }
    this.agGridCompaniesApi.exportDataAsCsv(params);

  }

  onGridReady(params) {

    this.agGridCompaniesApi = params.api;

    if (this.resetFilters) {
      localStorage.removeItem('companies_filter');
    }

    var fltr = JSON.parse(localStorage.getItem('companies_filter'));
    if (fltr) {
      this.savedFilterModel = fltr;
      console.log("savedFilterModel",this.savedFilterModel);
      if (this.savedFilterModel.assignedUserId) {
        this.savedFilterModel.assignedUserId.filter = this.currentUser.id;
      }
    }

    this.agGridCompaniesApi.setFilterModel(this.savedFilterModel);

    this.loadCompanies();
  }

  onCellClicked(event) {

    console.log("onCellClicked", event);

    if (event.column.colId == "id") {

      let clickedObjClass = event.event.target.className;
      console.log("clickedObjClass", clickedObjClass);

      if (clickedObjClass.indexOf("company_view") >= 0) {

        this.viewCompany(event.data);

      } else {

        this.editrow(event.data);

      }

    } else if (event.column.colId == "name") {

      // navigateToContacts
      this.router.navigate(['/', 'contacts'], { state: { data: { company: [event.data.name] } } });

    } else if (event.column.colId == "contactActiveCount") {

      this.newContact(event.data);
    }
  }

  viewCompany(data: any) {

    this.router.navigate(['/', 'company', data.id],);
  }
  newCompany() {

    let c = { id: 0 }
    this.editrow(c);

  }
  newContact(data: any) {

    const dialogRef = this.dialog.open(ContactEditDialogComponent, {
      width: '800px',
      disableClose: false,
      panelClass: 'custom-modalbox',
      data: {
        companyname: data.name,
        companyid: data.id,
        id: 0
      }
    });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        let newCount = data.contactActiveCount + 1;
        var editedRow = this.agGridCompaniesApi.getRowNode(data.id);
        editedRow.setDataValue('contactActiveCount', newCount);

        this.flash.show(`Successfully created new contact`, { cssClass: 'alert-success', timeout: 3000 });

      }
    });
  }
  editrow(data: any): void {

    let id = data.id;

    const dialogRef = this.dialog.open(CompanyEditDialogComponent, {
      width: '800px',
      disableClose: false,
      panelClass: 'custom-modalbox',
      data: id
    });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });

    dialogRef.afterClosed().subscribe(result => {
    
      if (result) {

        if (id > 0) {

          var editedRow = this.agGridCompaniesApi.getRowNode(id);

          if (result === "delete") {

            console.log("delete", data);

            let root_elm = this.view;
            console.log(root_elm);
            const dialogDeleteRef = this.dialog.open(ConfirmationDialogComponent,
              {

                data: `Are you sure you want to delete <b>${data.name}</b> company?`,
                viewContainerRef: root_elm
              });

            dialogDeleteRef.afterClosed().subscribe(result => {
              if (result) {
                // TODO: implement company delete here ---
                this.contactService.deleteCompany(id).subscribe(x => {

                  this.agGridCompaniesApi.updateRowData({
                    remove: [editedRow.data]
                  });

                  this.flash.show(`Successfully deleted <b>${data.name}</b> company`, { cssClass: 'alert-success', timeout: 3000 });

                  return;

                });
              }
            });

          } else {

            editedRow.setData(result);
          }


        } else {

          //this.companies.unshift(result);

          this.agGridCompaniesApi.updateRowData({
            add: [result]
          });

          this.filterByCompany = result.name;
          setTimeout(() => {
            this.onFilterChange({ target: { name: 'name' } })
          }, 300);
          
        }

        this.applyAllFilters();

        if (result.name) {

          this.flash.show(`Successfully updated <b>${result.name}</b> details`, { cssClass: 'alert-success', timeout: 3000 });

        }

      }
    });

  }
  deleteCompany(): void {


    let root_elm = this.view;
    console.log(root_elm);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {

        data: `Are you sure you want to delete this company?`,
        viewContainerRef: root_elm
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        return true;
      }
    });
  }

  ngOnInit() {

    this.authService.currentUser.subscribe(x => this.currentUser = x);
    this.showMyCompanies = true;

    if (history.state.data && history.state.data.industry) {
      this.resetFilters = true;
      this.filterByIndustry = history.state.data.industry;
    } else {
      this.resetFilters = false;
    }

    this.userService.getAll().subscribe(users => {

      this.users = users.filter((u) => u.status == 'Active');

      console.log(this.users, this.currentUser);
    })
  }
  assignCompanyUser() {

    this.selectedCompanyIds = [];
    this.selectedRows.forEach((item, index) => {
      this.selectedCompanyIds.push(item.id);
    });

    //console.log(this.selectedContactIds);

    this.contactService.assignCompanyUser(this.assignUserId, this.selectedCompanyIds)
      .subscribe(() => {
        this.loadCompanies(true);
      });

  }
  createNewCampaign() {

    this.filteredRows = this.agGridCompaniesApi.getModel().rowsToDisplay;

    let root_elm = this.view;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {

        data: `Are you sure you want to create new campaign with <b>${this.filteredRows.length}</b> companies?`,
        viewContainerRef: root_elm,
      });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log(rows);
        this.filteredCompanyIds.length = 0;
        this.filteredRows.forEach((item, index) => {
          //console.log(item.data.id, item.data.email);
          this.filteredCompanyIds.push(item.data.id);
        });

        this.router.navigate(['/', 'campaign', 'message'], { state: { data: { companyids: [this.filteredCompanyIds] } } });
      }
    });

  }
  private loadCompanies(applyFilters: boolean = false) {

    this.contactService.getAllCompanies().subscribe(cmp => {

      var c: any = cmp;
      this.companies = c;

      //console.log(this.companies);
      // --- clear selected if any ---
      this.selectedRows.length = 0;
      this.selectedCompanyIds.length = 0;

      if (applyFilters) {
        setTimeout(() => {
          this.applyAllFilters()
        }, 500)
      }
    });
  }
}
var filterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    //var dateParts = dateAsString.split('-');
    //var cellDate = new Date(
    //  Number(dateParts[2]),
    //  Number(dateParts[1]) - 1,
    //  Number(dateParts[0])
    //);
    var cellDate = new Date(dateAsString);

    //console.log(cellValue, cellDate);

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
