<div class="container mb-3">
  <form [formGroup]="emailCampaignUploadForm" (ngSubmit)="onSubmit()">
    <div class="card square mb-3">
      <div class="row no-gutters">
        <div class="col-md-12">
          <div class="card-body">
            <h2>Create new campaign</h2>
            <div class="form-group">
              <label class="required-label" for="CampaignName">Campaign Name</label>
              <input type="text" class="form-control" id="CampaignName" formControlName="CampaignName"
                     [ngClass]="{ 'is-invalid':  (f.CampaignName.dirty || f.CampaignName.touched) && f.CampaignName.errors }" autocomplete="off">
              <div *ngIf="f.CampaignName.errors" class="invalid-feedback">
                <div *ngIf="f.CampaignName.errors.required">Name is required</div>
              </div>
            </div>
            <div class="form-group">
              <label class="required-label" for="StartDate">Start Date</label>
              <input type="date" class="form-control" id="StartDate" formControlName="StartDate" [min]="formattedDate" required
                     [ngClass]="{ 'is-invalid':  (f.StartDate.dirty || f.StartDate.touched) && f.StartDate.errors }">
              <div *ngIf="f.StartDate.errors" class="invalid-feedback">
                <div *ngIf="f.StartDate.errors.required">Start Date is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="StartTime">Start Time</label>
              <input type="time" class="form-control" id="StartTime" formControlName="StartTime" required>
            </div>
            <div class="custom-file mb-3">
              <!--<label class="required-label" for="ExcelFile">Excel File</label>-->
              <input type="file" class="form-control-file"
                     accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                     id="file"
                     formControlName="file"
                     (change)="onFileChange($event.target.files)" required
                     [ngClass]="{ 'is-invalid':  (f.file.dirty || f.file.touched) && f.file.errors }">
              <!--<label class="custom-file-label" for="ExcelFile" style="margin-top: 30px">Choose file</label>-->
              <div *ngIf="f.file.errors" class="invalid-feedback">
                <div *ngIf="f.file.errors.required">File is required</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="card-body">
          <div class="form-group">
            <label>Subject</label>
            <div class="border p-1">{{email_template.subject}}</div>
          </div>
          <div class="form-group">
            <label>Message</label>
            <div class="border p-1" [innerHTML]="email_template.body"></div>
          </div>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-secondary btn-lg" [disabled]="!emailCampaignUploadForm.valid">{{ buttonText }}</button>
    <button type="button" class="btn btn-primary btn-lg mr-1" (click)="goBack()">Cancel</button>
  </form>
</div>

