
import { Component, OnInit, ViewContainerRef, ViewChild, LOCALE_ID, ChangeDetectorRef } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ContactService } from '../../services/contact.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.scss']
})
export class IndustryComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private contactService: ContactService,
    public dialog: MatDialog,
    public view: ViewContainerRef,
    private cd: ChangeDetectorRef,
    private flash: FlashMessagesService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.context = { componentParent: this };
  }

  currentUser: User;

  private agGridIndustriesApi;
  public context;
  public frameworkComponents;
  industries: any[] = [];
  selectedRows: any = [];
  filteredRows: any = [];
  selectedIndustryIds: number[] = [];

  getRowNodeId = function (data) {
    return data.id;
  }

  columnDefs = [
    {
      headerName: 'Industry Name', field: 'name', width: 250,
      pinned: true,
      checkboxSelection: true,
      sort: 'asc',
      //cellRenderer: (data) => {
      //  return `<div class='btn-link text-secondary'>${data.value}</div>`
      //},
    },
    {
      headerName: 'Companies', field: 'companyCount', width: 120,
      filter: 'agNumberColumnFilter',
      cellStyle: function () {
        return { "text-align": "center" }
      },
      cellRenderer: (data) => {
        return `<div class='btn-link text-secondary'>${data.value}</div>`
      },
    },
    {
      headerName: 'Contacts', field: 'contactCount', width: 120,
      filter: 'agNumberColumnFilter',
      cellStyle: function () {
        return { "text-align": "center" }
      },
      cellRenderer: (data) => {
        return `<div class='btn-link text-secondary'>${data.value}</div>`
      },
    },
    {
      headerName: 'Responded', field: 'contactCountResponded', width: 120,
      filter: 'agNumberColumnFilter',
      cellStyle: function () {
        return { "text-align": "center" }
      },
    },
    {
      headerName: 'Last Email Sent Date', field: 'lastEmailDate', width: 160,
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      cellRenderer: (data) => {
        if (data.value != null) {
          return formatDate(data.value, 'dd MMM yyyy hh:mm a', 'en-us')
        }
      }
    },
  ];

  defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    filterParams: {
      resetButton: true,
      applyButton: false,
      debounceMs: 500
    },

  };

  ngOnInit() {

    this.authService.currentUser.subscribe(x => this.currentUser = x);

  }
  onGridReady(params) {

    this.agGridIndustriesApi = params.api;


    this.loadIndustries();
  }

  onCellClicked(event) {

    if (event.column.colId == "companyCount") {

      // navigateToContacts
      this.router.navigate(['/', 'companies'], { state: { data: { industry: [event.data.name] } } });

    } else if (event.column.colId == "contactCount") {

      this.router.navigate(['/', 'contacts'], { state: { data: { industry: [event.data.name] } } });

    }
  }
  onSelectionChanged() {
    this.selectedRows = this.agGridIndustriesApi.getSelectedRows();
  }

  createNewCampaign() {

    this.selectedIndustryIds = [];
    let selectedNames = '';
    let selectedContactsCount: number = 0;
    this.selectedRows.forEach((item, index) => {
      this.selectedIndustryIds.push(item.id);
      selectedNames += `<br/>${item.name}`;
      selectedContactsCount += item.contactCount;
    });

    let root_elm = this.view;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {

        data: `Are you sure you want to create new campaign for selected Industries?<b>${selectedNames}</b><br/>Total of <b>${selectedContactsCount}</b> contacts will be reached.`,
        viewContainerRef: root_elm,
      });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.router.navigate(['/', 'campaign', 'message'], { state: { data: { industryids: [this.selectedIndustryIds] } } });
      }
    });

  }
  private loadIndustries() {

    this.contactService.getIndustryStats().subscribe(cmp => {

      var c: any = cmp;
      this.industries = c;

      console.log(this.industries);
      // --- clear selected if any ---
      this.selectedRows.length = 0;
      this.selectedIndustryIds.length = 0;

    });
  }
}
var filterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    //var dateParts = dateAsString.split('-');
    //var cellDate = new Date(
    //  Number(dateParts[2]),
    //  Number(dateParts[1]) - 1,
    //  Number(dateParts[0])
    //);
    var cellDate = new Date(dateAsString);

    //console.log(cellValue, cellDate);

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
