import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { UserService } from 'src/app/services/user.service';
import { User } from '../../models/user';
import { AuthenticationService } from '../../services/authentication.service';
import { PreviousURLService } from '../../services/previous-url.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { InviteDialogComponent } from './invite-dialog/invite-dialog.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private userAuth: AuthenticationService,
    private flash: FlashMessagesService,
    public urlService: PreviousURLService,
    public dialog: MatDialog,
    public view: ViewContainerRef,
  ) {}
  users: Array<User> = [];
  currentUser: User;

  ngOnInit() {

    this.currentUser = this.userAuth.currentUserValue;
    this.reloadUsers();
  }

  reloadUsers() {

    this.userService.getAll().subscribe(users => {

      this.users = users;
      this.users.sort((a, b) => a.roleId - b.roleId
        || a.firstName.toUpperCase().localeCompare(b.firstName.toUpperCase())
        || a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase())
      );

      console.log(users);
    })
  }

  inviteUser() {

    let root_elm = this.view;

    const dialogRef = this.dialog.open(InviteDialogComponent, {
      width: '400px',
      disableClose: false,
      viewContainerRef: root_elm,
      data: {
        email: '',
        payment: this.currentUser.paymentProfileId
      }
    });

    //let root_elm = this.view;
    //const dialogRef = this.dialog.open(InviteDialogComponent,
    //  {

    //    //data: `Are you sure you want to resend invitation to the user? <br/><b>${user.firstName} ${user.lastName} &lt;${user.email}&gt;</b>`,
    //    viewContainerRef: root_elm,
    //  });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });


    dialogRef.afterClosed().subscribe(result => {

      if (result && result.email) {

        console.log(result);
        this.userService.invite(result.email).subscribe(() => {
          this.flash.show('Successfully sent an invitation to the new user',
            { cssClass: 'alert-success', timeout: 3000 })

          this.reloadUsers();
        })
      }
    });

  }
  resendInvite(user: User) {

    console.log("resendInvite", user);
    let root_elm = this.view;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {

        data: `Are you sure you want to resend invitation to the user? <br/><b>${user.firstName} ${user.lastName} &lt;${user.email}&gt;</b>`,
        viewContainerRef: root_elm,
      });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.userService.resendInvite(user.id).subscribe(() => {
          this.flash.show('Successfully sent invitation to the user',
            { cssClass: 'alert-success', timeout: 3000 })

          this.reloadUsers();
        })
      }
    });
  }

  updateStatus(user: User, active: number) {

    //this.userService.status(user.id, active).subscribe(() => {
    //  this.flash.show('Successfully updated user status',
    //    { cssClass: 'alert-success', timeout: 3000 })

    //  this.reloadUsers();
    //})
    let root_elm = this.view;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {

        data: `Are you sure you want to ${active?'activate':'deactivate'} user? <br/><b>${user.firstName} ${user.lastName} &lt;${user.email}&gt;</b>`,
        viewContainerRef: root_elm,
      });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.userService.status(user.id, active).subscribe(() => {
          this.flash.show('Successfully updated user status',
            { cssClass: 'alert-success', timeout: 3000 })

          this.reloadUsers();
        })
      }
    });

  }
  deleteUser(user: User) {

    console.log("delete", user);

    let root_elm = this.view;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {

        data: `Are you sure you want to delete user? <br/><b>${user.firstName} ${user.lastName} &lt;${user.email}&gt;</b>`,
        viewContainerRef: root_elm,
      });

    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.userService.delete(user.id).subscribe(() => {
          this.flash.show('Successfully deleted the user',
            { cssClass: 'alert-success', timeout: 3000 })

          this.reloadUsers();
        })
      }
    });

  }

}
