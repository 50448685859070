import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";



@Component({
  selector: 'app-cell-callback-renderer',
  templateUrl: './cell-callback-renderer.component.html',
  styleUrls: ['./cell-callback-renderer.component.scss']
})
//export class CellCallbackRendererComponent implements OnInit {

//  constructor() { }

//  ngOnInit() {
//  }

//}
/***** interface ICellRendererParams {
value: any, // value to be rendered
valueFormatted: any, // value to be rendered formatted
getValue: () => any, // convenience function to get most recent up to date value
setValue: (value: any) => void, // convenience to set the value
formatValue: (value: any) => any, // convenience to format a value using the columns formatter
data: any, // the rows data
node: RowNode, // row rows row node
colDef: ColDef, // the cells column definition
column: Column, // the cells column
rowIndex: number, // the current index of the row (this changes after filter and sort)
api: GridApi, // the grid API
eGridCell: HTMLElement, // the grid's cell, a DOM div element
eParentOfValue: HTMLElement, // the parent DOM item for the cell renderer, same as eGridCell unless using checkbox selection
columnApi: ColumnApi, // grid column API
context: any, // the grid's context
refreshCell: () => void // convenience function to refresh the cell
}
*/
export class CellCallbackRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
    //console.log("agInit", this.params.colDef)
  }

  public invokeParentMethod(type) {

    console.log("invokeParentMethod-"+type,this.params.data);

    this.params.context.componentParent.actionFromCell(type, this.params.data);
  }

  refresh(): boolean {
    return false;
  }
}
