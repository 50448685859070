import { Component, Inject, ViewContainerRef, ChangeDetectorRef, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact.service';
import { formatDate } from '@angular/common';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  companyIndustries: any;

  constructor(
    private authService: AuthenticationService,
    private contactService: ContactService,
    private userService: UserService
  ) { }

  industryid: number = 0;
  userid: number = 0;
  startDate: Date;
  endDate: Date;

  start: string;
  end: string;
  totalCampaigns: number;
  totalEmails: number;
  totalEmailsOpened: number;
  totalContacts: number;
  contactsResponded: number;
  contactsUnsubscribed: number;
  contactsBounced: number;
  rangeSelected = '30'; // last 30 days
  users: Array<User> = [];
  currentUser: User;

  ngOnInit() {

    this.authService.currentUser.subscribe(x => this.currentUser = x);
    // get list of industries with campain emails sent
    this.contactService.getIndustryStats(true).subscribe(ind => {
      this.companyIndustries = ind
    });

    this.userService.getAll().subscribe(users => {

      this.users = users.filter((u) => u.status !== 'Invited');

      console.log(this.users, this.currentUser);
    })

    console.log(this.start, this.end);

    this.calcRange();

  }

  calcRange() {

    if (this.rangeSelected == '90') {

      this.startDate = new Date(new Date().setDate(new Date().getDate() - 90));

    } else if (this.rangeSelected == '30') {

      this.startDate = new Date(new Date().setDate(new Date().getDate() - 30));
    }
    else if (this.rangeSelected == 'Y') {
      var y = new Date().getFullYear();
      this.startDate = new Date(y, 0, 1);
    }
    else if (this.rangeSelected == 'M') {
      var y = new Date().getFullYear();
      var m = new Date().getMonth();
      this.startDate = new Date(y, m, 1);
    }

    this.endDate = new Date();

    this.start = formatDate(this.startDate, 'yyyy-MM-dd', 'en-US');
    this.end = formatDate(this.endDate, 'yyyy-MM-dd', 'en-US');

    this.getStats(null);

  }

  getRange($event) {

    console.log($event);
    this.rangeSelected = $event.target.value;
    this.calcRange();

  }

  getStats($event) {

    console.log("getStats", $event);
    if ($event) {
      // --- when dates changed manually by the user ---
      this.rangeSelected = "-";
    }

    console.log("getstats called", this.start, this.end, this.industryid, this.userid);

    this.contactService.analytics(this.start, this.end, this.industryid, this.userid)
      .subscribe(result => {

        var ret: any = result;
        console.log(result);
        this.totalCampaigns = ret.campaigns;
        this.totalContacts = ret.contacts;
        this.totalEmails = ret.emails_sent;
        this.totalEmailsOpened = ret.emails_opened;
        this.contactsResponded = ret.contacts_responded;
        this.contactsUnsubscribed = ret.contacts_unsubscribed;
        this.contactsBounced = ret.contacts_bounced;

      }
      );
    }
}
